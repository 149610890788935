import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import Feature from "ol/Feature";
import xml2js from 'xml2js';
import {saveAs} from 'file-saver';
import Drawing from "dxf-writer";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import MultiLineString from "ol/geom/MultiLineString";
import {MerkatorMapInteractionTypes, SetInteraction} from "@merkator/merkator-map";
import {Geometry} from "ol/geom"
import {download} from "@crmackey/shp-write";
import {ComponentmanagerService} from "../../../services/app-state-services/componentmanager.service";
import {MatDialog} from "@angular/material/dialog";
import {FormControl} from '@angular/forms';
import {MerkatorMapService} from "../../../services/app-map-services/merkator-map.service";
import {ExportWFSModel} from "../../../classes-types/ExportWFSModel";
import GeoJSON from "ol/format/GeoJSON";
import {HttpService} from "../../../services/app-utililty-services/http.service";
import {TypeOfFeature} from "../../../classes-types/type-features";
import {KML} from "ol/format";




@Component({
  selector: 'app-feature-exporter',
  templateUrl: './feature-exporter.component.html',
  styleUrls: ['./feature-exporter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeatureExporterComponent implements OnInit {

  /*
  * DrawSelect om Poly te tekenen voor features.
  */
  fc_chips = new FormControl('');
  public selectedChip: any;
  chipTitles: string[] = ["Alles", "Gemeente", "Poly"];
  gemeentenArray: any;
  typesOfFeatures: TypeOfFeature[] = [];
  featuresForExport: Feature<Geometry>[] = [];
  featuresForExportFiltered: Feature<Geometry>[] = [];
  wfsURL = "";
  selectedFeatureType: TypeOfFeature = {displayname: "", featuretype: ""};
  searchText="";
  selectedFeature = 0;
  constructor(private compManager: ComponentmanagerService, public dialog: MatDialog, private merkatorMapService: MerkatorMapService, private httpService: HttpService) {
  }

  ngOnInit(): void {
    const sub = this.merkatorMapService.getGemeenten().subscribe(result => {
      this.gemeentenArray = result.sort((a, b) => a.name.localeCompare(b.name));
      sub.unsubscribe();
    });

    this.wfsURL = this.httpService.apiUrl.replace("/api/", "/wfs/");


    this.merkatorMapService.mapRef.db_layers.filter(x => x.main_url.includes("/wfs")).forEach(x => {
      this.typesOfFeatures.push({displayname: x.name, featuretype: x.main_layers});
    });
  }

  ExportFeatures(typeOfExport: string, selectionOfFeatures: string) {
    let features: Feature[]=[];//.filter(x=>x.get("layername")===selectionOfFeatures);
    console.log(this.selectedFeature);
    if(this.selectedFeature===0) features= this.featuresForExport;
    else features = this.featuresForExport.filter(x=>x.getId()===this.selectedFeature);
    console.log(features);
    switch (typeOfExport) {
      case "KML":
        this.exportToKML(features);
        break;
      case "DXF":
        this.exportToDXF(features, ["Leidingen"]);
        break;
      case"SHP":
        this.exportToSHP(features);
        break;

    }
  }

  toggleSelection(chipName: string): void {
    this.selectedChip = chipName;
    this.featuresForExport = [];
    switch (this.selectedChip) {
      case"Poly":
        this.compManager.mapService.drawRef.verwijderAlleGetekende("Tekenlaag");
        const interaction: SetInteraction = {type: MerkatorMapInteractionTypes.Draw, geometry: "Polygon"};
        this.compManager.mapService.mapRef.setInteraction(interaction);
        const sub = this.compManager.mapService.drawRef.drawFinished.subscribe(value => {
          this.getFeaturesFromPoly(value);
          console.log('Ik kom Hier!!!');
          this.merkatorMapService.mapRef.setInteraction({type: MerkatorMapInteractionTypes.Off});
          console.log('GETTING INTERACTION  ',this.merkatorMapService.mapRef.interaction);
          sub.unsubscribe();
        });
        break;
      case "Alles":
        const wfsModel: ExportWFSModel = {
          featuretype: this.selectedFeatureType.featuretype + '_geojson',
          geojson: ""
        }
        const sub2 = this.merkatorMapService.getAllFeaturesWithinPoly(wfsModel, this.wfsURL).subscribe(value1 => {
          console.log(value1);

          this.featuresForExport = this.compManager.mapService.mapRef.getFeaturesFromGeoJSON(value1, this.merkatorMapService.mapRef.projectionCode);
          let style = this.compManager.mapService.mapRef.styles.filter(x => x.stylename.trim().toLowerCase() === this.selectedFeatureType.displayname.trim().toLowerCase())[0];
          if (style === undefined)
            style = this.merkatorMapService.mapRef.styleServ.redStyle;
          console.log('TESTESTESTEST', style);
          this.featuresForExport.forEach(x => x.setStyle(style));
          sub2.unsubscribe();
        });
        break;
    }
  }

  exportToDXF(features: Feature[], layers: string[]) {
    let d = new Drawing();
    let i = 1;
    const layername = layers[0]
    layers.forEach(value => d.addLayer(layername, Drawing.ACI.RED, 'DASHED'));
    d.setUnits("Meters");
    features.forEach((value) => {
      d.setActiveLayer(layername);
      const geom = value.getGeometry();
      console.log(geom.getType(), value)
      if (geom instanceof Polygon) {
        const coords = geom.getCoordinates()[0] as [number, number][];
        d.drawPolyline(coords);
      } else if (geom instanceof LineString) {
        const coords = geom.getCoordinates() as [number, number][];
        d.drawPolyline(coords);
      } else if (geom instanceof MultiLineString) {
        const coords = geom.getCoordinates()[0] as [number, number][];
        d.drawPolyline(coords, false);
      }
    });
    const blob = new Blob([d.toDxfString()], {type: 'application/dxf'});
    saveAs(blob, 'data.dxf');
  }

  exportToKML(features: Feature[]) {
    const kmlFormat = new KML();
    const localProj = this.merkatorMapService.mapRef.projectionCode;


    features.forEach(x=>{
      let geom = x.getGeometry();
      geom.transform(localProj,'EPSG:4326');
    });
    const kmlData = kmlFormat.writeFeatures(features);
    const parser = new xml2js.Parser();

    parser.parseString(kmlData, (err, result) => {
      const kmlString = new XMLSerializer().serializeToString(new DOMParser().parseFromString(kmlData, 'text/xml'));
      const blob = new Blob([kmlString], {type: 'application/vnd.google-earth.kml+xml'});
      saveAs(blob, 'data.kml');
    });
  }

  getFeaturesFromPoly(geoJson: GeoJSON) {
    const f = this.merkatorMapService.mapRef.getFeaturesFromGeoJSON(geoJson, this.merkatorMapService.mapRef.projectionCode);
    const g = this.merkatorMapService.mapRef.getGeoJsonFromFeatures(f) as Object as GeoJSON.FeatureCollection;
    const wfsModel: ExportWFSModel = {
      featuretype: this.selectedFeatureType.featuretype + '_geojson',
      geojson: JSON.stringify(g.features[0].geometry)
    }
    const sub = this.merkatorMapService.getAllFeaturesWithinPoly(wfsModel, this.wfsURL).subscribe(value1 => {
      this.featuresForExport = this.compManager.mapService.mapRef.getFeaturesFromGeoJSON(value1, this.merkatorMapService.mapRef.projectionCode);
      this.featuresForExportFiltered = this.featuresForExport;
      sub.unsubscribe();
    });
  }
  filterFeatures(){
    if(this.searchText.length===0)
    {
      this.featuresForExportFiltered = this.featuresForExport;
      return this.selectedFeature = 0;
    }
    if(this.featuresForExport[0].get('name')!==undefined)
      this.featuresForExportFiltered = this.featuresForExport.filter(x=>x.getId().toString().toLowerCase().includes(this.searchText.toString().toLowerCase()) || x.get('name').toLowerCase().includes(this.searchText.toString().toLowerCase()));
    else
      this.featuresForExportFiltered = this.featuresForExport.filter(x=>x.getId().toString().toLowerCase().includes(this.searchText.toString().toLowerCase()));
    this.featuresForExportFiltered = [... new Map(this.featuresForExportFiltered.map(item =>
      [item['id_'], item])).values()];
  }
  getGeom($event: Event) {
    const sub = this.merkatorMapService.getGeomGemeente(+$event).subscribe(value => {
      const gemeenteFeature = this.compManager.mapService.mapRef.getFeaturesFromGeoJSON(value, this.merkatorMapService.mapRef.projectionCode);
      this.compManager.mapService.mapRef.addFeatureCollectionToVectorLayer("Tekenlaag", gemeenteFeature);
      this.compManager.mapService.mapRef.zoomToFeature(gemeenteFeature[0]);
      this.getFeaturesFromPoly(value);
      sub.unsubscribe();
    });
  }

  setFeature(featuretype: any) {
    this.selectedFeatureType = this.typesOfFeatures.filter(x => x.featuretype === featuretype)[0];
  }

  private async exportToSHP(features: any) {
    let geojson = this.compManager.mapService.mapRef.getGeoJsonFromFeatures(features, false) as any;
    let zipOptions = {
      folder: 'myshapes',
      names: ["SHPExport"],
      polyline: "LineString",
      polygon: "polygon",

    };
    try {
      await download(geojson, zipOptions);
    } catch (error) {
      console.log(error)
    }
  }

  selectFeature(value: any) {
    console.log(value);
    this.selectedFeature = +value;
  }
}
