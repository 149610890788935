<div style="position:relative; padding: 0; margin: 0">
  <h1 mat-dialog-title> {{ data.title | translate :{ Default: 'Kies uw organisatie'}  }} </h1> <button mat-flat-button (click)="close()" style="position: absolute; top: 0px; right: 0px;">X</button>
</div>
<hr>
<div mat-dialog-content>
  <div dynamicComponent *ngIf="compManager.modalComponent" [params]="data.params" [desiredComponent]="compManager.modalComponent">


  </div>
</div>
<!--
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Ok</button>
</div>
-->