import { Component, OnInit } from '@angular/core';
import { AppState } from '../../../state-interface/app.state';
import { Store } from '@ngrx/store';
import * as AppConfigActions from '../../../actions/appconfig-actions/appconfig.actions';
import * as matModalActions from '../../../actions/matmodal-actions/matmodal.actions';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../../services/app-state-services/application.service';
import { HttpService } from '../../../services/app-utililty-services/http.service';

export class Organisation {
  id: string;
  name: string;
}

@Component({
  selector: 'app-chooseorganisation',
  templateUrl: './chooseorganisation.component.html',
  styleUrls: ['./chooseorganisation.component.scss']
})
export class ChooseorganisationComponent implements OnInit {

  constructor(public appService: ApplicationService, public http: HttpService, private store: Store<AppState>,
              private translate: TranslateService) { }

  orgArray: Array<Organisation>;
  showArray: Array<Organisation>;

  ngOnInit(): void {
    this.orgArray = this.fillArray();
    this.showArray = this.orgArray;
  }

  fillArray(): Array<Organisation> {
    const result = new Array<Organisation>();
    for (const key in this.appService.organisations) {
      const value = this.appService.organisations[key];
      const org = new Organisation();
      org.id = key;
      org.name = value;
      result.push(org);
    }
    return result;
  }

  setDefaultLanguage(): void {
    this.http.getText(this.http.apiUrl + 'MerkatorLanguage/GetLanguage')
      .subscribe((result: string) => this.translate.setDefaultLang(result));
  }

  onChooseOrganisation(idOrg: number, orgName: string): void {
    this.appService.organisatieTitle = orgName;
    this.http.id_organisation = idOrg.toString();
    sessionStorage.setItem('io', this.http.id_organisation);
    this.setDefaultLanguage();
    this.store.dispatch(new AppConfigActions.LoadAppConfig());
    this.store.dispatch(new matModalActions.MatModalClose());
  }

  filterMyArray(filter: string): void {
    if (filter === '') {
      this.showArray = this.orgArray;
    } else {
      this.showArray = this.orgArray.filter( org => org.name.toLowerCase().includes(filter.toLowerCase()));
    }
  }

}
