<div mwlResizable  
style="padding-top: 38px;"
[resizeSnapGrid]="{ top: 50, bottom: 50}" 
[resizeEdges]="{bottom: true, right: false, top: true, left: false}" 
(resizeEnd)="onResizeEnd($event)" 
(resizeStart)="onResizeStart($event)" 
>

    <!-- drag me -->
    <div style="z-index: 20;" class="resize-handle-top theme-background-color" mwlResizeHandle
    [resizeEdges]="{ top: true, bottom: true }"> 
    </div>

    <!-- drag me invisible -->
    <div style="z-index: 20;" class="resize-handle-top-invis" mwlResizeHandle
    [resizeEdges]="{ top: true, bottom: true }"> 
    </div>

    

    <!-- clear -->
    <button class="top" matTooltip="{{ 'tooltip.clear' | translate }}" mat-icon-button style="position: fixed; top:4px; right: 0px; z-index: 1000;" (click)="onClose();">
        <mat-icon style="font-size: 28px;">clear</mat-icon>
    </button>

    <!-- fullscreen -->
    <button class="top" matTooltip="{{ 'tooltip.fullscreen' | translate }}" mat-icon-button style="position: fixed; top:4px; right: 32px; z-index: 1000;" (click)="goFullScreen();">
        <mat-icon style="font-size: 28px;">{{screenstatus}}</mat-icon>
    </button>

    <!---->
    <div id="mybottomsheetheighthandler">
        <div dynamicComponent 
        *ngIf="compManager.bottomSheetComponent && compManager.bottomSheetParams"
        [params]="compManager.bottomSheetParams" 
        [desiredComponent]="compManager.bottomSheetComponent">
        </div>
    </div>
</div>