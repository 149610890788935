import {Injectable, Type, EventEmitter} from '@angular/core';
import {CustomDynamicComponent} from '../../classes-types/dynamic-comp.types';
import {MerkatorGridComponent} from '../../components/app-grid-components/merkator-grid/merkator-grid.component';
import {
  MerkatorLayerControlComponent
} from '../../components/app-map-components/merkator-layer-control/merkator-layer-control.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../state-interface/app.state';
import * as MerkatorMapActions from '../../actions/map-actions/merkator-map.actions';
import * as modalActions from '../../actions/matmodal-actions/matmodal.actions';
import * as buttonActions from '../../actions/button-actions/button.actions';
import {
  MerkatorFeatureComponent
} from '../../components/app-map-components/merkator-feature/merkator-feature.component';
import {FeaturelistComponent} from '../../components/app-map-components/featurelist/featurelist.component';
import {
  ChooseorganisationComponent
} from '../../components/app-state-components/chooseorganisation/chooseorganisation.component';
import {GkalFormImplComponent} from '../../components/gkal-components/gkal-form-impl/gkal-form-impl.component';
import {
  HtmlinputcomponentComponent
} from '../../components/gkal-components/htmlinputcomponent/htmlinputcomponent.component';
import {MatSidenav} from '@angular/material/sidenav';
import {MerkatorMapService} from '../app-map-services/merkator-map.service';
import {
  GridprevioussaveddialogComponent
} from '../../components/app-grid-components/gridprevioussaveddialog/gridprevioussaveddialog.component';
import {
  MerkatorMapprintComponent
} from '../../components/app-map-components/merkator-mapprint/merkator-mapprint.component';
import {
  UserManagementComponent
} from '../../components/app-toolbar-components/user-management/user-management.component';
import {
  NmpExportMailingComponent
} from '../../components/app-map-components/nmp-export-mailing/nmp-export-mailing.component';
import {ImageCarouselComponent} from '../../components/app-utility-components/image-carousel/image-carousel.component';
import {CronJobMenuComponent} from "../../components/app-utility-components/CronJobMenu/cron-job-menu.component";
import {
  FeatureExporterComponent
} from "../../components/app-map-components/feature-exporter/feature-exporter.component";

export enum Components {
  DEMOCOMPONENT = 'DemoComponent',
  GRIDCOMPONENT = 'MerkatorGridComponent',
  MERKATORLAYERCONTROL = 'MerkatorLayerControl',
  MERKATORFEATURECOMPONENT = 'MerkatorFeatureComponent',
  MERKATORFEATURELISTCOMPONENT = 'FeaturelistComponent',
  CHOOSEORGANISATION = 'ChooseorganisationComponent',
  GKALFORMIMPLCOMPONENT = 'GkalformimplComponent',
  HTMLINPUTCOMPONENT = 'HtmlinputcomponentComponent',
  SAVEDGRIDSTATECOMPONENT = 'GridprevioussaveddialogComponent',
  MERKATORMAPPRINTCOMPONENT = 'MerkatorMapprintComponent',
  USERMANAGEMENTCOMPONENT = 'UserManagementComponent',
  NMPEXPORTMAILINGCOMPONENT = 'NmpExportMailingComponent',
  IMAGECAROUSEL = 'ImageCarouselComponent',
  CRONJOBMENU = 'CronJobMenuComponent',
  EXPORTFEATURE= 'FeatureExporterComponent'
}

@Injectable()
export class ComponentmanagerService {

  constructor(private store: Store<AppState>, public mapService: MerkatorMapService) {
  }

  leftSideComponent: Type<CustomDynamicComponent>;
  leftSideParams: any;
  rightSideComponent: Type<CustomDynamicComponent>;
  rightSideParams: any;
  modalComponent: Type<CustomDynamicComponent>;
  bottomSheetComponent: Type<CustomDynamicComponent>;
  bottomSheetParams: any;
  backdrop: boolean = false;
  cronJobSideComponent: Type<CustomDynamicComponent>;
  cronJobSideParams: any;

  public StringToComponent(component: string): Type<CustomDynamicComponent> {
    switch (component) {
      case Components.SAVEDGRIDSTATECOMPONENT:
        return GridprevioussaveddialogComponent;
      case Components.GRIDCOMPONENT:
        return MerkatorGridComponent;
      case Components.USERMANAGEMENTCOMPONENT:
        return UserManagementComponent;
      case Components.CHOOSEORGANISATION:
        return ChooseorganisationComponent;
      case Components.MERKATORLAYERCONTROL:
        return MerkatorLayerControlComponent;
      case Components.MERKATORFEATURECOMPONENT:
        return MerkatorFeatureComponent;
      case Components.MERKATORFEATURELISTCOMPONENT:
        return FeaturelistComponent;
      case Components.GKALFORMIMPLCOMPONENT:
        return GkalFormImplComponent;
      case Components.HTMLINPUTCOMPONENT:
        return HtmlinputcomponentComponent;
      case Components.MERKATORMAPPRINTCOMPONENT:
        return MerkatorMapprintComponent;
      case Components.NMPEXPORTMAILINGCOMPONENT:
        return NmpExportMailingComponent;
      case Components.IMAGECAROUSEL:
        return ImageCarouselComponent;
      case Components.CRONJOBMENU:
        return CronJobMenuComponent;
      case Components.EXPORTFEATURE:
        return FeatureExporterComponent;
      default:
        return null;
    }
  }

  public StringToMethod(method: string, params: any, object?: any): void {
    const methode = method.toLowerCase();
    switch (methode) {
      case 'openmodal':
        this.store.dispatch(new modalActions.MatModalOpen({
          title: params.title,
          component: params.component,
          params: {}
        }));
        break;
      case 'setinteraction':
        this.store.dispatch(new MerkatorMapActions.TalkToMapReference(params));
        break;
      case 'selectlayer':
        this.mapService.multiSelectSettings = object;
        this.store.dispatch(new MerkatorMapActions.TalkToMapReference(params));
        break;
      case 'klicselect':
        this.store.dispatch(new MerkatorMapActions.TalkToMapReference(params));
        break;
      case 'off':
        this.store.dispatch(new buttonActions.ClearActiveButton());
        this.store.dispatch(new MerkatorMapActions.TalkToMapReference({type: 'off', geometry: 'null'}));
        break;
      case 'downloadsomething':
        const link = document.createElement('a');
        link.download = 'download';
        link.href = params.url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        break;
      default:
        break;
    }
  }

}
