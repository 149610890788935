import {Component, OnInit, Input, EventEmitter, ViewChild, ChangeDetectorRef} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as ButtonState from '../../../reducers/button-reducer/button.reducer';
import { ViewerButton } from '../../../classes-types/button';
import { MatSidenav } from '@angular/material/sidenav';
import { ComponentmanagerService } from '../../../services/app-state-services/componentmanager.service';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';

@Component({
  selector: 'app-topbuttoncontainer',
  templateUrl: './topbuttoncontainer.component.html',
  styleUrls: ['./topbuttoncontainer.component.scss']
})
export class TopbuttoncontainerComponent implements OnInit {

  constructor(private store: Store<AppState>, public compManager: ComponentmanagerService, public buttonService: ButtonmethodService, public cdRef: ChangeDetectorRef) { }
  buttons: Array<ViewerButton>;
  positionObject: { left: string, right: string, top: string, bottom: string };
  activeButton = null;

  @Input() leftSideNav: MatSidenav;
  @Input() rightSideNav: MatSidenav;
  @Input() leftEvent: EventEmitter<any>;
  @Input() rightEvent: EventEmitter<any>;
  @Input() initTop?: string;
  @Input() initLeft?: string;
  @Input() initRight?: string;
  @Input() initBottom?: string;

  ngOnInit(): void {
    this.positionObject = { left: this.initLeft, right: this.initRight, top: this.initTop, bottom: this.initBottom };
    this.store.pipe(select(ButtonState.getTopButtons)).subscribe((button: ButtonState.ButtonState) => {
      if (button.topLoaded) {
        this.buttons = button.topButtons;
      }
    });
    this.rightEvent.subscribe( value => {
      if ( value === 'refresh') {
        setTimeout(() => {
          this.openedStart();
        }, 10);
      } else if ( value === 'open') {
        this.rightSideNav.open();
      }
    });
    this.rightSideNav.closedStart.subscribe(() => {
      this.closedStart();
    });
    this.store.pipe(select(ButtonState.getActiveButton)).subscribe(value => {
      if (value.activeButton !== null) {
        this.activeButton = value.activeButton;
      } else if ( value.activeButton === null) {
        this.activeButton = null;
      }
    });

  }

  openedStart(): void {
    if (this.positionObject.left === undefined) {
        this.positionObject.right = this.rightSideNav._getWidth() + 10 + 'px';
    } else if (this.positionObject.right === undefined) {
        this.positionObject.left = this.rightSideNav._getWidth() + 10 + 'px';
    }
  }

  closedStart(): void {
    if (this.positionObject.left === undefined) {
      this.positionObject.right = this.initRight;
    } else if (this.positionObject.right === undefined) {
      this.positionObject.left = this.initLeft;
    }
  }
}
