import {Injectable, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {ComponentmanagerService} from '../app-state-services/componentmanager.service';
import {AppState} from '../../state-interface/app.state';
import {Store} from '@ngrx/store';
import * as ButtonActions from '../../actions/button-actions/button.actions';
import {ViewerButton} from '../../classes-types/button';
import * as BottomSheetActions from '../../actions/bottomsheet-actions/bottomsheet.actions';
import {MerkatorMapInteractionTypes} from '@merkator/merkator-map';
import * as merkatorMapActions from '../../actions/map-actions/merkator-map.actions';
import {PatchNotesComponent} from "../../components/app-toolbar-components/patch-notes/patch-notes.component";
import {CronJobMenuComponent} from "../../components/app-utility-components/CronJobMenu/cron-job-menu.component";
import {MatDialog} from "@angular/material/dialog";

export class DrawButton {
  icon: string;
  method: string;
  tooltip: string;
}

@Injectable({
  providedIn: 'root'
})
export class ButtonmethodService {

  constructor(private compManager: ComponentmanagerService, private store: Store<AppState>, public dialog: MatDialog) {
  }

  leftSideNavEvent: EventEmitter<any> = new EventEmitter();
  rightSideNavEvent: EventEmitter<any> = new EventEmitter();
  bottomSheetEvent: EventEmitter<any> = new EventEmitter();
  cronjobNavEvent: EventEmitter<any> = new EventEmitter();
  activeButton: ViewerButton;
  /**
   * Buttons On Draw Finished (normale teken flow)
   */
  onDrawFinishedButtons: Array<DrawButton> = [
    {icon: 'done', method: 'done', tooltip: 'tooltip.finish'},
    {icon: 'add', method: 'renewdrawing', tooltip: 'tooltip.add'},
    // { icon: 'color_lens', method: 'setcolordraw'},
    {icon: 'edit', method: 'Modify', tooltip: 'tooltip.edit'},
    {icon: 'delete', method: 'deletedrawing', tooltip: 'tooltip.delete'}
  ];
  /**
   * Buttons onMeasureFinished
   */
  onMeasureFinishedButtons: Array<DrawButton> = [
    {icon: 'delete', method: 'deletedmeasured', tooltip: 'tooltip.delete'}
  ];
  /**
   * Array voor specifiek KlicAfsnijden
   */
  OnDrawFinishedKlicFeature: Array<DrawButton> = [
    {icon: 'done', method: 'doneKlic', tooltip: 'tooltip.finish'},
  ];

  /**
   * Klic methode, ( mailexport )
   */
  klicDrawFinished: boolean = false;

  /**
   * Normale draw flow finished
   */
  drawfinished: boolean = false;
  measurefinished: boolean = false;


  /**
   * ButtonCreateMyComponent
   * Opent paneel left, right of bottom en zet in de componentmanager service de parameters voor het component die verkregen worden uit de payload
   * @param button de button waarop geklikt word
   * @param mobile of het op de mobiel is
   */
  buttonCreateMyComponent(button: ViewerButton, mobile?: boolean, noParsing?: boolean): void {
    if (this.activeButton === button) {
      this.store.dispatch(new ButtonActions.ClearActiveButton());
      return;
    }

    let object;
    if (noParsing) {
      object = button.payload;
    } else {
      object = JSON.parse(button.payload);
    }
    if (!object) {
      return;
    }

    if (object.payload.params) {
      if (noParsing) {
        object.payload.params.rights = object.rights;
      } else {
        object.payload.params.rights = button.rights;
      }
    }

    if (!object.component && !object.payload.methodName) {
      return;
    }
    if (object.payload.methodName) {
      this.store.dispatch(new ButtonActions.SetActiveButton(button));
      this.compManager.StringToMethod(object.payload.methodName, object.payload.params, object);
    }
    if (mobile) {
      this.emitRightSide(object);
      return;
    }
    switch (object.sidenav) {
      case "left":
        this.emitLeftSide(object);
        break;
      case "right":
        this.emitRightSide(object);
        break;
      case "bottom":
        this.emitBottomSide(object);
        break;
      case "cronjob":
        this.openCronJobDialog(object);
        break;
    }
  }

  private emitBottomSide(object: any) {
    if (this.compManager.bottomSheetComponent === this.compManager.StringToComponent(object.component)) {
      this.store.dispatch(new BottomSheetActions.BottomSheetClose());
      this.compManager.bottomSheetComponent = null;
      this.compManager.bottomSheetParams = null;
      setTimeout(() => {
        this.compManager.bottomSheetParams = object.payload.params;
        this.compManager.bottomSheetComponent = this.compManager.StringToComponent(object.component);
        this.store.dispatch(new BottomSheetActions.BottomSheetOpen({}));
      }, 1000);
      window.dispatchEvent(new Event('resize'));
      return
    }
    this.compManager.bottomSheetParams = object.payload.params;
    this.compManager.bottomSheetComponent = this.compManager.StringToComponent(object.component);
    this.store.dispatch(new BottomSheetActions.BottomSheetOpen({}));

    window.dispatchEvent(new Event('resize'));
  }

  private emitLeftSide(object: any) {
    this.compManager.leftSideParams = object.payload.params;
    this.compManager.leftSideComponent = this.compManager.StringToComponent(object.component);
    this.leftSideNavEvent.emit('refresh');
    this.leftSideNavEvent.emit('open');
  }

  private emitRightSide(object: any) {
    this.compManager.rightSideParams = object.payload.params;
    this.compManager.rightSideComponent = this.compManager.StringToComponent(object.component);
    this.rightSideNavEvent.emit('refresh');
    this.rightSideNavEvent.emit('open');
  }

  onDrawFinishMethods(button: DrawButton): void {
    // console.log(button);
    if (button.method === 'done') {
      // TODO done actions
      this.compManager.StringToMethod('setInteraction', {type: 'done', geometry: null}, null);
      this.store.dispatch(new merkatorMapActions.DoneDrawAction({drawstate: true, flow: 'merkatorTab'}));
    } else if (button.method === 'Modify') {
      // TODO edit polygon
      this.compManager.StringToMethod('setInteraction', {type: MerkatorMapInteractionTypes.Modify});
    } else if (button.method === 'deletedrawing') {
      // TODO delete polygon
      this.compManager.StringToMethod('setInteraction', {type: 'deletedrawing', geometry: null}, null);
    } else if (button.method === 'renewdrawing') {
      this.compManager.StringToMethod('setInteraction', {type: 'renewdrawing', geometry: null}, null);
    } else if (button.method === 'deletedmeasured') {
      this.compManager.StringToMethod('setInteraction', {type: 'deletedmeasured', geometry: null}, null);
    } else if (button.method === 'doneKlic') {
      this.klicDrawFinished = false;
      this.putMapInteractionOff();
      this.store.dispatch(new merkatorMapActions.TalkToMapReference({type: 'exportExcelNmp', exporttype: 'geometry'}));

    }
  }

  putMapInteractionOff(): void {
    this.store.dispatch(new ButtonActions.ClearActiveButton());
    this.compManager.StringToMethod('off', null);
  }

  private openCronJobDialog(object) {

    this.dialog.open(CronJobMenuComponent, {
      height: '35%',
      width: '30%',
      disableClose:true,
      data:{myObjectHolder:object.payload.params}
    });
    this.cronjobNavEvent.emit(object.payload.params.ids);
  }
}
