import * as translateActions from './../../actions/translate-actions/translate.actions';
import * as fromRoot from '../../state-interface/app.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export interface TranslateState extends fromRoot.AppState {
    available_languages: Array<string>;
    current_active_language: string;
}

const initialThemeState: TranslateState = {
    available_languages : new Array<string>('nl', 'fr', 'gb'),
    current_active_language : 'nl'
};

const getTranslateState = createFeatureSelector<TranslateState>('translate');

export const getTranslate = createSelector(
    getTranslateState,
    state => {
        return {
            available_languages : state.available_languages,
            current_active_language : state.current_active_language
        };
    }
);

export function translate_reducer(state: TranslateState = initialThemeState, action: translateActions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case translateActions.TranslateActionTypes.GET_CURRENT_LANGUAGE : {
            return {
                    ...state
                };
        }
        case translateActions.TranslateActionTypes.CHANGE_LANGUAGE: {
            return {
                ...state,
                available_languages: state.available_languages,
                current_active_language : action.payload
            };
        }
        default : {
            return state;
        }
    }
}
