import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import * as merkatorMapState from '../../../reducers/merkator-map-reducer/merkator-map.reducer';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import * as snackbarActions from '../../../actions/snackbar-actions/snackbar.actions';
import { AppState } from '../../../state-interface/app.state';
import { ViewerButton } from '../../../classes-types/button';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { HttpService } from '../../../services/app-utililty-services/http.service';

export interface FeatureIdentifierObject {
  id: number;
  feature_identifier_array: Array<ModificationObject>;
}

export interface ModificationObject {
  method: ModificationParams;
}

export interface ModificationParams {
  name: string;
  delimiter?: string;
  index?: string;
  replace?: string;
  replacewith?: string;
}

@Component({
  selector: 'app-merkator-feature-container',
  templateUrl: './merkator-feature-container.component.html',
  styleUrls: ['./merkator-feature-container.component.scss']
})
export class MerkatorFeatureContainerComponent implements OnInit {

  @Input() featureArray;
  @Input() layerName;
  @Input() hasOneLayer: boolean;
  featureIdGrid: number = 0;

  featureIdentifierArray: Array<FeatureIdentifierObject> = [];

  constructor( private store: Store<AppState>, public buttonManager: ButtonmethodService, private httpService: HttpService) { }

  ngOnInit(): void {
    if (this.featureArray[0].id_grid) {
      this.featureIdGrid = this.featureArray[0].id_grid;
      // console.log(this.featureIdGrid);
    }
    this.store.pipe(select(merkatorMapState.getFeatureIdentifier)).subscribe(result => {
      if (result.loaded) {
        if (result.merkatorMapFeatureIdentifer.length > 0) {
          // console.log(result.merkatorMapFeatureIdentifer);
          this.parseConfigAndPushToArray(result.merkatorMapFeatureIdentifer);
        }
      }
    });
  }

  parseConfigAndPushToArray(featureIdentifier: any): void {
    featureIdentifier.forEach( featureObject => {
      this.featureIdentifierArray.push({id: featureObject.id, feature_identifier_array: JSON.parse(featureObject.feature_identifier_array)});
      // console.log(JSON.parse(featureObject.feature_identifier_array));
      // console.log(featureObject.id);
    });
    // console.log(this.featureIdentifierArray);
  }

  private splitOrReorder(currentValue: string, delimiter: string, index: string): string {
    const indexar = index.split(',');
    const currentar = currentValue.split(delimiter);
    let newvalue = '';
    indexar.forEach(nummer => {
       newvalue += currentar[parseInt(nummer)] + ' ';
    });
    return newvalue;
  }

  private replaceValue(currentValue: string, replacePart: string, replaceWith: string): string {
    const newvalue = currentValue.replace(replacePart, replaceWith);
    return newvalue;
  }

  private getFeatureIdentifierConfig(id: number): FeatureIdentifierObject {
    let result;
    this.featureIdentifierArray.some( featureObject => {
      if (featureObject.id === id) {
        result = featureObject;
        return true;
      }
    });
    return result;
  }

  modifyFeatureValueDisplayName(id: number, featureValue: string): string {
    if (id !== undefined) {
      const config = this.getFeatureIdentifierConfig(id);
      let result = featureValue;
      config.feature_identifier_array.forEach( modification => {
        if ( modification.method.name === 'split') {
          result = this.splitOrReorder(result, modification.method.delimiter, modification.method.index);
        } else if ( modification.method.name === 'replace') {
          result = this.replaceValue(result, modification.method.replace, modification.method.replacewith);
        }
      });
      return result;
    }
    return featureValue;
  }

  highlightFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'highlight', feature}));
    // this.store.dispatch(new merkatorMapActions.TalkToMapReference( { type: 'measure', geometry: 'linestring'} ));
  }

  stopHighlightFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'clearhighlight', feature}));
  }

  zoomToFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'zoomtofeature', feature}));
  }

  openDetailTabFromFeature(feature: any): void {
    /*const gridButton: ViewerButton = this.generateFakeButtonGrid(feature.id_grid, feature.values_.id);
    this.buttonManager.buttonCreateMyComponent(gridButton, false);*/
    if (feature.values_.id) {
      this.generateFakeButtonGrid(feature.id_grid, feature.values_.id);
    } else {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.identifiervaluenotfound';
      payload.panelClass = 'extra-class-snackbar-error';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    }
  }

  async getRightsButton(idgrid: number): Promise<string> {
    return await this.httpService.get(this.httpService.apiUrl + 'Applicatiemenu/GetRolesMenu?idgrid=' + idgrid).toPromise();
  }

   generateFakeButtonGrid(idGrid: number, detailIdValue: any): any {
    const promise = this.getRightsButton(idGrid).then(rights => {
      // console.log(rights);
      const fakeButton = new ViewerButton();
      // console.log(idGrid);
      fakeButton.payload = `
      {
        "payload":{
         "params" : {
                "idGrid" :` + idGrid + `,
                "instantDetail": true,
                "detailIdValue": "` + detailIdValue + `"
            }
        },
        "matsidenavConfig":{
        "hasBackdrop":false
        },
        "sidenav": "bottom",
        "component":"MerkatorGridComponent"
        }
       `;
      fakeButton.rights = JSON.stringify(rights); // '{"edit": false, "create": false, "view": true, "delete": false}';
      this.buttonManager.buttonCreateMyComponent(fakeButton, false);
    }).catch( error => {
      // console.log(error);
      const fakeButton = new ViewerButton();
      this.buttonManager.buttonCreateMyComponent(fakeButton, false);
    });
  }

}
