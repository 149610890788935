import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, Type, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { AppState } from './state-interface/app.state';
import * as ThemeState from './reducers/theme-reducer/theme.reducer';
import * as MenuActions from './actions/menu-actions/menu.actions';
import * as ButtonActions from './actions/button-actions/button.actions';
import * as snackbarActions from './actions/snackbar-actions/snackbar.actions';
import * as bottomSheetActions from './actions/bottomsheet-actions/bottomsheet.actions';
import * as matModalActions from './actions/matmodal-actions/matmodal.actions';
import * as merkatorMapActions from './actions/map-actions/merkator-map.actions';
import * as ButtonState from './reducers/button-reducer/button.reducer';
import { Subscription, combineLatest } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ComponentmanagerService, Components } from './services/app-state-services/componentmanager.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { CustomDynamicComponent } from './classes-types/dynamic-comp.types';
import * as AppConfigActions from './actions/appconfig-actions/appconfig.actions';
import * as AppConfigState from './reducers/appconfig-reducer/appconfig.reducer';
import { AppConfig } from './classes-types/appconfig';
import { ApplicationService } from './services/app-state-services/application.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { HttpService } from './services/app-utililty-services/http.service';
import { filter, take, map, tap } from 'rxjs/operators';
import { LoaderService } from './services/app-utililty-services/loader.service';
import { ViewportService } from './services/app-utililty-services/viewport.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  constructor(public oidcSecurityService: OidcSecurityService,
              public appService: ApplicationService, private store: Store<AppState>,
              public router: Router, public http: HttpService, public loader: LoaderService,
              public viewportService: ViewportService) {
  }

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(res => {
      if (res.isAuthenticated === true) {
        this.http.id_token = res.accessToken;
        this.router.navigate(['/app']);
      } else {
        this.router.navigate(['/autologin']);
      }
    });

    /*
    this.oidcSecurityService.checkAuthIncludingServer().subscribe(res => {

    });*/
    this.store.pipe(select(ThemeState.getTheme)).subscribe((theme: ThemeState.ThemeState) => {
      this.appService.handleThemeChange(theme);
    });

    this.setViewportHeight();
    this.setViewportWidth();

    window.addEventListener('resize', () => {
      this.setViewportHeight();
      this.setViewportWidth();
    });
  }

  setViewportHeight(): void {
    const vh = window.innerHeight * 0.01;
    this.viewportService.viewportHeight = vh * 100;
  }

  setViewportWidth() {
    this.viewportService.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void { }


  private read(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }
    return;
  }
  private write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

}
