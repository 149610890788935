import { Action } from '@ngrx/store';
import * as mainMenuClasses from './../../classes-types/main.menu';

// Enums
export enum MenuTypes {
    LOAD_MENU = '[Menu] load menu',
    SUCCES_LOAD_MENU = '[Menu] succes load menu'
}

export class LoadMenu implements Action {
    readonly type = MenuTypes.LOAD_MENU;
}
export class SuccesLoadMenu implements Action {
    readonly type = MenuTypes.SUCCES_LOAD_MENU;
    constructor(public payload: Array<mainMenuClasses.MainMenu>) {}
}

// Export as 1 ActionType
export type ActionType = LoadMenu | SuccesLoadMenu;
