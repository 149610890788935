import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementAdduserComponent } from '../user-management-adduser/user-management-adduser.component';
import { AppState } from '../../../state-interface/app.state';
import { Store } from '@ngrx/store';
import * as snackbarActions from '../../../actions/snackbar-actions/snackbar.actions';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  constructor(public refresh: ChangeDetectorRef, public http: HttpService, public matDialog: MatDialog, private store: Store<AppState>) { }

  OriginalUsers: User[];
  shownUsers: User[];

  allRoles: Role[];
  assignableRoles: Role[];
  activeRoles: Role[];

  organisationFilter: string = '';
  usernameFilter: string = '';
  activeUser: string = '';
  activeFullName: string = '';
  activeUserId: number;
  organisationName: string = '';
  selectedOrganisation: string = '';

  mainOrganisation: Organisation;
  organisationArray = [];

  somethingChanged: boolean = false;
  organisationid: number;
  saveArray: Array<SaveRole> = [];

  ngOnInit(): void {
    this.loadTheData();
  }

  loadTheData(): void {
    forkJoin(
      this.GetUsers(),
      this.GetRoles()// ,
      // this.GetOrganisationTree()
    )
      .subscribe( result => {
      // console.log(result);
      this.OriginalUsers = result[0] as Array<User>;
      this.shownUsers = this.OriginalUsers;
      this.allRoles = result[1] as Array<Role>;
      // console.log(this.allRoles);
      this.refresh.detectChanges();
    });
  }

  /**
   * Set info at the right to specific user
   * @param user the user
   */
  SetInfo(user: User): void {
    this.activeFullName = user.fullname;
    this.activeUser = user.username;
    this.activeUserId = user.id;
    this.setProfileInfo(user.org);
  }

  /**
   * TEMP function to show frontend a user clicked a role
   * @param role role
   * @param from from
   * @param index index
   */
  userClickedRole(role: Role, from: string, index: number): void {
    this.somethingChanged = true;
    const originalType = from === 'assignable' ? 'removerole' : 'addrole';
    const saveObject = new SaveRole(role.id, this.activeUserId, this.organisationid, role.rolename, '', originalType);
    if ( from === 'assignable' ) {
      saveObject.type = 'addrole';
      if (this.checkSaveTypeArray(saveObject)) {
        this.activeRoles.push(role);
        this.assignableRoles.splice(index, 1);
      }
    } else if ( from === 'active' ) {
      saveObject.type = 'removerole';
      if (this.checkSaveTypeArray(saveObject)) {
        this.assignableRoles.push(role);
        this.activeRoles.splice(index, 1);
      }
    }
  }

  /**
   *
   */
  checkSaveTypeArray(saveRole: SaveRole): boolean {
    let result = false;
    this.saveArray.forEach( element => {
      if (element.idrole === saveRole.idrole && this.organisationid === element.organisationid) {
        element.type = saveRole.type;
        result = true;
      }
    });
    if (!result) {
      this.saveArray.push(saveRole);
      result = true;
    }
    return result;
  }

  /**
   * Creer organisatie object, en recursief, want elke organisatie kan een suborganisatie hebben
   * @param organisation organisatie
   */
  createOrganisationObject(organisation: Organisation): void {
    const assignableRoles = [];
    const activeRoles = [];
    this.allRoles.forEach( element => {
      let match = false;
      if (organisation.roles?.length > 0) {
        organisation.roles.forEach( role => {
          if (!match) {
            if (element.rolename === role) {
              activeRoles.push(element);
              match = true;
            }
          }
        });
      }
      if (!match) {
        assignableRoles.push(element);
      }
    });
    organisation.activeRoles = activeRoles;
    organisation.assignableRoles = assignableRoles;
    if (organisation.orgs?.length > 0 ) {
      organisation.orgs.forEach( element => {
        this.createOrganisationObject(element);
      });
    }
  }

  saveUserRoles(): void {
    // console.log(this.saveArray);
    this.saveArray = this.saveArray.filter( saveObject => saveObject.originalRole !== saveObject.type);
    // console.log(this.saveArray);
    this.http.post(this.http.apiUrl + 'account/ChangeUserRoles', this.saveArray, this.http.currentHeader).subscribe(result => {
      this.saveArray = [];
      this.somethingChanged = false;
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.savesuccess';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
      this.loadTheData();
    });
  }

  /**
   * Zet de zichtbare rollen naar wat de betreffende organisatie heeft
   * @param organisation organisatie
   */
  setRolesFromOrganisation(organisation: Organisation): void {
    this.activeRoles = organisation.activeRoles;
    this.assignableRoles = organisation.assignableRoles;
  }

  /**
   * Onclick van een user, zet hoofdorganisatie en creer het organisatieobject
   * @param organisation organisatie
   */
  setProfileInfo(organisation: any): void {
    // console.log(organisation);
    this.mainOrganisation = new Organisation(organisation[0].name, organisation[0].orgs,
                                               organisation[0].roles, [], [], organisation[0].id);
    this.createOrganisationObject(this.mainOrganisation);
    this.setRolesFromOrganisation(this.mainOrganisation);
    this.organisationName = organisation[0].name;
    this.selectedOrganisation = this.organisationName;
    this.organisationid = organisation[0].id;
  }

  /**
   * Selectboxchangeevent
   * @param event checkboxchangeevent
   */
  selectBoxchanged(event: any): void {
    if (this.mainOrganisation.name === event.value) {
      this.organisationid = this.mainOrganisation.id;
      this.setRolesFromOrganisation(this.mainOrganisation);
    } else {
      if (this.mainOrganisation.orgs?.length > 0) {
        this.mainOrganisation.orgs.forEach( el => {
          this.recursiveFunction(el, event.value);
        });
      }
    }
    // console.log(this.organisationid);
  }

  /**
   * Recursieve functie die de rollen zoekt van de organisatie
   * @param organisation organisatie
   * @param event event
   */
  recursiveFunction(organisation: Organisation, event: string): void {
    if ( organisation.name === event ) {
      this.organisationid = organisation.id;
      this.setRolesFromOrganisation(organisation);
    } else {
      if ( organisation.orgs?.length > 0 ) {
        organisation.orgs.forEach( element => {
          this.recursiveFunction(element, event);
        });
      }
    }
  }

  /**
   * Methode die de userarray filtert
   * @param input input
   *
   * @param type type
   */
  filterUserArray(input: string, type: string): void {
    // console.log(input);
    if (type === 'username') {
      this.usernameFilter = input.toLowerCase();
    } else if (type === 'organisation') {
      this.organisationFilter = input.toLowerCase();
    }
    this.shownUsers = this.OriginalUsers.filter(x => x.org[0].name.toLowerCase().includes(this.organisationFilter)
                                                     && x.username.toLowerCase().includes(this.usernameFilter));
  }

  /**
   * Opent de add user component in een model
   */
  openAddUserModal(): void {
    const reference = this.matDialog.open(UserManagementAdduserComponent, {
      width: '40%',
      height: '40%'
    });
    reference.afterClosed().subscribe(result => {
      // console.log(result);
      this.GetUsers();
    });
  }


  GetUsers(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'account/GetUsers');
  }

  GetRoles(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'account/GetRoles');
  }

  GetUserProfile(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'account/GetProfile');
  }

  GetOrganisationTree(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'account/GetOrganisations');
  }

}

export class User {
  id: number;
  username: string;
  org: any;
  organisation: string;
  fullname: string;
  constructor() {
    this.organisation = this.org[0].name;
  }
}

export class SaveRole {
  constructor(idrole: number, userid: number, organisationid: number, rolename: string, type: string, original: string ) {
    this.idrole = idrole;
    this.userid = userid;
    this.organisationid = organisationid;
    this.rolename = rolename;
    this.type = type;
    this.originalRole = original;
  }
  idrole: number;
  userid: number;
  organisationid: number;
  rolename: string;
  type: string;
  originalRole: string;
}

export class Role {
  id: number;
  rolename: string;
}

export class Organisation  {
  constructor(name: string, orgs: Array<Organisation>, roles: Array<Role>,
              activeRoles: Array<Role>, assignableRoles: Array<Role>, organisationId: number) {
    this.name = name;
    this.orgs = orgs;
    this.roles = roles;
    this.activeRoles = activeRoles;
    this.assignableRoles = assignableRoles;
    this.id = organisationId;
  }
  name: string;
  orgs: Array<Organisation>;
  roles: Array<Role | string>;
  activeRoles: Array<Role>;
  assignableRoles: Array<Role>;
  id: number;
}
