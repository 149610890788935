<!-- MOBIEL -->
<div *ngIf="mobile" style="flex-direction: row; width: auto; display: flex;"> <!--(touchmove)="mouseup()"  (mouseleave)="mouseup()"-->
    <div (mousedown)="mousedown()" (touchstart)="mousedown()" (touchend)="mouseup(mainButton, $event)" (mouseup)="mouseup(mainButton,$event)"  style="max-width: 64px;">
        <button mat-mini-fab color="primary">
            <ng-container>
                <mat-icon>
                    {{mainButton.icon}}
                </mat-icon>
            </ng-container>
        </button>
        <mat-icon style="position: relative; top: 7px; right: 6px;">
            arrow_right
        </mat-icon>
    </div>

    <div @list *ngIf="showMyChildren">
        <div @items *ngFor="let button of button.children" style="margin-left: 5px; float: left;">
            <!--<div *ngIf="!button.children">-->
                <button [matTooltip]="button.tooltip" #tooltip="matTooltip" *ngIf="button != mainButton" (click)="changeMainButton(button, true);" [ngClass]="button.name == activeButton.name ? 'activebutton' : ''" mat-mini-fab color="primary">
                    <ng-container>
                        <mat-icon>
                            {{button.icon}}
                        </mat-icon>
                    </ng-container>
                </button>
            <!--</div>-->
        </div>
        <div *ngIf="mainButton != initMainButton && workAroundShowMain" style="float: left; margin-left: 5px;">
            <button [matTooltip]="initMainButton.tooltip" (click)="changeMainButton(initMainButton, true);" mat-mini-fab color="primary">
                <ng-container>
                    <mat-icon>
                        {{initMainButton.icon}}
                    </mat-icon>
                </ng-container>
            </button>
        </div>
    </div>
</div>

<!-- LINKS  style="flex-direction: row; width: 20vw; display: flex;" -->
<div *ngIf="!mobile && expandTo == 'left'" style="flex-direction: row; width: 20vw; display: flex;">
    <div (click)="ToggleShowMyChildren();">
        <button mat-mini-fab color="primary" style="margin: 3px;">
            <mat-icon>
                {{button.icon}}
            </mat-icon>
        </button>
        <mat-icon style="position: relative; top: 7px; right: 6px;">
            arrow_right
        </mat-icon>
    </div>


    <div @list *ngIf="showMyChildren">
        <div @items *ngFor="let button of button.children" style="margin-top: 3px;margin-bottom: 3px; float: right;">
            <!--<div *ngIf="!button.children"> (click)="buttonCreateMyComponent(button);"-->
                <button mat-mini-fab color="primary">
                    <mat-icon>
                        {{button.icon}}
                    </mat-icon>
                </button>
            <!--</div>-->
        </div>
    </div>
</div>

<!-- Bottom -->
<div *ngIf="!mobile && expandTo == 'bottom'" style="flex-direction: column; height: 100%; display: inline-flex;">
    <!--(click)="ToggleShowMyChildren();" (mouseleave)="mouseup()"-->
    <div (mousedown)="mousedown()" (mouseup)="mouseup(mainButton)"   style="width: 40px; height: 52px; display: inline-block;">
        <button [matTooltip]="mainButton.tooltip" #tooltip="matTooltip" mat-mini-fab color="primary" [ngClass]="mainButton == activeButton ? 'activebutton' : ''">
            <ng-container>
                <mat-icon>
                    {{mainButton.icon}}
                </mat-icon>
            </ng-container>
        </button>
        <mat-icon style="position: relative; right: 7px; bottom: 5px;">
            arrow_drop_down
        </mat-icon>
    </div>


    <div @list *ngIf="showMyChildren">
            <div @items *ngFor="let button of button.children">
                <!--<div *ngIf="!button.children">-->
                    <button [matTooltip]="button.tooltip" #tooltip="matTooltip" *ngIf="button != mainButton" (click)="changeMainButton(button, false);" [ngClass]="button?.name == activeButton?.name ? 'activebutton' : ''" mat-mini-fab color="primary" style="margin-bottom: 5px;">
                        <ng-container>
                            <mat-icon>
                                {{button.icon}}
                            </mat-icon>
                        </ng-container>
                    </button>
                <!--</div>-->
            </div>
            <div *ngIf="mainButton != initMainButton && workAroundShowMain">
                <button [matTooltip]="initMainButton.tooltip" #tooltip="matTooltip" (click)="changeMainButton(initMainButton, false);" mat-mini-fab color="primary" style="margin-bottom: 5px;">
                    <ng-container>
                        <mat-icon>
                            {{initMainButton.icon}}
                        </mat-icon>
                    </ng-container>
                </button>
            </div>
    </div>
</div>


