import * as mapActions from '../../actions/map-actions/merkator-map.actions';
import * as fromRoot from '../../state-interface/app.state';
import * as merkatorMapClasses from '../../classes-types/merkator-map.classes';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SnackBarPayLoad } from '../../actions/snackbar-actions/snackbar.actions';
import { SetInteraction } from '@merkator/merkator-map';

export interface MapConfigState extends fromRoot.AppState {
    merkatorMapConfiguraties: merkatorMapClasses.MerkatorMapConfiguratie[];
    loaded: boolean;
}
export interface LayerState extends fromRoot.AppState {
    merkatorMapLayers: merkatorMapClasses.MerkatorMapLayers[];
    loaded: boolean;
}
export interface StylesState extends fromRoot.AppState {
    merkatorMapStyles: merkatorMapClasses.MerkatorMapStyles[];
    loaded: boolean;
}
export interface MapActionState extends fromRoot.AppState {
    merkatorMapAction: any;
}
export interface MapCategoriesState extends fromRoot.AppState {
    merkatorMapCategories: Array<merkatorMapClasses.MerkatorMapCategories>;
    loaded: boolean;
}
export interface MapLegendItemsState extends fromRoot.AppState {
    merkatorMapLegendItems: Array<merkatorMapClasses.MerkatorMapLegendItems>;
    loaded: boolean;
}
export interface MapFeatureIdentifierState extends fromRoot.AppState {
    merkatorMapFeatureIdentifer: any;
    loaded: boolean;
}
export interface MapSelectedFeaturesState extends fromRoot.AppState {
    merkatorMapSelectedFeatures: Array<any>;
}
export interface MapFiltersState extends fromRoot.AppState {
    merkatorMapFilters: {};
}

export interface MapViewParamsState extends fromRoot.AppState {
    merkatorMapViewParams: {};
    loaded: boolean;
}
export interface MapHttpHeadersState extends fromRoot.AppState {
    merkatorMapHttpHeaders: {};
    loaded: boolean;
}
export interface MapFeatureStyleState extends fromRoot.AppState {
    merkatorMapFeatureStyles: [];
    loaded: boolean;
}
export interface MapTextStyleState extends fromRoot.AppState {
    merkatorMapTextStyleState: [];
    loaded: boolean;
}

export interface DrawedFeatureState extends fromRoot.AppState {
    merkatorMapDrawedFeature: any;
    flow: any;
}

export interface DoneDrawState extends fromRoot.AppState {
    drawstate: boolean;
    flow: any;
    primaryValue: any;
}
export interface ColorPickerState extends fromRoot.AppState {
    activemeasurecolor: string;
    activelinecolor: string;
    activefillcolor: string;
}

const initialColorPickerState: ColorPickerState = {
    activemeasurecolor: 'rgba(0, 199, 175, 1)',
    activelinecolor: 'rgba(0, 199, 175, 1)',
    activefillcolor: 'rgba(0, 199, 175, 1)'
};

const initialDoneDrawState: DoneDrawState = {
    drawstate: false,
    flow: null,
    primaryValue: null
};

const initialMapState: MapConfigState = {
    merkatorMapConfiguraties : new Array<merkatorMapClasses.MerkatorMapConfiguratie>(),
    loaded: false
};

const initialFeatureIdentifierState: MapFeatureIdentifierState = {
    merkatorMapFeatureIdentifer : [],
    loaded: false
};

const initialLayerState: LayerState = {
    merkatorMapLayers : new Array<merkatorMapClasses.MerkatorMapLayers>(),
    loaded: false
};

const initialStylesState: StylesState = {
    merkatorMapStyles : new Array<merkatorMapClasses.MerkatorMapStyles>(),
    loaded: false
};

const initialMapActionState: MapActionState = {
    merkatorMapAction: { type: null, geometry: null} as SetInteraction
};

const initialMapCategoriesState: MapCategoriesState = {
    merkatorMapCategories: new Array<merkatorMapClasses.MerkatorMapCategories>(),
    loaded: false
};

const initialHttpHeadersState: MapHttpHeadersState = {
    merkatorMapHttpHeaders: {},
    loaded: false
};
const initialViewParamsState: MapViewParamsState = {
    merkatorMapViewParams: {},
    loaded: false
};

const initialMapLegendItemsState: MapLegendItemsState = {
    merkatorMapLegendItems: new Array<merkatorMapClasses.MerkatorMapLegendItems>(),
    loaded: false
};

const initialMapFeatureStyleState: MapFeatureStyleState = {
    merkatorMapFeatureStyles: [],
    loaded: false
};

const initialMapTextStyleState: MapTextStyleState = {
    merkatorMapTextStyleState: [],
    loaded: false
};

const initialMapSelectedFeaturesState: MapSelectedFeaturesState = {
    merkatorMapSelectedFeatures: []
};

const initialMapFilters: MapFiltersState = {
    merkatorMapFilters: null
};

const initialDrawedFeature: DrawedFeatureState = {
    merkatorMapDrawedFeature: null,
    flow: null
};

const getMapState = createFeatureSelector<MapConfigState>('map');
const getLayersState = createFeatureSelector<LayerState>('maplayers');
const getStylesState = createFeatureSelector<StylesState>('mapstyles');
const getMapActionState = createFeatureSelector<MapActionState>('mapactions');
const getMapCategoriesState = createFeatureSelector<MapCategoriesState>('mapcategories');
const getMapLegendItemsState = createFeatureSelector<MapLegendItemsState>('maplegenditems');
const getSelectedFeaturesState = createFeatureSelector<MapSelectedFeaturesState>('mapfeatures');
const getMapFiltersState = createFeatureSelector<MapFiltersState>('mapfilters');
const getDrawedFeatureState = createFeatureSelector<DrawedFeatureState>('mapdrawedfeature');
const getViewParamsState = createFeatureSelector<MapViewParamsState>('mapviewparams');
const getHttpHeadersState = createFeatureSelector<MapHttpHeadersState>('maphttpheaders');
const getFeatureIdentifierState = createFeatureSelector<MapFeatureIdentifierState>('mapfeatureidentifiers');
const getFeatureStyleState = createFeatureSelector<MapFeatureStyleState>('mapfeaturestyles');
const getTextStyleState = createFeatureSelector<MapTextStyleState>('maptextstyles');
const getDrawState = createFeatureSelector<DoneDrawState>('donedrawstate');
const getMapColorPickerState = createFeatureSelector<ColorPickerState>('mapcolorpicker');

export const getMapColorPicker = createSelector(
    getMapColorPickerState,
    state => {
        return {
            ...state,
            activemeasurecolor: state.activemeasurecolor,
            activelinecolor: state.activelinecolor,
            activefillcolor: state.activefillcolor
        };
    }
);

export const getMerkatorMapConfiguraties = createSelector(
    getMapState,
    state => {
        return {
            ...state,
            merkatorMapConfiguraties: state.merkatorMapConfiguraties,
            loaded: state.loaded
        };
    }
);

export const getMerkatorMapDrawState = createSelector(
    getDrawState,
    state => {
        return {
            ...state,
            drawstate: state.drawstate,
            flow: state.flow,
            primaryValue: state.primaryValue
        };
    }
);

export const getMerkatorMapFeatureStyle = createSelector(
    getFeatureStyleState,
    state => {
        return {
            ...state,
            merkatorMapFeatureStyles: state.merkatorMapFeatureStyles,
            loaded: state.loaded
        };
    }
);

export const getMerkatorMapTextStyle = createSelector(
    getTextStyleState,
    state => {
        return {
            ...state,
            merkatorMapTextStyleState: state.merkatorMapTextStyleState,
            loaded: state.loaded
        };
    }
);

export const getLayers = createSelector(
    getLayersState,
    state => {
        return {
            ...state,
            merkatorMapLayers : state.merkatorMapLayers,
            loaded: state.loaded
        };
    }
);

export const getStyles = createSelector(
    getStylesState,
    state => {
        return {
            ...state,
            merkatorMapStyles : state.merkatorMapStyles,
            loaded: state.loaded
        };
    }
);

export const TalkToMapReference = createSelector(
    getMapActionState,
    state => {
        return {
            ...state,
            merkatorMapAction: state.merkatorMapAction
        };
    }
);

export const getCategories = createSelector(
    getMapCategoriesState,
    state => {
        return {
            ...state,
            merkatorMapCategories: state.merkatorMapCategories,
            loaded: state.loaded
        };
    }
);

export const getLegendItems = createSelector(
    getMapLegendItemsState,
    state => {
        return {
            ...state,
            merkatorMapLegendItems: state.merkatorMapLegendItems,
            loaded: state.loaded
        };
    }
);

export const getViewParams = createSelector(
    getViewParamsState,
    state => {
        return {
            ...state,
            merkatorMapViewParams: state.merkatorMapViewParams,
            loaded: state.loaded
        };
    }
);

export const getHttpHeaders = createSelector(
    getHttpHeadersState,
    state => {
        return {
            ...state,
            merkatorMapHttpHeaders: state.merkatorMapHttpHeaders,
            loaded: state.loaded
        };
    }
);

export const getSelectedFeatures = createSelector(
    getSelectedFeaturesState,
    state => {
        return {
            ...state,
            merkatorMapSelectedFeatures: state.merkatorMapSelectedFeatures
        };
    }
);

export const getMapFilters = createSelector(
    getMapFiltersState,
    state => {
        return {
            ...state,
            merkatorMapFilters: state.merkatorMapFilters
        };
    }
);

export const getDrawedFeature = createSelector(
    getDrawedFeatureState,
    state => {
        return {
            ...state,
            merkatorMapDrawedFeature: state.merkatorMapDrawedFeature
        };
    }
)
;
export const getFeatureIdentifier = createSelector(
    getFeatureIdentifierState,
    state => {
        return {
            ...state,
            merkatorMapFeatureIdentifer: state.merkatorMapFeatureIdentifer,
            loaded: state.loaded
        };
    }
);


export function mapconfig_reducer(state: MapConfigState = initialMapState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_CONFIGURATIES : {
                return {
                    merkatorMapConfiguraties: state.merkatorMapConfiguraties,
                    loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_CONFIGURATIES : {
                return {
                    merkatorMapConfiguraties: action.payload,
                    loaded: true
                };
            }
            default : {
                return state;
            }
        }
}

export function mapdrawstate_reducer(state: DoneDrawState = initialDoneDrawState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.DONE_DRAW_ACTION : {
                return {
                    drawstate: action.payload.drawstate,
                    flow: action.payload.flow,
                    primaryValue: action.payload.primaryValue
                };
            }
            default : {
                return state;
            }
        }
}

export function maphttp_reducer(state: MapHttpHeadersState = initialHttpHeadersState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_HTTPHEADERS : {
                return {
                    merkatorMapHttpHeaders: state.merkatorMapHttpHeaders,
                    loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_HTTPHEADERS : {
                return {
                    merkatorMapHttpHeaders: action.payload == null ? {} : action.payload,
                    loaded: true
                };
            }
            default : {
                return state;
            }
        }
}

export function mapviewparams_reducer(state: MapViewParamsState = initialViewParamsState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_VIEWPARAMS : {
                return {
                    merkatorMapViewParams: state.merkatorMapViewParams,
                    loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_VIEWPARAMS : {
                return {
                    merkatorMapViewParams: action.payload == null ? {} : action.payload,
                    loaded: true
                };
            }
            default : {
                return state;
            }
        }
}


export function maplayer_reducer(state: LayerState = initialLayerState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_LAYERS : {
                return {
                        merkatorMapLayers: state.merkatorMapLayers,
                        loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_LAYERS : {
                return {
                    merkatorMapLayers: action.payload,
                    loaded: true
                };
            }
            case mapActions.MerkatorMapTypes.GET_MERKATOR_MAP_LAYERS : {
                return {
                        ...state
                    };
            }
            default : {
                return state;
            }
        }
    }

export function mapstyles_reducer(state: StylesState = initialStylesState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_STYLES : {
                return {
                        merkatorMapStyles: state.merkatorMapStyles,
                        loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_STYLES : {
                return {
                    merkatorMapStyles: action.payload,
                    loaded: true
                };
            }
            default : {
                return state;
            }
        }
    }

export function mapactions_reducer(state: MapActionState = initialMapActionState, action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.TALK_TO_MAP_REFERENCE : {
                return {
                        merkatorMapAction: action.payload
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapfeatures_reducer(state: MapSelectedFeaturesState = initialMapSelectedFeaturesState,
                                    action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.SET_SELECTED_FEATURES : {
                return {
                        merkatorMapSelectedFeatures: action.payload
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapfilters_reducer(state: MapFiltersState = initialMapFilters,
                                   action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_FILTERS : {
                return {
                        merkatorMapFilters: action.payload,
                        loaded: true
                    };
            }
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_FILTERS : {
                return {
                        merkatorMapFilters: state.merkatorMapFilters,
                        loaded: false
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapfeatureidentifier_reducer(state: MapFeatureIdentifierState = initialFeatureIdentifierState,
                                             action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_FEATUREIDENTIFIER : {
                return {
                        merkatorMapFeatureIdentifer: state.merkatorMapFeatureIdentifer,
                        loaded: false
                    };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_FEATUREIDENTIFIER : {
                return {
                        merkatorMapFeatureIdentifer: action.payload,
                        loaded: true
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapcategories_reducer(state: MapCategoriesState = initialMapCategoriesState,
                                      action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_CATEGORIES: {
                return {
                        merkatorMapCategories: state.merkatorMapCategories,
                        loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_CATEGORIES : {
                return {
                        merkatorMapCategories: action.payload,
                        loaded: true
                    };
            }
            default : {
                return state;
            }
        }
}

export function maplegenditems_reducer(state: MapLegendItemsState = initialMapLegendItemsState,
                                       action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_LEGEND_ITEMS: {
                return {
                        merkatorMapLegendItems: state.merkatorMapLegendItems,
                        loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_LEGEND_ITEMS : {
                return {
                        merkatorMapLegendItems: action.payload,
                        loaded: true
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapfeaturestyles_reducer(state: MapFeatureStyleState = initialMapFeatureStyleState,
                                         action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_FEATURESTYLE: {
                return {
                        merkatorMapFeatureStyles: state.merkatorMapFeatureStyles,
                        loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_FEATURESTYLE : {
                return {
                        merkatorMapFeatureStyles: action.payload,
                        loaded: true
                    };
            }
            default : {
                return state;
            }
        }
}

export function maptextstyle_reducer(state: MapTextStyleState = initialMapTextStyleState,
                                     action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_TEXTSTYLES: {
                return {
                        merkatorMapTextStyleState: state.merkatorMapTextStyleState,
                        loaded: false
                };
            }
            case mapActions.MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_TEXTSTYLES : {
                return {
                        merkatorMapTextStyleState: action.payload,
                        loaded: true
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapdrawedfeature_reducer(state: DrawedFeatureState = initialDrawedFeature,
                                         action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.SET_DRAWED_FEATURE: {
                return {
                        merkatorMapDrawedFeature: action.payload
                };
            }
            case mapActions.MerkatorMapTypes.GET_DRAWED_FEATURE : {
                return {
                        merkatorMapDrawedFeature: state.merkatorMapDrawedFeature
                    };
            }
            case mapActions.MerkatorMapTypes.RESET_DRAWED_FEATURE : {
                return {
                        merkatorMapDrawedFeature: null
                    };
            }
            default : {
                return state;
            }
        }
}

export function mapcolorpicker_reducer(state: ColorPickerState = initialColorPickerState,
                                       action: mapActions.ActionType ): fromRoot.AppState {
        switch (action.type) {
            case mapActions.MerkatorMapTypes.SET_ACTIVE_MEASURE_COLOR: {
                return {
                        activelinecolor: state.activelinecolor,
                        activefillcolor: state.activefillcolor,
                        activemeasurecolor: action.payload
                };
            }
            case mapActions.MerkatorMapTypes.SET_ACTIVE_LINE_COLOR: {
                return {
                        activefillcolor: state.activefillcolor,
                        activemeasurecolor: state.activemeasurecolor,
                        activelinecolor: action.payload
                };
            }
            case mapActions.MerkatorMapTypes.SET_ACTIVE_FILL_COLOR: {
                return {
                        activemeasurecolor: state.activemeasurecolor,
                        activelinecolor: state.activelinecolor,
                        activefillcolor: action.payload
                };
            }
            default : {
                return state;
            }
        }
}




