import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, OnChanges, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as gridState from '../../../reducers/merkator-grid-reducer/merkator-grid.reducer';
import * as mapState from '../../../reducers/merkator-map-reducer/merkator-map.reducer';
import { AppState } from '../../../state-interface/app.state';
import * as GridActions from '../../../actions/grid-actions/merkator-grid.actions';
import { MerkatorGridService } from '../../../services/app-grid-services/merkator-grid.service';
import { Subscription, combineLatest } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as AppConfigActions from '../../../actions/appconfig-actions/appconfig.actions';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import * as snackbarActions from '../../../actions/snackbar-actions/snackbar.actions';
import * as matModalActions from '../../../actions/matmodal-actions/matmodal.actions';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { HttpHeaders } from '@angular/common/http';
import { Components } from '../../../services/app-state-services/componentmanager.service';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import { MerkatorMapInteractionTypes } from '@merkator/merkator-map';
import { buttonActions } from '@merkator/merkator-grid';
import { MatDialog } from '@angular/material/dialog';
import { MerkatorDocumentsComponent } from '../../app-documents-components/merkator-documents/merkator-documents.component';

@Component({
  selector: 'app-merkator-grid',
  templateUrl: './merkator-grid.component.html',
  styleUrls: ['./merkator-grid.component.scss']
})
export class MerkatorGridComponent implements OnInit, OnDestroy {

  // Comes from Database, number of grid configuration you want ; )
  @Input() idGrid: number;
  @Input() whereclause?: string;

  // Rights from button
  @Input() rights?: ButtonRights;

  // properties gezet door dynamiccomponentdirective vanuit faked button (vanuit feature)
  instantDetail?: boolean = false;
  detailIdValue?: any;

  showGrid: boolean = false;
  geometryChangedEvent: EventEmitter<any> = new EventEmitter<any>();
  $geometrySub: Subscription;
  $geometrySubDraw: Subscription;

  columnDefinitions: any[] = [];
  gridConfiguration: any = {};
  dataset: any[] = [];

  item = { row: 0, columns: [], values: []};
  showDetail: boolean = false;
  currentActiveGrid: number = null;
  $subscription: Subscription;
  $subscriptionActive: Subscription;
  userPressedReload: boolean = false;
  userPressedOpenStateSettings: boolean = false;

  tabButtons: any = { event: null, action: '' };

  metrics: any = { rows: 0 };
  tabs: string[] = [];
  showColumns: any [];
  sidepanel: any = { columns: []};
  showGrouping: boolean = false;
  activeRows: number;
  gridInput: { columnDefinitions: any; gridConfiguration: any; data: any; preset: any; };
  gridSettings = {
    sidepanel: true,
    logger: {
      level: 'verbose'
    },
  };
  gridheight: number;
  featurePropertyZoom: any;
  connectedIdLayer: any;
  stringifiedState: string;

  detailGridConfig: any;
  detailObject: any;
  httpHeaders: HttpHeaders;

  newObjectInDetail: boolean = false;

  drawedGeometry;
  addGeometryType = 'normal';

  constructor(
    private store: Store<AppState>,
    public gridService: MerkatorGridService,
    public refresh: ChangeDetectorRef,
    private httpService: HttpService,
    public mapService: MerkatorMapService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.httpHeaders = null;
    this.httpHeaders = this.httpService.currentHeader;
    this.gridService.whereclause = this.whereclause;
    this.gridService.button = { event: null, action: '' };
    if (this.instantDetail) {
      this.detailObject = {
        identifiercolumn: 'id',
        identifiervalue: this.detailIdValue
      };
    }
    this.gridService.idGrid = this.idGrid;
    this.store.dispatch(new AppConfigActions.SetAppLoading(true));
    this.$subscription = this.store.pipe(select(gridState.getGrid)).subscribe(result => {


      if (result.grid.loaded === false || this.currentActiveGrid !== this.idGrid) {
        if (this.idGrid !== undefined) {
          this.store.dispatch(new GridActions.LoadGridFull(this.idGrid));
        }
      } else if ( result.grid.loaded === true ) {

        this.gridInput = { columnDefinitions: [], gridConfiguration: [], data: [], preset: ''};
        this.gridInput.columnDefinitions = result.grid.gridObject.grid_columns;
        this.gridInput.gridConfiguration = result.grid.gridObject.grid_options;
        console.log(this.gridInput.gridConfiguration.endpoint);
        if(location.origin.includes("localhost:4200")){
          this.gridInput.gridConfiguration.endpoint = "https://localhost:44392/api/merkatorgrid/detail";
        }

        if ( this.userPressedReload ) {
          this.gridInput.preset = JSON.parse(this.stringifiedState);
          this.userPressedReload = false;

        } else {
          this.gridInput.preset = JSON.parse(result.grid.gridObject.preset);
        }

        if (this.gridInput.gridConfiguration.createable && this.rights.create) {
          this.gridInput.gridConfiguration.buttons = [{action: 'add', payload: 'detail'}];
        } else {
          this.gridInput.gridConfiguration.buttons = [];
        }


        this.gridInput.data = result.grid.gridObject.grid_data;


        this.detailGridConfig = this.gridInput.gridConfiguration;

        this.setGeometryColumnProperty(this.gridInput.columnDefinitions);
        this.setGeometryLayer(this.gridInput.gridConfiguration);
        if (this.instantDetail) {
          this.showDetail = true;
        }
        this.showGrid = true;
        this.refresh.detectChanges();

      }
    });
    this.$subscriptionActive = this.store.pipe(select(gridState.getGridActive)).subscribe( result => {
      if ( result.gridactive.currentActiveGrid != null) {
        this.currentActiveGrid = result.gridactive.currentActiveGrid;
      }
    });
    this.store.pipe(select(gridState.getGridHeight)).subscribe(result => {
      this.gridheight = result.gridHeight.gridHeight.height;
    });
    this.gridService.resetgridstate.subscribe( result => {
      this.gridService.updateButtons(new Event('click'), 'setPreset', { preset: JSON.parse(result) } );
      this.refresh.detectChanges();
    });

    // get the geometry
    this.$geometrySub = this.store.pipe(select(mapState.getDrawedFeature)).subscribe(result => {
      if (result.merkatorMapDrawedFeature?.flow === 'merkatorTab') {
        this.drawedGeometry = result.merkatorMapDrawedFeature;
      }
    });
    this.$geometrySubDraw = this.store.pipe(select(mapState.getMerkatorMapDrawState)).subscribe( result => {

      if (this.addGeometryType === 'normal') {
        this.geometryChangedEvent.emit({ geometry: this.drawedGeometry, result, action: 'none' });
      } else if (this.addGeometryType === 'reference') {
        this.geometryChangedEvent.emit({ geometry: this.drawedGeometry, result, action: 'openCreateReference'});
      }
      this.refresh.detectChanges();
    });

  }


  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
    this.$subscriptionActive.unsubscribe();
    this.$geometrySub.unsubscribe();
    this.$geometrySubDraw.unsubscribe();
    this.showGrid = false;
    this.tabs = null;
  }

  exportToShp(): void {
    this.gridService.button = { event: null, action: 'getGridIDs' };
  }

  geometryColumnhandler(datafromgrid: any): void {

    this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: 'zoomtofeaturebyproperty', datafromgrid,
                        featurePropertyZoom : this.featurePropertyZoom, connectedIdLayer: this.connectedIdLayer }));
  }

  setGeometryLayer(gridConfig: any): void {
    this.connectedIdLayer = gridConfig.fk_id_layer;
  }

  reloadGrid(): void {
    this.userPressedReload = true;
    this.gridService.button = { event: null, action: buttonActions.updateGridInfo };
    this.showGrid = false;
    this.refresh.detectChanges();
    this.store.dispatch(new GridActions.LoadGridFull(this.idGrid));
  }

  merkatorTabEmitter($event: {type: any, value: any}): void {

    if ($event.type === 'zoomToMap') {
      if ($event.value.connectedidlayer) {
        this.connectedIdLayer = $event.value.connectedidlayer;
      }
      let featureProperty = this.featurePropertyZoom;
      if ( featureProperty === undefined) {
        featureProperty = $event.value.values.fieldname;
      }
      const datafromgrid = { content: $event.value.values.value };
      this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: 'zoomtofeaturebyproperty', datafromgrid,
      featurePropertyZoom : featureProperty, connectedIdLayer: this.connectedIdLayer }));
    } else if ( $event.type === 'featureToBlob' ) {
      this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: 'getmapblobbyproperty',  connectedIdLayer: $event.value.connectedidlayer,
      identifiercolumn: $event.value.identifiercolumn, identifiervalue: $event.value.identifiervalue }));
    } else if ($event.type === 'changedorder' ) {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'tooltip.changedorder';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'addGeometryTab' ) {
      this.addGeometryType = 'normal';
      this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: MerkatorMapInteractionTypes.Draw,
          geometry: $event.value.detailparams.type, flow: 'merkatorTab', primaryValue: $event.value.values.value }));
    } else if ($event.type === 'addGeometryTabFromCreateReference') {
      this.addGeometryType = 'reference';
      this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: MerkatorMapInteractionTypes.Draw,
        geometry: $event.value.detailparams.type, flow: 'merkatorTab', primaryValue: $event.value.values.value }));
    } else if ( $event.type === 'back') {
      this.instantDetail = false;
      this.showDetail = false;
      this.gridService.button = { event: null, action: '' };
      this.showGrid = true;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        this.refresh.detectChanges();
      }, 1);
    } else if ( $event.type === 'refresh' ) {
      this.store.dispatch(new GridActions.SetGridHeight( new GridActions.GridHeight(this.gridService.height, 'refresh')));
    } else if ( $event.type === 'saveError') {
      const payload = new snackbarActions.SnackBarPayLoad();
      const fields = this.createSaveErrorString($event.value);
      payload.message = 'popup.saveerror';
      payload.panelClass = 'extra-class-snackbar-error';
      payload.params = fields;
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'saveErrorContact') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.saveerrocontactadmin';
      payload.panelClass = 'extra-class-snackbar-error';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'reedsgekoppeld') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.alreadyconnected';
      payload.panelClass = 'extra-class-snackbar-error';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'requiredError') {
      const payload = new snackbarActions.SnackBarPayLoad();
      const fields = this.createSaveErrorString($event.value);
      payload.message = 'popup.required';
      payload.params = fields;
      payload.panelClass = 'extra-class-snackbar-error';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'saveSuccess') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.savesuccess';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'saveNothing') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.savenothing';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'deletekeyError') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.deletekeyerror';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'deleteError') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.deleteerror';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'backDeleteSucces') {
      this.showDetail = false;
      this.gridService.button = { event: null, action: '' };
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.deletesuccess';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'saveFirstBeforeCreatingReference') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.savefirstprior';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'sharedSuccess') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.sharedsuccess';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'notShared' ) {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.notshared';
      payload.panelClass = 'extra-class-snackbar-error';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'successcopy') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'clipboard.success';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'mandatorySaveGeometryFlow') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.savefirstprior';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'fileislocked') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.fileislocked';
      payload.panelClass = 'extra-class-snackbar-error';
      payload.delay = 10000;
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'fileisadded') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.fileisadded';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'fileinternal') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.fileinternal';
      payload.panelClass = 'extra-class-snackbar-error';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    } else if ( $event.type === 'filedeleted') {
      const payload = new snackbarActions.SnackBarPayLoad();
      payload.message = 'popup.deletesuccess';
      payload.panelClass = 'extra-class-snackbar';
      this.store.dispatch(new snackbarActions.SnackbarOpen(payload));
    }
  }

  newForm(): void {
    this.newObjectInDetail = true;
    this.detailGridConfig = this.gridInput.gridConfiguration;
    let field;
    this.gridInput.columnDefinitions.forEach(element => {
      if (element.fieldtype === 'detail') {
        field = element.field;
      }
    });
    this.detailObject = {
      identifiercolumn: field,
      identifiervalue: null
    };
    this.httpHeaders = this.httpService.currentHeader;
    this.showDetail = true;
  }

  createSaveErrorString( array: string[]): string {
    let result = '';
    array.forEach(str => {
      result += str + ' ';
    });
    return result;
  }

  setGeometryColumnProperty(gridinput: any): void {
    gridinput.forEach( element => {
      if ( element.fieldtype === 'marker') {
        this.featurePropertyZoom =  element.field;
      }
    });
  }

  detailInfo(e: any): void {
  }

  openDetailPane(e: any): void {
    const value = e.content.value.toString();
    this.newObjectInDetail = false;
    this.detailGridConfig = this.gridInput.gridConfiguration;

    this.detailObject = {
      identifiercolumn: e.content.key,
      identifiervalue: value
    };
    this.httpHeaders = this.httpService.currentHeader;
    this.showDetail = true;
  }

  gotoTab(e: any, newAction: string, payload: any): void {
    this.tabButtons = {
      event: e,
      action: newAction,
      payload
    };
  }

  updateTabs(e: any): void {
    this.tabs = e;
  }

  readSidepanel(e: any): void {
    this.sidepanel = {
      columns: e.columns
    };
  }

  closeDetailPane(e: any): void {
    this.tabs = [];
    this.store.dispatch(new GridActions.SetGridHeight( new GridActions.GridHeight(this.gridheight, 'none') ));
    this.showDetail = false;
  }

  updateMetrics(event: any): void {

    this.activeRows = event.rows.filtered;
    this.stringifiedState = JSON.stringify(event);
    this.refresh.detectChanges();
    if (this.userPressedOpenStateSettings) {
      this.store.dispatch(new matModalActions.MatModalOpen(
        { title: 'Settings', component: 'GridprevioussaveddialogComponent' as Components.SAVEDGRIDSTATECOMPONENT,
          params: { state: this.stringifiedState, idGrid: this.idGrid } }));
      this.userPressedOpenStateSettings = false;
    }

  }

  parseCustomEvent(event: any): void {
    console.log('GRID ID', this.idGrid);
    console.log('CONTENT ID', event.content.id);
    const gridid = this.idGrid;
    const id = event.content.id;
    this.httpService.get( this.httpService.apiUrl + 'document/GetRapportagesForObject?idgrid=' + gridid + '&id=' + id).subscribe(result => {
      console.log('RESULT', result);
      this.dialog.open(MerkatorDocumentsComponent, {
        height: '75%',
        width: '75%',
        disableClose:false,
        data:result
      });
    });
  }


  /** TODO */

  openStateSettings(event: any): void {
    this.userPressedOpenStateSettings = true;
    this.gridService.button = { event: null, action: buttonActions.updateGridInfo };
  }

  yourFn(event: MatTabChangeEvent): void {

    if (this.tabs[event.index - 1] === undefined) {
      this.gotoTab(new Event('click'), 'cancel', null);
    } else {
      this.gotoTab(new Event('click'), 'tab', {tabName: this.tabs[event.index - 1]});
    }
  }

  myToggleChangeGroup(event: MatSlideToggleChange): void {
    this.showGrouping = event.checked;
    this.gridService.updateButtons(new Event('click'), 'preheaderpanel', null);
  }

  sendExportToShapeFileRequest(idArray: Array<number>): void {
    const body = {
      idArray,
      idgrid: this.idGrid,
      gridname: this.gridInput.gridConfiguration.custom_table
    };
    this.httpService.postArrayBufferResponse( this.httpService.apiUrl + 'shp', body).subscribe(result => {
      this.downloadFile(result);
    });
  }

  downloadFile(data: any): void {
    const file = new Blob([data], { type: 'application/zip' });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = fileURL;
    a.target = '_blank';
    a.download = 'exportshp.zip';
    a.click();
    a.remove();
  }

  buttonEvent(event: any): void {

    if (event.content.key === 'gridIDs') {
      this.sendExportToShapeFileRequest(event.content.value);
    } else {
      this.detailGridConfig = this.gridInput.gridConfiguration;
      this.detailObject = {
        identifiercolumn: 'id',
      };
      this.newObjectInDetail = true;
      this.httpHeaders = this.httpService.currentHeader;
      this.showDetail = true;
    }
  }
}

export class ButtonRights {
  create: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
  procurement: boolean;
}
