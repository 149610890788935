import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../../../services/app-utililty-services/http.service';

@Component({
  selector: 'app-user-management-adduser',
  templateUrl: './user-management-adduser.component.html',
  styleUrls: ['./user-management-adduser.component.scss']
})
export class UserManagementAdduserComponent implements OnInit {

  formGroup: UntypedFormGroup;
  usernameControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  fullNameControl: UntypedFormControl = new UntypedFormControl('');

  constructor(public dialogRef: MatDialogRef<UserManagementAdduserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder, private http: HttpService) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({});
    this.formGroup.addControl('usernameControl', this.usernameControl);
    this.formGroup.addControl('fullNameControl', this.fullNameControl);
    /*
    this.formGroup.valueChanges.subscribe(result => {
      console.log(this.formGroup);
      console.log(result);
    });
    */
  }

  saveUser(): void {
    // console.log(this.formGroup.controls['usernameControl'].value);
    // console.log(this.formGroup.controls['fullNameControl'].value);
    const UserPost = {
      fullname: this.formGroup.controls['fullNameControl'].value,
      username: this.formGroup.controls['usernameControl'].value
    };
    this.http.post(this.http.apiUrl + 'account/adduser', UserPost, this.http.currentHeader).subscribe(result => {
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
