import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthgaurdService implements CanActivate {

  constructor(private oicdSecurityService: OidcSecurityService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    return this.oicdSecurityService.isAuthenticated$.pipe(
      map(({ isAuthenticated, allConfigsAuthenticated }) => {
        if (isAuthenticated) {
          return true;
        }

        // this.router.navigate(['/unauthorized']);
        return false;
      })
    );
  }

}
