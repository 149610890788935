import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { OverlayContainer } from '@angular/cdk/overlay';
import * as ThemeState from '../../reducers/theme-reducer/theme.reducer';
import { MatSidenav } from '@angular/material/sidenav';
import { HttpService } from '../app-utililty-services/http.service';
import { MainMenu } from '../../classes-types/main.menu';

@Injectable()
export class ApplicationService {

  constructor(private http: HttpService, private OverlayContainer: OverlayContainer) { }

  CurrentActiveIdApplicatie: number;
  theme: string;
  weWaitForTheToken: EventEmitter<any> = new EventEmitter();
  organisations;
  organisatieTitle: string;

  leftSideNavRef: MatSidenav;
  rightSideNavRef: MatSidenav;
  hasAdminPower = false;

  /**
   * Checks if the user has the correct role to view Logs & User management :)
   */
  public hasAppAdmin(): void {
    this.http.get( this.http.apiUrl + 'account/hap' ).subscribe(result => {
      this.hasAdminPower = result;
    });
  }

  getAppConfig(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'ApplicatieConfiguratie');
  }

  getMainMenu(container: string, idApplicatie?: number): Observable<MainMenu[]> {
    return this.http.get(this.http.apiUrl + 'Applicatiemenu/' + this.CurrentActiveIdApplicatie + '?container=' + container);
  }

  public handleThemeChange(theme: ThemeState.ThemeState): void {
    this.theme = theme.current_theme;
    // this.theme = theme.current_theme;
    if (theme.old_theme != null) {
      this.OverlayContainer.getContainerElement().classList.remove(theme.old_theme);
    }
    this.OverlayContainer.getContainerElement().classList.add(theme.current_theme);
  }

}
