<!--
<button (click)="zoomIn();" mat-raised-button color="primary">Zoom +</button>
<button (click)="test();" mat-raised-button color="primary">Zoom -</button>
-->
<ng-container *ngIf="config && layersLoaded && stylesLoaded && featureStylesLoaded && textStylesLoaded">
    <merkator-map merkatorDraw merkatorMeasure #map1
                [buttonsPosition]="mMapButtonPosition"
                [addCoordinatesStyleId]="addCoordStyle.id"
                [addCoordinatesEnabled]="true"
                [popupOnSearch]="true"
                [baseUrl]="config.baseurl"
                [projectionCode]="projectionCode"
                [stylesArray]="stylesArray"
                [layersArray]="layersArray"
                [zoomControl]="false"
                (mapReady)="CustomMapReady($event)"
                [layerswitch]="false"
                [filterDict]="filterDict"
                (drawFinished)="drawFinished($event)"
                (modifiedFeature)="modifiedFeature($event)"
                [viewParams]="viewParams"
                [requestHeaders]="httpHeaders"
                [coordinateFrame]="true"
                [scaleFrame]="true"
                [initialCenter]="initialCenter"
                [initialZoom]="initialZoom"
                (measured)="measureFinished($event)"
                (coordinatesCopied)="coordinatesCopied($event)"
                [featureStyles]="featureStyles"
                [textStyles]="textStyles"
                (mapToBlob)="mapToBlob($event)"
                [language]="mapService.language"
                (klicFeature)="klicFeatureFinished($event);"
                (selectedPart)="klicSelectedPart($event);"
                (interactionCanceled)="interactionCanceled($event)"
                (onError)="errorEventThrownByMap($event)"
                [stylesMenuConfig]="stylesMenuConfig"
    >
    </merkator-map>
</ng-container>
