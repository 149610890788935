import { Action } from '@ngrx/store';
import * as merkatorMapClasses from '../../classes-types/merkator-map.classes';

// Enum -- Constants voor Action types
export enum MerkatorMapTypes {
    LOAD_MERKATOR_MAP_CONFIGURATIES = '[MerkatorMap] load merkator map configuraties',
    LOAD_SUCCES_MERKATOR_MAP_CONFIGURATIES = '[MerkatorMap] load succes merkator map configuraties',
    LOAD_MERKATOR_MAP_LAYERS = '[MerkatorMap] load merkator map layers',
    LOAD_SUCCES_MERKATOR_MAP_LAYERS = '[MerkatorMap] load succes merkator map layers',
    LOAD_MERKATOR_MAP_STYLES = '[MerkatorMap] load merkator map styles',
    LOAD_SUCCES_MERKATOR_MAP_STYLES = '[MerkatorMap] load succes merkator map styles',
    LOAD_MERKATOR_MAP_CATEGORIES = '[MerkatorMap] load merkator map categories',
    LOAD_SUCCES_MERKATOR_MAP_CATEGORIES = '[MerkatorMap] load succes merkator map categories',
    LOAD_MERKATOR_MAP_LEGEND_ITEMS = '[MerkatorMap] load merkator map legend items',
    LOAD_SUCCES_MERKATOR_MAP_LEGEND_ITEMS = '[MerkatorMap] load succes merkator map legend items',
    GET_MERKATOR_MAP_LAYERS = '[MerkatorMap] get merkator map layers',
    TALK_TO_MAP_REFERENCE = '[MerkatorMap] talk to map reference',
    // SET_MULTISELECT_SETTINGS = '[MerkatorMap] set multi select settings',
    SET_SELECTED_FEATURES = '[MerkatorMap] set selected features',
    LOAD_MERKATOR_MAP_FILTERS = '[MerkatorMap] load merkator map filters',
    LOAD_SUCCES_MERKATOR_MAP_FILTERS = '[MerkatorMap] load succes merkator map filters',
    SET_DRAWED_FEATURE = '[MerkatorMap] set drawed feature',
    GET_DRAWED_FEATURE = '[MerkatorMap] get drawed feature',
    LOAD_MERKATOR_MAP_VIEWPARAMS = '[MerkatorMap] load merkator map view params',
    LOAD_SUCCES_MERKATOR_MAP_VIEWPARAMS = '[MerkatorMap] load succes merkator map view params',
    LOAD_MERKATOR_MAP_HTTPHEADERS = '[MerkatorMap] load merkator map http headers',
    LOAD_SUCCES_MERKATOR_MAP_HTTPHEADERS = '[MerkatorMap] load succes merkator map http headers',
    LOAD_MERKATOR_MAP_FEATUREIDENTIFIER = '[MerkatorMap] load merkator map feature identifier',
    LOAD_SUCCES_MERKATOR_MAP_FEATUREIDENTIFIER = '[MerkatorMap] load succes merkator map feature identifier',
    LOAD_MERKATOR_MAP_FEATURESTYLE = '[MerkatorMap] load merkator map feature styles',
    LOAD_SUCCES_MERKATOR_MAP_FEATURESTYLE = '[MerkatorMap] load succes merkator map feature styles',
    LOAD_MERKATOR_MAP_TEXTSTYLES = '[MerkatorMap] load merkator map text styles',
    LOAD_SUCCES_MERKATOR_MAP_TEXTSTYLES = '[MerkatorMap] load succes merkator map text styles',
    DONE_DRAW_ACTION = '[MerkatorMap] done draw action',
    RESET_DRAWED_FEATURE = '[MerkatorMap] reset drawed feature',
    SET_ACTIVE_MEASURE_COLOR = '[MerkatorMap] set active measure color',
    SET_ACTIVE_LINE_COLOR = '[MerkatorMap] set active line color',
    SET_ACTIVE_FILL_COLOR = '[MerkatorMap] set active fill color'
}

export class SetActiveMeasureColor implements Action {
    readonly type = MerkatorMapTypes.SET_ACTIVE_MEASURE_COLOR;
    constructor(public payload: string) {}
}
export class SetActiveLineColor implements Action {
    readonly type = MerkatorMapTypes.SET_ACTIVE_LINE_COLOR;
    constructor(public payload: string) {}
}
export class SetActiveFillColor implements Action {
    readonly type = MerkatorMapTypes.SET_ACTIVE_FILL_COLOR;
    constructor(public payload: string) {}
}
export class DoneDrawAction implements Action {
    readonly type = MerkatorMapTypes.DONE_DRAW_ACTION;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapConfiguraties implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_CONFIGURATIES;
}

export class LoadSuccesMerkatorMapConfiguraties implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_CONFIGURATIES;
    constructor(public payload: Array<merkatorMapClasses.MerkatorMapConfiguratie>) {}
}

export class LoadMerkatorMapLayers implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_LAYERS;
}

export class LoadMerkatorMapViewParams implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_VIEWPARAMS;
}
export class LoadSuccesMerkatorMapViewParams implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_VIEWPARAMS;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapHttpHeaders implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_HTTPHEADERS;
}

export class LoadSuccesMerkatorMapHttpHeaders implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_HTTPHEADERS;
    constructor(public payload: any) {}
}

export class LoadSuccesMerkatorMapLayers implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_LAYERS;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapStyles implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_STYLES;
}

export class LoadSuccesMerkatorMapStyles implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_STYLES;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapCategories implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_CATEGORIES;
}

export class LoadSuccesMerkatorMapCategories implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_CATEGORIES;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapLegendItems implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_LEGEND_ITEMS;
}

export class LoadSuccesMerkatorMapLegendItems implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_LEGEND_ITEMS;
    constructor(public payload: any) {}
}

export class TalkToMapReference implements Action {
    readonly type = MerkatorMapTypes.TALK_TO_MAP_REFERENCE;
    constructor(public payload: any) {}
}

export class GetMerkatorMapLayers implements Action {
    readonly type = MerkatorMapTypes.GET_MERKATOR_MAP_LAYERS;
}

export class LoadMerkatorMapFilters implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_FILTERS;
}

export class LoadSuccesMerkatorMapFilters implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_FILTERS;
    constructor(public payload: any) {}
}
export class LoadMerkatorMapFeatureIdentifiers implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_FEATUREIDENTIFIER;
}

export class LoadSuccesMerkatorMapFeatureIdentifiers implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_FEATUREIDENTIFIER;
    constructor(public payload: any) {}
}

export class SetDrawedFeature implements Action {
    readonly type = MerkatorMapTypes.SET_DRAWED_FEATURE;
    constructor(public payload: any) {}
}

export class GetDrawedFeature implements Action {
    readonly type = MerkatorMapTypes.GET_DRAWED_FEATURE;
}

export class ResetDrawedFeature implements Action {
    readonly type = MerkatorMapTypes.RESET_DRAWED_FEATURE;
}

export class SetClickedFeatures implements Action {
    readonly type = MerkatorMapTypes.SET_SELECTED_FEATURES;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapFeatureStyle implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_FEATURESTYLE;
}

export class LoadSuccesMerkatorMapFeatureStyle implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_FEATURESTYLE;
    constructor(public payload: any) {}
}

export class LoadMerkatorMapTextStyle implements Action {
    readonly type = MerkatorMapTypes.LOAD_MERKATOR_MAP_TEXTSTYLES;
}

export class LoadSuccesMerkatorMapTextStyle implements Action {
    readonly type = MerkatorMapTypes.LOAD_SUCCES_MERKATOR_MAP_TEXTSTYLES;
    constructor(public payload: any) {}
}



export type ActionType = GetMerkatorMapLayers | LoadMerkatorMapConfiguraties | LoadSuccesMerkatorMapConfiguraties
                    | LoadSuccesMerkatorMapLayers | LoadMerkatorMapLayers | TalkToMapReference
                    | LoadMerkatorMapStyles | LoadSuccesMerkatorMapStyles | LoadSuccesMerkatorMapCategories | LoadMerkatorMapCategories
                    | LoadMerkatorMapLegendItems | LoadSuccesMerkatorMapLegendItems | SetClickedFeatures
                    | LoadMerkatorMapFilters | LoadSuccesMerkatorMapFilters | SetDrawedFeature  | GetDrawedFeature
                    | LoadMerkatorMapHttpHeaders | LoadSuccesMerkatorMapHttpHeaders | LoadMerkatorMapViewParams
                    | LoadSuccesMerkatorMapViewParams | LoadMerkatorMapFeatureIdentifiers | LoadSuccesMerkatorMapFeatureIdentifiers
                    | LoadMerkatorMapFeatureStyle | LoadSuccesMerkatorMapFeatureStyle | LoadMerkatorMapTextStyle
                    | LoadSuccesMerkatorMapTextStyle | DoneDrawAction | ResetDrawedFeature | SetActiveMeasureColor
                    | SetActiveLineColor | SetActiveFillColor;
