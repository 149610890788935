import { Action } from '@ngrx/store';

// Enum -- Constants voor Action types
export enum TranslateActionTypes {
    GET_CURRENT_LANGUAGE = '[Translate] get current Translate',
    CHANGE_LANGUAGE = '[Translate] changed'
}

// ChangeThemeAction om thema te krijgen
export class GetTranslateAction implements Action {
    readonly type = TranslateActionTypes.GET_CURRENT_LANGUAGE;
}

// ChangeThemeAction om thema te wijzigen
export class ChangeTranslateAction implements Action {
    readonly type = TranslateActionTypes.CHANGE_LANGUAGE;
    constructor(public payload: string) { }
}


// Export alle actions onder 1 type
export type ActionType = ChangeTranslateAction | GetTranslateAction;

