import { Component, OnInit, OnDestroy } from '@angular/core';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import { AppState } from '../../../state-interface/app.state';
import { Store, select } from '@ngrx/store';
import * as merkatorMapState from '../../../reducers/merkator-map-reducer/merkator-map.reducer';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import { Subscription, forkJoin, combineLatest } from 'rxjs';


@Component({
  selector: 'app-merkator-layer-control',
  templateUrl: './merkator-layer-control.component.html',
  styleUrls: ['./merkator-layer-control.component.scss']
})
export class MerkatorLayerControlComponent implements OnInit, OnDestroy {

  constructor(public mapService: MerkatorMapService, private store: Store<AppState>) { }

  config: {
    layers: any[];
    mapcategories: any[];
    legendItems: any[];
  };
  
  subscriptionArray: Array<Subscription> = [];

  ngOnInit(): void {
    this.store.dispatch(new merkatorMapActions.LoadMerkatorMapCategories());
    this.store.dispatch(new merkatorMapActions.LoadMerkatorMapLegendItems());
    this.subscriptionArray.push(
      combineLatest(
        this.store.select(merkatorMapState.getLayers),
        this.store.select(merkatorMapState.getCategories),
        this.store.select(merkatorMapState.getLegendItems)
      ).subscribe((result: any) => {
          if (result[0].merkatorMapLayers.length > 0
              && result[1].merkatorMapCategories.length > 0
              && result[2].loaded
              ) {
            this.config = {
              layers: result[0].merkatorMapLayers,
              mapcategories: result[1].merkatorMapCategories,
              legendItems: result[2].merkatorMapLegendItems
            };
          }
        })
    );



  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
