import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { MerkatorMapComponent, MerkatorDrawDirective } from '@merkator/merkator-map';
import { HttpService } from '../app-utililty-services/http.service';
import { ApplicationService } from '../app-state-services/application.service';
import { ExportToExcelNmp } from '../../classes-types/merkator-map.classes';
import { Store } from '@ngrx/store';
import { AppState } from '../../state-interface/app.state';
import * as modalActions from '../../actions/matmodal-actions/matmodal.actions';
import * as buttonActions from '../../actions/button-actions/button.actions';
import GeoJSON from "ol/format/GeoJSON";

@Injectable()
export class MerkatorMapService {

  constructor(public http: HttpService, public appService: ApplicationService, private store: Store<AppState>) { }

  mapRef: MerkatorMapComponent;
  drawRef: MerkatorDrawDirective;
  active_id_map: number;
  multiSelectSettings: any;
  klicTypeMelding: string;
  accesToken: string;
  canvas: { timestamp: number, canvas: Blob };
  language: 'GB' | 'FR' | 'NL'|string;
  canvasReportEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  config: any;

  /**
   * Properties voor nmpExportMailComponent ( export eigenaar percelen naar excel )
   */
  currentBuffer: number;
  gekozengemeeente: number[];
  typeSelected: string[];
  cutFromSelectedPartGeometry: any;
  idpipe: number;
 public getMapRef():MerkatorMapComponent{
    return this.mapRef;
  }
  getAllMapConfiguratie(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormapconfiguratie/' + this.appService.CurrentActiveIdApplicatie);
  }

  getAllMapLayers(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormaplayers/' + this.active_id_map);
  }

  getAllMapStyles(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormapstyles');
  }

  getAllMapViewParams(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormapviewparams');
  }

  getAllMapHttpHeaders(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormaphttpheaders');
  }

  getAllMapCategories(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormapcategories');
  }

  getAllMapLegendItems(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormaplegenditems');
  }

  getAllFilters(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'merkatormapfilters');
  }

  getAllFeatureIdentifiers(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'MerkatorMapFeatureIdentifier');
  }

  getAllFeatureStyles(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'MerkatorMapFeatureStyles');
  }

  getAllTextStyles(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'MerkatorMapTextStyles');
  }

  fillExportMailingToExcelObject(type: string): ExportToExcelNmp {
    if ( type === 'gemeente' ) {
      return {
        idgemeente: this.gekozengemeeente,
        buffer: this.currentBuffer,
        geometry: null,
        idpipe: this.idpipe,
        typeselected: this.typeSelected
      };
    } else if ( type === 'wholepipe' ) {
      return {
        idgemeente: [],
        buffer: this.currentBuffer,
        geometry: null,
        idpipe: this.idpipe,
        typeselected: this.typeSelected
      };
    } else {
      return {
        idgemeente: [],
        buffer: this.currentBuffer,
        geometry: this.cutFromSelectedPartGeometry,
        idpipe: this.idpipe,
        typeselected: this.typeSelected
      };
    }
  }

  exportMailingToExcel(type: string): void {
    this.http.postArrayBufferResponse(this.http.apiUrl + 'document/GetCSVOwnerPipes', this.fillExportMailingToExcelObject(type)).subscribe( result => {
      // console.log(result);
      this.downloadFile(result);
    });
  }

  downloadFile(data: any): void {
    const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = fileURL;
    a.target = '_blank';
    a.download = 'export.xlsx';
    a.click();
    a.remove();
    this.store.dispatch(new modalActions.MatModalClose());
    this.store.dispatch(new buttonActions.ClearActiveButton());
  }
  getGemeenten(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'pipelink/getallgemeenten');
  }
  getAllFeaturesWithinPoly(geoJson:any,wfsURL:string):Observable<any>{
    return this.http.post(wfsURL,geoJson);
  }

  getGeomGemeente(id: number): Observable<GeoJSON> {
    return this.http.get(this.http.apiUrl+ 'pipelink/getGeomGemeente/'+id);
  }
}
