import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import { ComponentmanagerService } from '../../../services/app-state-services/componentmanager.service';

@Component({
  selector: 'app-matmodal',
  templateUrl: './matmodal.component.html',
  styleUrls: ['./matmodal.component.scss']
})
export class MatmodalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MatmodalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<AppState>, public compManager: ComponentmanagerService) { }

  ngOnInit(): void {
    this.compManager.modalComponent = this.compManager.StringToComponent(this.data.component);
  }

  close(): void {
    this.compManager.StringToMethod('off', null);
    this.dialogRef.close();
  }

}
