<div style="padding-left: 10px; padding-right: 10px; padding-top: -10px; box-sizing: border-box; display: flex;">
    <div style="flex-grow: 1; position: relative; max-width: 600px; margin-top: -25px; padding-right: 30px;">
        <div style="position:absolute; height: 35px; width: 35px; bottom: 30px; right: 50px; z-index: 10000;">
            <button (click)="openAddUserModal();" mat-mini-fab aria-label="Add a user">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div>
        <mat-grid-list [cols]="2" rowHeight="40px" [gutterSize]="'10px'">
                <!--<mat-grid-tile 
                    [colspan]="0.5"
                    [rowspan]="1"
                    [style.color]="'white'"
                    [style.background]="'white'"
                > 
                    
                </mat-grid-tile>-->
                <mat-grid-tile 
                    [colspan]="1"
                    [rowspan]="1"
                    [style.color]="'#007A7A'"
                    [style.background]="'white'"
                >
                {{ 'usermanagement.username' | translate }}
                </mat-grid-tile>
                <mat-grid-tile 
                [colspan]="1"
                [rowspan]="1"
                [style.color]="'#007A7A'"
                [style.background]="'white'"
                >
                {{ 'usermanagement.organisation' | translate }}
            </mat-grid-tile>

                <!--<mat-grid-tile
                    [colspan]="0.5"
                    [rowspan]="1"
                >
                    
                </mat-grid-tile>-->
                <mat-grid-tile
                    [colspan]="1"
                    [rowspan]="1"
                >
                    <mat-form-field style="width: 70%;">
                        <input [id]="'custom_id_forcc_filter_prefix1'" (keyup)="filterUserArray(inputus.value, 'username')" #inputus matInput placeholder="Filter" [value]="usernameFilter">
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile 
                [colspan]="1"
                [rowspan]="1"
                >
                    <mat-form-field style="width: 70%;">
                        <input [id]="'custom_id_forcc_filter_prefix2'" (keyup)="filterUserArray(inputorg.value, 'organisation')" #inputorg matInput placeholder="Filter" [value]="organisationFilter">
                    </mat-form-field> 
                </mat-grid-tile>
            </mat-grid-list>
            </div>

            <div style="height: 310px; overflow-y:auto;">
            <mat-grid-list [cols]="2" rowHeight="40px" [gutterSize]="'10px'">
                <ng-container *ngFor="let user of shownUsers">
                    <!--<mat-grid-tile
                    [colspan]="0.5"
                    [rowspan]="1"
                    [style.background]="'white'"
                    [style.color]="'black'"
                    >
                    <button (click)="SetInfo(user);" mat-icon-button aria-label="">
                        <mat-icon>create</mat-icon>
                      </button>
                        
                    </mat-grid-tile>-->
                    <div class="hoverClass">
                            <mat-grid-tile (click)="SetInfo(user);"
                            [matTooltip]="user.username"
                            [colspan]="1"
                            [rowspan]="1"
                            [style.background]="'white'"
                            [style.color]="'black'"
                            >
                                {{user.username.split("@")[0] + '@..'}}
                            </mat-grid-tile>
                            <mat-grid-tile (click)="SetInfo(user);" 
                                [matTooltip]="user.org[0].name"
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'white'"
                                [style.color]="'black'"
                            >
                                {{user.org[0].name}}
                            </mat-grid-tile>
                    </div>
    
                </ng-container>
            </mat-grid-list>
        </div>




    </div>
            
    <div style="flex-grow: 1; justify-content: center; text-align: center;">
        <div style="height: 370px; margin-bottom: 40px; margin-right: 40px; margin-left:40px; margin-top: -20px; padding: 10px; position: relative;">
            
            <div style="position: absolute; top: 20px; left: 0px; height: 100px; width: 100%; text-align: left;">
                
                <!-- US -->
                <mat-form-field appearance="outline" style="width: 60%; height: 55px;">
                    <mat-label>{{ 'usermanagement.email' | translate }}</mat-label>
                    <input disabled matInput placeholder="Username" [value]="activeUser">
                    <mat-icon matPrefix>email</mat-icon>
                </mat-form-field>

                <!-- US -->
                <mat-form-field appearance="outline" style="width: 60%; height: 55px;">
                    <mat-label>{{ 'usermanagement.fullname' | translate }}</mat-label>
                    <input disabled matInput placeholder="Username" [value]="activeFullName">
                    <mat-icon matPrefix>face</mat-icon>
                </mat-form-field>

                <!-- ORGANISATION -->
                <mat-form-field appearance="outline" style="width: 60%; height: 55px;">
                    <mat-label>{{ 'usermanagement.organisation' | translate }}</mat-label>
                    <mat-select (selectionChange)="selectBoxchanged($event);" [(ngModel)]="selectedOrganisation">
                      <mat-option value="{{organisationName}}" selected="true">{{organisationName}}</mat-option>
                      <ng-template #nestedOrgs let-sections let-index="index">
                        <ng-container *ngIf="sections">
                        <ng-container *ngFor="let orgs of sections.orgs">
                            <mat-option value="{{orgs.name}}">
                               <span *ngFor="let number of [].constructor(index)">-&nbsp;</span> {{orgs.name}}
                            </mat-option>
                            <ng-container *ngIf="orgs.orgs?.length > 0">
                                <ng-container *ngTemplateOutlet="nestedOrgs; context: { $implicit: orgs, index: index+1 }">
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        </ng-container>
                      </ng-template>
                      <ng-container *ngTemplateOutlet="nestedOrgs; context: { $implicit: mainOrganisation, index: 1 }"></ng-container>
                    <!--<mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>-->
                    </mat-select>
                  </mat-form-field>

                <!--
                <div style="position: absolute; right: 0; top: 0;">
                    <button mat-icon-button aria-label="">
                        <mat-icon style="font-size: 40px;">save</mat-icon>
                    </button>
                </div>
                -->

            </div>

            <div *ngIf="somethingChanged" style="position: absolute; right: 0px; bottom: 205px; width: 200px;">
                <button (click)="saveUserRoles();" style="width: 100%;" mat-stroked-button>{{ 'usermanagement.saveroles' | translate }}</button>
            </div>

            <!-- Assignable ROLES -->
            <div style="position: absolute; bottom: 170px; right: 0px;">{{ 'usermanagement.assignroles' | translate }}</div>
            <div style="border: 2px solid #007A7A; width: 40%; height: 150px; position: absolute; bottom:0; right: 0; overflow-y: auto; overflow-x: hidden;">
                <ng-container *ngFor="let role of assignableRoles; let i = index;">
                    <div (click)="userClickedRole(role, 'assignable', i)" style="width: 95%; height: 30px; padding: 5px;" class="roleHover">
                        {{role.rolename}}
                    </div>
                </ng-container>
            </div>
            
            <div style="position: absolute; bottom: 90px; left: 44%;">
                <button mat-icon-button aria-label="" >
                    <mat-icon style="font-size: 40px;">arrow_forward</mat-icon>
                </button>
            </div>
            
            <div style="position: absolute; bottom: 20px; left: 44%;">
                <button mat-icon-button aria-label="">
                    <mat-icon style="font-size: 40px;">arrow_back</mat-icon>
                </button>
            </div>

            <!-- ACTIVE ROLES -->
            <div style="position: absolute; bottom: 170px; left: 0px;">{{ 'usermanagement.activeroles' | translate }}</div>
            <div style="border: 2px solid #007A7A; width: 40%; height: 150px; position: absolute; bottom: 0; left: 0; overflow-y: auto; overflow-x: hidden;">
                <ng-container *ngFor="let role of activeRoles; let i = index;">
                    <div (click)="userClickedRole(role, 'active', i)" style="width: 95%; height: 30px; padding: 5px;" class="roleHover">
                        {{role.rolename}}
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>