<mat-toolbar>
  <!-- " Groter dan mobiel/tablet modus " -->
  <mat-toolbar-row [style.display]="viewportService.innerWidth > 959 ? null : 'none'">


  <h1 class="organization" style="color:#007a7a"> {{ appService.organisatieTitle }}</h1>


        <!-- PROGRESS TODO -->
       <!-- <div style="position:absolute; top: 12px; left: 80px;">
        <mat-progress-spinner *ngIf="loadSpinning" style="height: 20px; width: 20px;"
          mode="indeterminate"
          [value]="10"
          diameter="40"
          >
        </mat-progress-spinner>
      </div> -->


      <div class="menu-spacer"></div>
      <div style="float: right;">

          <ng-container *ngIf="menuObject">



              <ng-container *ngFor="let item of menuObject">
                <!-- Handle branch node buttons here -->
                <ng-container *ngIf="item?.rights?.view">

                <ng-container *ngIf="item.children && item.children.length > 0">
                  <button mat-button [matMenuTriggerFor]="menu.childMenu">
                    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                    {{item.name }}
                    <mat-icon style="margin-bottom: 3px;" matSuffix>arrow_drop_down</mat-icon>
                  </button>
                  <app-menu-item #menu [menuObject]="item.children"></app-menu-item>
                </ng-container>
                <!-- Leaf node buttons here -->
                <ng-container *ngIf="!item.children || item.children.length === 0">
                  <button (click)="onItemClick(item)" mat-button>
                    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                    {{item.name }}
                  </button>
                </ng-container>
              </ng-container>
              </ng-container>
          </ng-container>

          <app-menu-settings [mobile]="false"></app-menu-settings>

      </div>
    </mat-toolbar-row>



  <!-- " mobiel/tablet modus " -->
  <mat-toolbar-row [style.display]="viewportService.innerWidth > 960 ? 'none' : null">
      <button style="margin-top: 7px;" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
      </button>

      <!-- PROGRESS TODO
      <mat-progress-spinner *ngIf="loadSpinning" style="height: 20px; width: 20px; margin-left: 20px;"
      mode="indeterminate"
      [value]="10"
      diameter="40"
      >
      </mat-progress-spinner> -->



      <mat-menu #menu="matMenu">

        <ng-container *ngIf="menuObject">
            <ng-container *ngIf="menuObject">
                <ng-container *ngFor="let item of menuObject">
                  <!-- Handle branch node buttons here -->
                  <ng-container *ngIf="item?.rights?.view">
                    <ng-container *ngIf="item.children && item.children.length > 0">
                      <ng-container *ngIf="item?.rights?.view">
                        <button mat-menu-item [matMenuTriggerFor]="menu.childMenu">
                          <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                          {{item.name }}
                        </button>
                        <app-menu-item #menu [menuObject]="item.children"></app-menu-item>
                      </ng-container>
                    </ng-container>
                    <!-- Leaf node buttons here -->
                    <ng-container *ngIf="!item.children || item.children.length === 0">
                      <button (click)="onItemClick(item)" mat-menu-item>
                        <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                        {{item.name }}
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>
            </ng-container>

            <app-menu-settings [mobile]="true"></app-menu-settings>
            </ng-container>

      </mat-menu>

      <div style="max-width: 300px !important; position: absolute; top: -15px; left: 70px; padding: 0 !important; margin: 0 !important;">
        <app-merkator-googlelocator>

        </app-merkator-googlelocator>
      </div>
      <!--<div style="position: absolute; right: 4px; top: 10px;">
        <h1>{{ title | translate}}</h1>
      </div>-->

  </mat-toolbar-row>



</mat-toolbar>
<app-feature-exporter *ngIf="exportOpen"></app-feature-exporter>
<!--
<mat-progress-bar mode="indeterminate" style="position:absolute; top: 64px; z-index: 10000;"></mat-progress-bar>
-->
