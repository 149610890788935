import * as snackBarActions from '../../actions/snackbar-actions/snackbar.actions';
import * as fromRoot from '../../state-interface/app.state';

export interface SnackState extends fromRoot.AppState {
  show: boolean;
}

const initialState: SnackState = {
  show: false
};

export function snackbar_reducer(state: SnackState = initialState, action: snackBarActions.SnackbarAction): fromRoot.AppState {
  switch (action.type) {
    case snackBarActions.SnackbarTypes.SNACKBAR_CLOSE:
      return { ...state, show: false };
    case snackBarActions.SnackbarTypes.SNACKBAR_CLOSE:
      return { ...state, show: true };
    default:
      return state;
  }
}
