import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { AppState } from '../../../state-interface/app.state';
import * as MerkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import * as modalActions from '../../../actions/matmodal-actions/matmodal.actions';
import * as buttonActions from '../../../actions/button-actions/button.actions';
import { MerkatorMapInteractionTypes } from '@merkator/merkator-map';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';

@Component({
  selector: 'app-nmp-export-mailing',
  templateUrl: './nmp-export-mailing.component.html',
  styleUrls: ['./nmp-export-mailing.component.scss']
})
export class NmpExportMailingComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private http: HttpClient, private httpserv: HttpService,
              private store: Store<AppState>, private mapService: MerkatorMapService,
              public change: ChangeDetectorRef) { }

  @Input() featureid: number;

  @Input() featurename: string;

  /**
   * FormGroup Object
   */
  nmpExportForm: UntypedFormGroup;

  gemeentenArray: Array<any> = [];

  gekozenGemeente: number[] = [];

  required: boolean = false;

  typeArray = [{name: 'eigenaar', checked: true}, {name: 'huurder', checked: false}, {name: 'andere', checked: false}];
  selectedCheckbox = ['eigenaar'];

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  /**
   * Initializeer formGroup voor het exporteren van eigenaarsgegevens leidingen
   */
  initializeFormGroup(): void {
    this.mapService.currentBuffer = 1;
    this.mapService.currentBuffer = 1;
    const fg = this.fb.group({});
    fg.addControl('buffer', new UntypedFormControl({value: 1, disabled: false }, Validators.required ));
    fg.addControl('methode', new UntypedFormControl({value: '2', disabled: false }, Validators.required ));
    fg.addControl('gemeentenaam', new UntypedFormControl({value: 0, disabled: false }, Validators.required ));
    fg.addControl('type', new UntypedFormControl({value: 0, disabled: false }, Validators.required ));
    fg.addControl('afbakening', new UntypedFormControl({value: 0, disabled: false }, Validators.required ));
    fg.controls.type.valueChanges.subscribe( value => {
      this.selectedCheckbox = value;
      this.mapService.typeSelected = value;
    });
    fg.controls.methode.valueChanges.subscribe(value => {
      if (value === '1') {
        // Haal gemeenten op die relevant zijn voor de geselecteerde leiding
        this.getGemeenten();
      } else if (value === '2') {
        // Niets, indien op volgende geklikt word krijg je het afsnijden gedeelte
      } else if ( value === '3') {
      }
    });
    fg.controls.buffer.valueChanges.subscribe( newBuffer => {
      this.mapService.currentBuffer = newBuffer;
      if (newBuffer === null) {
        fg.controls.buffer.setValue(1, {emitEvent: false});
        this.mapService.currentBuffer = 1;
      }
      // console.log(this.mapService.currentBuffer);
    });
    fg.controls.gemeentenaam.valueChanges.subscribe( gemeente => {
      this.mapService.gekozengemeeente = gemeente;
      this.gekozenGemeente = gemeente;
      this.required = false;
      this.change.detectChanges();
    });
    this.nmpExportForm = fg;
    // console.log(this.nmpExportForm);
  }

  /**
   * Haalt gemeenten op die relevant zijn
   */
  getGemeenten(): void {
    if (this.gemeentenArray.length === 0) {
      this.httpserv.get(this.httpserv.apiUrl + 'pipelink/getgemeenten?id=' + this.featureid).subscribe(result => {
        // object met name + id
        this.gemeentenArray = result;
      });
    }
  }

  /**
   * Wanneer op volgende word geklikt
   */
  finishProces(): void {
    if ( this.nmpExportForm.controls.methode.value === '1' ) {
      // Exporteer naar Excel
      if (this.gekozenGemeente.length > 0) {
        this.mapService.exportMailingToExcel('gemeente');
      } else {
        this.required = true;
      }
    } else if ( this.nmpExportForm.controls.methode.value === '2' ) {
      // start het afsnijden
      this.store.dispatch(new MerkatorMapActions.TalkToMapReference({ type: MerkatorMapInteractionTypes.SelectPart}));
    } else if ( this.nmpExportForm.controls.methode.value === '3' ) {
      // Pak de hele leiding
      this.mapService.exportMailingToExcel('wholepipe');
    }
  }

  /**
   * Close component
   */
  cancel(): void {
    // Sluit component
    this.store.dispatch(new MerkatorMapActions.TalkToMapReference({ type: MerkatorMapInteractionTypes.Off}));
    this.store.dispatch(new buttonActions.ClearActiveButton());
    this.store.dispatch(new modalActions.MatModalClose());
  }



}
