<mat-card class="exportMenu">
  <mat-card-title class="theme-text-color">
    &nbsp; &nbsp; &nbsp;&nbsp;<h1>{{ 'tooltip.ExportToFile.Header' | translate :{Default: 'Header'} }}</h1>
  </mat-card-title>
  <mat-card-content><br/>


    <mat-form-field style="width: 100%">

      <mat-label>{{ 'tooltip.ExportToFile.Message' | translate :{Default: 'Alles'} }}</mat-label>
      <mat-select (selectionChange)="setFeature($event.value)">
        <ng-container *ngFor="let typesOfFeatures  of typesOfFeatures">
          <mat-option [value]="typesOfFeatures.featuretype">
            {{typesOfFeatures.displayname}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-chip-listbox *ngIf="selectedFeatureType.featuretype.length!==0" [formControl]="fc_chips" class="chipBox"
                      selectable>

      <mat-chip-option #chip1 (click)="toggleSelection('Alles')"
                      > {{ 'tooltip.ExportToFile.Everything' | translate :{Default: 'Alles'} }}</mat-chip-option>
      <mat-chip-option #chip2 (click)="toggleSelection('Gemeente')"
                      >{{ 'tooltip.ExportToFile.Municipality' | translate :{Default: 'Gemeente'} }}</mat-chip-option>
      <mat-chip-option #chip3 (click)="toggleSelection('Poly')"
                      >{{ 'tooltip.ExportToFile.Poly' | translate :{Default: 'Draw a polygon'} }}</mat-chip-option>
    </mat-chip-listbox>

    <mat-form-field *ngIf="selectedChip==='Gemeente'" style="width: 100%">
      <br/>
      <mat-label>{{ 'nmp.kiesgemeente' | translate :{Default: 'Kies een gemeente'} }}</mat-label>
      <mat-select (selectionChange)="getGeom($event.value)" >
        <mat-option *ngFor="let option of gemeentenArray" [value]="option.id">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="featuresForExport.length!==0" style="width: 100%">
      <br/>
      <input (change)="filterFeatures()" [(ngModel)]="searchText" matInput type="text">
      <mat-label>{{ 'tooltip.ExportToFile.selectFeature' | translate :{Default: 'Exporteer enkel een onderdeel van data'} }}</mat-label>
      <mat-select (selectionChange)="selectFeature($event.value)" >
        <mat-option *ngFor="let option of featuresForExportFiltered" [value]="option.getId()">
          {{option.getId() + ' ' + option.get('name')}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions class="saveButtons">
    <button (click)="ExportFeatures('SHP','')" mat-raised-button>
      <mat-icon>share</mat-icon>
      {{ 'tooltip.ExportToFile.ShpButton' | translate :{Default: 'ShpButton'} }}</button>
    <button (click)="ExportFeatures('KML','')" mat-raised-button>
      <mat-icon>file_copy</mat-icon>
      {{ 'tooltip.ExportToFile.KMLButton' | translate :{Default: 'KMLButton'} }}</button>
    <button (click)="ExportFeatures('DXF', 'Leidingen')" mat-raised-button>
      <mat-icon>download</mat-icon>
      {{ 'tooltip.ExportToFile.DXFButton' | translate :{Default: 'DXFButton'} }}</button>
  </mat-card-actions>

</mat-card>
