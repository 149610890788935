<div style="width: 400px; background-color: white; padding: 10px;">

    {{featurename}}

    <br>
    <br>
    <!--<h3 style="padding-top: 15px;">{{ 'nmp.title' | translate :{ Default: 'Export contactdetails parcels'} }}</h3>-->

    <form [formGroup]="nmpExportForm">

        <!-- Buffer in M -->
        <mat-form-field style="font-size: 12px;" appearance="outline">
        <mat-label>
          {{ 'nmp.buffer' | translate :{ Default: 'Buffer in Meters'} }}
        </mat-label>
        <input min="1" matInput type="number" formControlName="buffer">
        </mat-form-field>
        <br>
        <!-- Radiobox -->
        <mat-radio-group aria-label="Kies uw methode" formControlName="methode">
            <mat-radio-button value="1">{{ 'nmp.gemeente' | translate :{ Default: 'Gemeente'} }}</mat-radio-button>
            <br>
            <br>
            <mat-form-field style="font-size: 12px;" appearance="outline" *ngIf="nmpExportForm.controls.methode.value === '1'">
                <mat-label>{{ 'nmp.kiesgemeente' | translate :{ Default: 'Kies een gemeente'} }}</mat-label>
                <mat-select formControlName="gemeentenaam" multiple>
                  <ng-container *ngFor="let option of gemeentenArray">
                    <mat-option [value]="option.id">
                      {{option.name}}
                    </mat-option>
                  </ng-container>
                </mat-select>
            </mat-form-field>
            <br>
            <span *ngIf="required" style="font-size: 12px; color: red;">
              {{ 'nmp.required' | translate :{ Default: 'Dit veld is verplicht'} }}
              <br>
              <br>
            </span>
            <mat-radio-button value="2">{{ 'nmp.afbakening' | translate :{ Default: 'Afbakening dmv Polygoon'} }}</mat-radio-button>
            <br><br>
            <mat-radio-button value="3">{{ 'nmp.heleleiding' | translate :{ Default: 'Gehele leiding'} }}</mat-radio-button>
        </mat-radio-group>
        <br><br>
        <mat-radio-group aria-label="Type">

          <mat-form-field style="font-size: 12px;" appearance="outline">
              <mat-label>{{ 'nmp.type' | translate :{ Default: 'Kies type'} }}</mat-label>
              <mat-select formControlName="type" multiple  [ngModel]="selectedCheckbox"
              (ngModelChange)="selectedCheckbox" >
                <ng-container *ngFor="let option of typeArray">
                  <mat-option [value]="option.name">
                    {{ 'nmp.'+option.name | translate :{ Default: 'Optie'} }}   {{option.name}}
                  </mat-option>
                </ng-container>
              </mat-select>
          </mat-form-field>
        </mat-radio-group>

    </form>
    <div style="padding: 30px; float: right;">

            <button (click)="finishProces();" mat-stroked-button>{{ 'nmp.volgende' | translate :{ Default: 'Volgende'} }}</button>

            <button (click)="cancel();" style="margin-left: 10px;" mat-stroked-button>{{ 'nmp.annuleren' | translate :{ Default: 'Annuleren'} }}</button>

    </div>
</div>
