import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/app-utililty-services/http.service';

@Component({
  selector: 'app-patch-notes',
  templateUrl: './patch-notes.component.html',
  styleUrls: ['./patch-notes.component.scss']
})
export class PatchNotesComponent implements OnInit {

  patchNotes: Array<IPatchNote>;

  constructor(public http: HttpService) { }

  ngOnInit(): void {
    this.getPatchNotes();
  }

  getPatchNotes(): void {
    this.http.get( this.http.apiUrl + 'ApplicatieConfiguratie/getPatchNotes').subscribe(result => {
      this.patchNotes = result;
    });
  }

}

export interface IPatchNote {
  id: number;
  version: number;
  text: string;
}
