<div style="padding-top: 20px; padding: 10px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="mat-card-title" style="font-size: 24px; font-family: 'Lucida Sans','Lucida Sans Regular','Lucida Grande','Lucida Sans Unicode',Geneva,Verdana,sans-serif;"> {{ 'featureidentifier.title' | translate }}</mat-card-title>
        </mat-card-header>
        <ng-container *ngFor="let layer of layerArray; let i = index;">
            <app-merkator-feature-container [hasOneLayer]="oneLayer" [featureArray]="featureArray[i]" [layerName]="layerArray[i]"></app-merkator-feature-container>
            <hr *ngIf="i+1 !== layerArray.length" class="merkator-featurelist-hr">
        </ng-container>
    </mat-card>
</div>
