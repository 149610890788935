import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import Feature from 'ol/Feature';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, OnDestroy {

  @Input() imageInput;

  @Input() config: {imageprefix: string, imageproperty: string};

  features = [];
  currentFeature;
  index = 0;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    // Get features by property
    this.features = this.imageInput.map(feature => feature.values_);
    this.features.map(feature => this.createFeatureUrl(feature));
    this.currentFeature = this.features[this.index];
    this.highlightFeature(this.imageInput[this.index]);
  }

  ngOnDestroy(): void {
    this.stopHighlightFeature(this.index);
  }

  parseBackToFeature(feature: any): Feature {
    feature.__proto__ = Feature.prototype;
    return feature;
  }

  goFullscreen(url: string): void {
    window.open(url, '_blank');
  }

  goToIndex(i: number): void {
    this.stopHighlightFeature(this.index);
    this.index = i;
    this.currentFeature = this.features[this.index];
    this.highlightFeature(this.imageInput[this.index]);
  }

  changeIndex(direction: string): void {
    if (direction === 'up') {
      this.index++;
      if (this.index > this.features.length - 1) {
        this.index = 0;
      }
    } else {
      this.index--;
      if (this.index < 0) {
        this.index = this.features.length - 1;
      }
    }
  }

  obsKeysToString(o: any, k: any, sep: any): any {
    return k.map(key => o[key]).filter(v => v).join(sep);
  }

  createTooltip(currentFeature: any): string {
    return this.obsKeysToString(currentFeature, Object.keys(currentFeature), ' \n ');
    // return 'HI';
  }

  goLeft(): void {
    this.stopHighlightFeature(this.index);
    this.changeIndex('');
    this.currentFeature = this.features[this.index];
    this.highlightFeature(this.imageInput[this.index]);
  }

  goRight(): void {
    this.stopHighlightFeature(this.index);
    this.changeIndex('up');
    this.currentFeature = this.features[this.index];
    this.highlightFeature(this.imageInput[this.index]);
  }

  createFeatureUrl(feature: any): any {
    const imageurl = feature['"' + this.config.imageproperty + '"'];
    const source = this.config.imageprefix + imageurl;
    feature.viewer_fullimageurl = source;
    return feature;
  }

  highlightFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'highlight', feature}));
    // this.store.dispatch(new merkatorMapActions.TalkToMapReference( { type: 'measure', geometry: 'linestring'} ));
  }

  stopHighlightFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'clearhighlight', feature}));
  }

}
