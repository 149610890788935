import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy, OnChanges, AfterContentChecked, AfterViewInit, HostListener } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import * as MatBottomActions from '../../../actions/bottomsheet-actions/bottomsheet.actions';
import { AppState } from '../../../state-interface/app.state';
import { Store, select } from '@ngrx/store';
import * as GridActions from '../../../actions/grid-actions/merkator-grid.actions';
import * as GridState from '../../../reducers/merkator-grid-reducer/merkator-grid.reducer';
import { Subscription } from 'rxjs';
import { MerkatorGridService } from '../../../services/app-grid-services/merkator-grid.service';
import { ComponentmanagerService } from '../../../services/app-state-services/componentmanager.service';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { ResizeEvent } from 'angular-resizable-element';

@Component({
  selector: 'app-matbottomsheet',
  templateUrl: './matbottomsheet.component.html',
  styleUrls: ['./matbottomsheet.component.scss']
})
export class MatbottomsheetComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public compManager: ComponentmanagerService, public refresh: ChangeDetectorRef,
              public grids: MerkatorGridService, public store: Store<AppState>, private button: ButtonmethodService) { }

  oldHeight: any;
  screenstatus: string = 'fullscreen';
  $subscription: Subscription;
  mouseYPositionStart: number;

  ngOnInit(): void {
    this.$subscription = this.store.pipe(select(GridState.getGridHeight)).subscribe(result => {
      if (document.getElementById('mybottomsheetheighthandler')) {
          this.grids.height = result.gridHeight.gridHeight.height;
          document.getElementById('mybottomsheetheighthandler').style.height = result.gridHeight.gridHeight.height + 'px';
          if (result.gridHeight.gridHeight.sideEffect === 'refresh') {
            this.refreshSheet();
          } else if (result.gridHeight.gridHeight.sideEffect === 'fullscreen') {
            this.oldHeight = result.gridHeight.gridHeight.oldHeight;
            if (this.oldHeight !== result.gridHeight.gridHeight.height) {
                this.screenstatus = 'fullscreen_exit';
                if ( this.oldHeight === undefined) {
                  this.screenstatus = 'fullscreen';
                }
            }
            this.refreshSheet();
          }
          setTimeout(() => {
            this.refreshSheet();
          }, 500);
      }
      this.refreshSheet();
    });
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  onClose(): void {
    this.store.dispatch(new MatBottomActions.BottomSheetClose());
    this.compManager.bottomSheetComponent = null;
    this.compManager.bottomSheetParams = null;
  }

  updateButtons($event: any, test: any): void {
  }

  goFullScreen(): void {
    if (this.screenstatus === 'fullscreen') {
      this.screenstatus = 'fullscreen_exit';
      this.oldHeight = document.getElementById('mybottomsheetheighthandler').style.height;
      this.store.dispatch(new GridActions.SetGridHeight(
        new GridActions.GridHeight((window.innerHeight - (64 + 28)), 'fullscreen', (this.oldHeight.split('px')[0] as any ))));
    } else {
      this.screenstatus = 'fullscreen';
      this.store.dispatch(new GridActions.SetGridHeight(new GridActions.GridHeight((this.oldHeight.split('px')[0] as any ), 'fullscreen')));
    }
  }

  onResizeStart(event: ResizeEvent): void {
    // console.log('resizeStart');
    this.mouseYPositionStart = event.rectangle.top;
  }


  onResizeEnd(event: ResizeEvent): void {
    // console.log('resizeEnd');
    // console.log(this.mouseYPositionStart, ' ---> ' , event.rectangle.top);
    if ( this.mouseYPositionStart < event.rectangle.top ) {
      // console.log(' Naar beneden ');
      // Small extra nr to go down
      event.rectangle.top = event.rectangle.top + 75;
    } else {
      // console.log(' Naar boven ');
    }
    this.grids.previousSize = (window.innerHeight - event.rectangle.top) + 'px';
    this.store.dispatch(new GridActions.SetGridHeight( new GridActions.GridHeight((window.innerHeight - event.rectangle.top), 'refresh')));
  }

  refreshSheet(): void {
    this.refresh.detectChanges();
    window.dispatchEvent(new Event('resize'));
  }



}
