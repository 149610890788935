import { Action } from '@ngrx/store';

export class GridHeight {
    height: number;
    oldHeight: number;
    sideEffect: string;
    constructor(height: number, sideEffect: string, oldheight?: number) {
        this.height = height;
        this.sideEffect = sideEffect;
        this.oldHeight = oldheight;
    }
}

// Enums
export enum ActionTypes {
    GET_GRID = '[GRID] Get Grid',
    LOAD_GRID_FULL = '[GRID] Get Grid FULL',
    LOAD_GRID_FULL_SUCCESS = '[GRID] Get Grid FULL success',
    GET_COLUMNDEFINITIONS = '[GRID] Get ColumnDefinitions',
    GET_GRIDOPTIONS = '[GRID] Get GridOptions',
    GET_DATASET = '[GRID] Get Dataset',
    RESET_GRID = '[GRID] Reset grid',
    SET_GRID_HEIGHT = '[GRID] set grid height'
}

// Actions
export class GetGrid implements Action {
    readonly type = ActionTypes.GET_GRID;
}

export class ResetGrid implements Action {
    readonly type = ActionTypes.RESET_GRID;
}

export class LoadGridFull implements Action {
    readonly type = ActionTypes.LOAD_GRID_FULL;
    public constructor(public payload: number) { }
}
export class SuccesLoadGridFull implements Action {
    readonly type = ActionTypes.LOAD_GRID_FULL_SUCCESS;
    public constructor(public payload: any) { }
}
export class SetGridHeight implements Action {
    readonly type = ActionTypes.SET_GRID_HEIGHT;
    public constructor(public payload: GridHeight) { }
}

// export class GetColumnDefitions implements Action {
//     readonly type = ActionTypes.GET_COLUMNDEFINITIONS;
// }

// export class GetGridOptions implements Action {
//     readonly type = ActionTypes.GET_GRIDOPTIONS;
// }

// export class GetDataset implements Action {
//     readonly type = ActionTypes.GET_DATASET;
// }

// Export As 1 ActionType
export type ActionType = GetGrid | LoadGridFull | SuccesLoadGridFull | ResetGrid
                             | SetGridHeight; // | GetColumnDefitions | GetGridOptions | GetDataset;
