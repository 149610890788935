import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import * as GridActions from '../actions/grid-actions/merkator-grid.actions';
import { MerkatorGridService } from '../services/app-grid-services/merkator-grid.service';

@Injectable()
export class MerkatorGridEffects {
    constructor(private action$: Actions, public gridService: MerkatorGridService) { }

    loadGridConfig$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(GridActions.ActionTypes.LOAD_GRID_FULL),
        mergeMap(() => this.gridService.getGridConfig(this.gridService.idGrid)
                            .pipe(
                                map(gridObject => (new GridActions.SuccesLoadGridFull(gridObject))),
                                catchError(() => {
                                    console.log('Error');
                                    return EMPTY;
                                })
                            )
        ))
    );

/*
    loadMenuEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
            ofType(GridActions.ActionTypes.LOAD_GRID_FULL_SUCCESS),
            tap(() => {
                console.log('tap effect');
                this.refresh.detectChanges();
                window.dispatchEvent(new Event('resize'));
            })
        ),
        { dispatch: false }
    );
*/
}
