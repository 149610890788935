import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApplicationService } from '../../../services/app-state-services/application.service';
import { HttpService } from '../../../services/app-utililty-services/http.service';

@Component({
  selector: 'app-auto-component',
  templateUrl: './auto-login.component.html'
})
export class AutoLoginComponent implements OnInit, OnDestroy {
  lang: any;
  constructor(public oidcSecurityService: OidcSecurityService,
              public http: HttpService,
              public appService: ApplicationService
  ) {
    this.oidcSecurityService.authorize();
  }

  ngOnInit(): void  {
  }

  ngOnDestroy(): void {
  }

  private onModuleSetup(): void {
    this.oidcSecurityService.authorize();
  }

}
