import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, Type, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as ThemeState from '../../../reducers/theme-reducer/theme.reducer';
import * as MenuActions from '../../../actions/menu-actions/menu.actions';
import * as ButtonActions from '../../../actions/button-actions/button.actions';
import * as snackbarActions from '../../../actions/snackbar-actions/snackbar.actions';
import * as bottomSheetActions from '../../../actions/bottomsheet-actions/bottomsheet.actions';
import * as matModalActions from '../../../actions/matmodal-actions/matmodal.actions';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import * as ButtonState from '../../../reducers/button-reducer/button.reducer';
import { Subscription, combineLatest } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { CustomDynamicComponent } from '../../../classes-types/dynamic-comp.types';
import * as AppConfigActions from '../../../actions/appconfig-actions/appconfig.actions';
import * as LanguageActions from '../../../actions/translate-actions/translate.actions';
import * as AppConfigState from '../../../reducers/appconfig-reducer/appconfig.reducer';
import { AppConfig } from '../../../classes-types/appconfig';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApplicationService } from '../../../services/app-state-services/application.service';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { ComponentmanagerService, Components } from '../../../services/app-state-services/componentmanager.service';
import { ViewportService } from '../../../services/app-utililty-services/viewport.service';

@Component({
  selector: 'app-appmain',
  templateUrl: './appmain.component.html',
  styleUrls: ['./appmain.component.scss']
})
export class AppmainComponent implements OnInit, OnDestroy {

  constructor(private translate: TranslateService, private store: Store<AppState>,
              private OverlayContainer: OverlayContainer, public appService: ApplicationService,
              public compManager: ComponentmanagerService, public dialog: MatDialog, public oidcSecurityService: OidcSecurityService,
              public buttonService: ButtonmethodService, public http: HttpService, public mapService: MerkatorMapService, public viewportService: ViewportService) {
              // translate.setDefaultLang('nl');
  }

  @ViewChild('leftpanel', { static: true }) public sidenavleft: MatSidenav;
  @ViewChild('rightpanel', { static: true }) public sidenavright: MatSidenav;

  today: string;
  leftButtons: any;
  rightButtons: any;
  bottomButtons: any;
  combinedButtons: any = [];
  SubscriptionArray: Subscription[] = [];
  theme: string;
  backdrop: boolean = false;
  wantedMethod: string = '';
  tokenReceived: boolean = false;

  ngOnInit(): void {
    // Moet want weWaitForTheToken emit niets bij een refresh omdat er een token is
    this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated, allConfigsAuthenticated }) => {
      if (isAuthenticated === true) {
        this.oidcSecurityService.getAccessToken().subscribe(res => {
          this.http.id_token = res;
        })
        this.getCurrentUser();
        this.getOrganisations();
      }
    });
    // Manual hardtrigger dat token er is, dan pas laden
    this.appService.weWaitForTheToken.subscribe( authorized => {
      if (authorized === 'tokenreceived') {
        this.oidcSecurityService.getAccessToken().subscribe(res => {
          this.http.id_token = res;
        })
        this.getCurrentUser();
        this.getOrganisations();
      }
    });

    this.SubscriptionArray.push(
      this.store.pipe(select(AppConfigState.getApp)).subscribe((appconfig: Array<AppConfig>) => {
        if (appconfig.length > 1) {
          const result = this.decideDefaultAppConfig(appconfig) as AppConfig;
          this.appService.CurrentActiveIdApplicatie = result.id;
          this.store.dispatch(new AppConfigActions.SetDefaultAppConfig(result));
        } else if (appconfig.length === 1) {
          this.appService.CurrentActiveIdApplicatie = appconfig[0].id;
          this.store.dispatch(new AppConfigActions.SetDefaultAppConfig(appconfig[0]));
        }
      }),
      this.store.pipe(select(AppConfigState.getActiveApp)).subscribe((appconfig: AppConfig) => {
        if (appconfig !== null) {
          this.appService.hasAppAdmin();
          this.store.dispatch(new MenuActions.LoadMenu());
          this.store.dispatch(new ButtonActions.LoadTopButtons());
          this.store.dispatch(new ButtonActions.LoadLeftButtons());
          this.store.dispatch(new ButtonActions.LoadRightButtons());
          this.store.dispatch(new merkatorMapActions.LoadMerkatorMapConfiguraties());
        }
      }),
      this.store.pipe(select(ButtonState.getLeftButtons)).subscribe((button: ButtonState.ButtonState) => {
        this.leftButtons = button.leftButtons;
      }),
      this.store.pipe(select(ButtonState.getRightButtons)).subscribe((button: ButtonState.ButtonState) => {
        this.rightButtons = button.rightButtons;
      }),
      combineLatest(
        this.store.select(ButtonState.getLeftButtons),
        this.store.select(ButtonState.getRightButtons),
        this.store.select(ButtonState.getTopButtons)
      ).subscribe(button => {
        if (button[0].leftLoaded && button[1].rightLoaded && button[2].topLoaded) {
          this.combinedButtons = [];
          this.combinedButtons = this.combinedButtons.concat(button[0].leftButtons, button[1].rightButtons,
            button[2].topButtons);
          }
      }),
      this.store.pipe(select(ButtonState.getActiveButton)).subscribe(value => {
        if (value.activeButton !== null) {
          this.buttonService.activeButton = value.activeButton;
        } else if ( value.activeButton === null) {
          this.buttonService.activeButton = null;
        }
      })
    );
    this.appService.leftSideNavRef = this.sidenavleft;
    this.appService.rightSideNavRef = this.sidenavright;
  }

  getCurrentUser(): void {
    if (sessionStorage.getItem('ue') == null && sessionStorage.getItem('fn') == null) {
      this.http.get(this.http.apiUrl + 'ApplicatieConfiguratie/getcurrentuser').subscribe(result => {
        sessionStorage.setItem('ue', result.email);
        sessionStorage.setItem('fn', result.full_name);
      });
    }
  }

  getOrganisations(): void {
    if (sessionStorage.getItem('io') == null) {
      this.http.get(this.http.apiUrl + 'ApplicatieConfiguratie/GetOrganisations').subscribe(result => {
        this.appService.organisations = result;
        if (Object.keys(result).length > 1) {
          sessionStorage.setItem('ao', JSON.stringify(result));
          this.store.dispatch(new matModalActions.MatModalOpen(
            { title: 'Kies uw organisatie', component: Components.CHOOSEORGANISATION, params: {} }));
        } else {
          this.http.id_organisation = Object.keys(result)[0];
          sessionStorage.setItem('io', this.http.id_organisation);
          this.appService.organisatieTitle = Object.values(result)[0].toString();
          this.setDefaultLanguage();
          this.goLoadAppConfig();
        }
      });
    } else {
      this.http.get(this.http.apiUrl + 'ApplicatieConfiguratie/GetOrganisations').subscribe(result => {
        if (Object.keys(result).length > 0) {
          sessionStorage.setItem('ao', JSON.stringify(result));
          const io = sessionStorage.getItem('io');
          this.appService.organisatieTitle = JSON.parse(sessionStorage.getItem('ao'))[io];
        }
      });
      this.http.id_organisation = sessionStorage.getItem('io');
      this.setDefaultLanguage();
      this.goLoadAppConfig();
    }
  }

  ngOnDestroy(): void {
    this.SubscriptionArray.every((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
  goLoadAppConfig(): void {
    this.store.dispatch(new AppConfigActions.LoadAppConfig());
  }

  setDefaultLanguage(): void {
    this.http.getText(this.http.apiUrl + 'MerkatorLanguage/GetLanguage')
    .subscribe((result: string) => {
          this.translate.setDefaultLang(result);
          this.mapService.language = result.toUpperCase();
          this.store.dispatch(new LanguageActions.ChangeTranslateAction(result));
      });
  }

  decideDefaultAppConfig(AppConfigArray: Array<AppConfig>): AppConfig {
    let result = null;
    AppConfigArray.some((AppConfig: AppConfig) => {
      if (AppConfig.default_active === true) {
        result = AppConfig;
      }
    });
    return result;
  }

  /** Yikes Yikes Yikes  */
  onResizeEnd(event: any): void {
    if (event.edges.right) {
      document.getElementById('leftsidenavwidth').style.width = event.rectangle.width + 'px';
      this.buttonService.leftSideNavEvent.emit('refresh');
    } else {
      if (event.edges.right === 0) {
      } else {
        document.getElementById('rightsidenavwidth').style.width = event.rectangle.width + 'px';
        this.buttonService.rightSideNavEvent.emit('refresh');
      }
    }
  }

  closeRightSidePanel(ref: any): void {
    const str = this.getRightSideComponent();
    if (str === 'GkalFormImplComponent') {
      this.store.dispatch(new matModalActions.MatModalOpen({ title: 'Let op!',
      component: Components.HTMLINPUTCOMPONENT, params: {htmlinput: `<div>Dit verwijdert de tekening.</div><br>`, flow: 'gkalgraaf'} } ));
    } else {
      this.compManager.rightSideComponent = null;
      this.compManager.rightSideParams = null;
      ref.close();
    }
    // ref.close();
  }

  getRightSideComponent(): string {
    let comp = this.compManager.rightSideComponent.toString();
    comp = comp.split('(')[0];
    comp = comp.replace('function ', '');
    return comp;
  }

  getLeftSideComponent(): string {
    let comp = this.compManager.leftSideComponent.toString();
    comp = comp.split('(')[0];
    comp = comp.replace('function ', '');
    return comp;
  }

  customLeftPanelClose(ref: any): void {
    const str = this.getLeftSideComponent();
    /*if (string === 'MerkatorMapprintComponent')  */
    this.compManager.leftSideComponent = null;
    this.compManager.leftSideParams = null;
    this.mapService.mapRef.removeAllFeaturesFromLayer('boxeventdrawlayer');
    if (str === 'ImageCarouselComponent') {
      this.mapService.drawRef.verwijderAlleGetekende('DrawSelectLaag');
      this.mapService.mapRef.removeAllFeaturesFromLayer('DrawSelectLaag'); // .('DrawSelectLaag');
    }
    ref.close();
    }

}
