import { MainMenu } from './main.menu';

export class ViewerButton {
    public id: number;
    public id_menu: number;
    public name: string;
    public translate_property: string;
    public tooltop: string;
    public component_name: string;
    public deleted: boolean;
    public id_applicatie: number;
    public children: Array<MainMenu>;
    public icon: string;
    public rights: string;
    public payload: string;
    public id_container_name: string;
    public tooltip: string;
}
