import * as AppConfigActions from './../../actions/appconfig-actions/appconfig.actions';
import * as fromRoot from '../../state-interface/app.state';
import * as AppConfigClasses from '../../classes-types/appconfig';
import { createSelector, createFeatureSelector } from '@ngrx/store';


export interface AppConfigState extends fromRoot.AppState {
    AppConfiguratie: Array<AppConfigClasses.AppConfig>;
    ActiveConfiguratie: AppConfigClasses.AppConfig;
}

export interface AppLoadingState extends fromRoot.AppState {
    appLoading: boolean;
}

const initialState: AppConfigState = {
    AppConfiguratie: [],
    ActiveConfiguratie: null
};

const initialAppLoadingState: AppLoadingState = {
    appLoading : null
};

const getAppState = createFeatureSelector<AppConfigState>('app');
const getAppLoadingState = createFeatureSelector<AppLoadingState>('apploading');

export const getApp = createSelector(
    getAppState,
    state => {
        return state.AppConfiguratie;
    }
);

export const getActiveApp = createSelector(
    getAppState,
    state => {
        return state.ActiveConfiguratie;
    }
);

export const getLoadingStatus = createSelector(
    getAppLoadingState,
    state => {
        return state.appLoading;
    }
);

export function apploading_reducer(state: AppLoadingState = initialAppLoadingState, action: AppConfigActions.ActionType): fromRoot.AppState {
    switch (action.type) {
        case AppConfigActions.AppConfigTypes.SETAPPLOADING: {
            return {
                appLoading: action.payload
            };
        }
        default:
            return state;
    }
}

export function appconfig_reducer(state: AppConfigState = initialState, action: AppConfigActions.ActionType): fromRoot.AppState {
    switch (action.type) {
        case AppConfigActions.AppConfigTypes.LOAD_APP_CONFIG: {
            return {
                ...state
            };
        }
        case AppConfigActions.AppConfigTypes.GET_DEFAULT_APP_CONFIG: {
            return {
                ...state
            };
        }
        case AppConfigActions.AppConfigTypes.SET_DEFAULT_APP_CONFIG: {
            return {
                ...state,
                ActiveConfiguratie: action.payload
            };
        }
        case AppConfigActions.AppConfigTypes.SUCCESS_LOAD_APP_CONFIG: {
            return {
                ...state,
                AppConfiguratie : action.payload
            };
        }
        default:
            return state;
    }
}
