
<mat-card style="height: 40%; width: 500px; margin-left: 10px; margin-top: 10px;">
    <ng-container *ngIf="currentFeature">
        <div style="position: relative;">
            <h3 style="color: #007A7A; font-weight: 500;">Feature foto</h3>
            <div matTooltip="{{createTooltip(currentFeature)}}" [matTooltipPosition]="'right'" style="margin-top: 20px; justify-content: center; text-align: center;">
                <img 
                [src]="currentFeature.viewer_fullimageurl" height="95%" width="90%"/>
                <br>
            </div>  
            <!-- left -->
            <div style="position: absolute; top: 45%; left: 0px; height: 50px; width: 20px;">
                <button (click)="goLeft();" mat-mini-fab color="primary">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </div>
            
            <!-- right -->
            <div style="position: absolute; top: 45%; right: 0px; height: 50px; width: 50px;">
                <button (click)="goRight();" mat-mini-fab color="primary">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
            <!-- Fullscreen -->
            <div style="position: absolute; top: 25px; right: 17px; height: 30px; width: 30px;">
                <button (click)="goFullscreen(currentFeature.viewer_fullimageurl);" mat-mini-fab color="primary">
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </div>
        </div>

    </ng-container>
    <hr>
    <h3 style="color: #007A7A; font-weight: 500;">Features</h3>
                <!-- DETAILS  -->
    <ng-container *ngFor="let feature of imageInput; let i = index;">
                    <mat-expansion-panel style="max-width: 100%;" class="mat-elevation-z0"  [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-description style="padding-right: 20px;" 
                            >
                            <span [ngStyle]="currentFeature.feature_identifier_name === feature.values_.feature_identifier_name ? 
                            {'color': '#007A7A', 'font-weight': '600'} : 
                            {'background-color': 'transparant'}">
                            {{feature.values_.feature_identifier_name}}  
                        </span>
                            </mat-panel-description>
                            <button style="margin-right: 20px;  height: 32px; width: 32px;" (click)="$event.stopPropagation(); goToIndex(i);" mat-mini-fab>
                                <mat-icon style="font-size: 16px;" color="primary" matSuffix>photo_camera</mat-icon>
                            </button>
                        </mat-expansion-panel-header>
    
                        <app-merkator-feature [feature]="feature"></app-merkator-feature>
                    </mat-expansion-panel>
                    <hr *ngIf="i+1 !== feature.length" class="merkator-featurecontainer-hr">
    </ng-container> 
</mat-card>

