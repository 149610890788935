import { Action } from '@ngrx/store';
import * as AppConfigClasses from './../../classes-types/appconfig';

// Enums
export enum AppConfigTypes {
    LOAD_APP_CONFIG = '[App] load appconfig',
    SUCCESS_LOAD_APP_CONFIG = '[App] app load config',
    SET_DEFAULT_APP_CONFIG = '[App] set default',
    GET_DEFAULT_APP_CONFIG = '[App] get default',
    RESET_APP = '[App] reset',
    SETAPPLOADING = '[App] start loading animation'
}

export class LoadAppConfig implements Action {
    readonly type = AppConfigTypes.LOAD_APP_CONFIG;
}

export class SuccesLoadAppConfig implements Action {
    readonly type = AppConfigTypes.SUCCESS_LOAD_APP_CONFIG;
    constructor(public payload: Array<AppConfigClasses.AppConfig>) {}
}

export class SetDefaultAppConfig implements Action {
    readonly type = AppConfigTypes.SET_DEFAULT_APP_CONFIG;
    constructor(public payload: AppConfigClasses.AppConfig) {}
}

export class GetDefaultAppConfig implements Action {
    readonly type = AppConfigTypes.GET_DEFAULT_APP_CONFIG;
}

export class ResetAppConfig implements Action {
    readonly type = AppConfigTypes.RESET_APP;
}

export class SetAppLoading implements Action {
    readonly type = AppConfigTypes.SETAPPLOADING;
    constructor(public payload: boolean) { }
}

// Export as 1 ActionType
export type ActionType = LoadAppConfig | SuccesLoadAppConfig | GetDefaultAppConfig | SetDefaultAppConfig | ResetAppConfig | SetAppLoading;
