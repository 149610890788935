import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { delay, map, tap} from 'rxjs/operators';
import * as snackBarActions from '../actions/snackbar-actions/snackbar.actions';
import { Action } from '@ngrx/store';
import { SnackBarComponent } from '../components/app-utility-components/snack-bar/snack-bar.component';

@Injectable()
export class SnackbarEffects {

 constructor(private actions: Actions,
             private matSnackBar: MatSnackBar) {
 }
  delay = 2000;

  closeSnackBar$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(snackBarActions.SnackbarTypes.SNACKBAR_CLOSE),
            tap(() => this.matSnackBar.dismiss()),
        ),
        { dispatch: false }
  );

  showSnackbar$: Observable<Action> = createEffect(() =>
        this.actions.pipe(ofType(snackBarActions.SnackbarTypes.SNACKBAR_OPEN),
            map((action: snackBarActions.SnackbarOpen) => action.payload),
            tap(payload => this.delay = payload.delay),
            tap(payload => this.matSnackBar.openFromComponent(SnackBarComponent, {
                data: { message: payload.message, type: payload.type, params: payload.params },
                panelClass: [payload.panelClass]
            })),
            delay(this.delay),
            map(() => new snackBarActions.SnackbarClose())
        ),
  );

}
