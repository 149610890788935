
<div style="position:relative; width: 100%; height: 90%;">

    <div style="position:absolute; top: 0; right: 10px; z-index: 1000;">
        <button mat-icon-button (click)="close();">
            <mat-icon>close</mat-icon>
          </button>
    </div>


    <div>

        <h3 style="color: #007A7A"> {{ 'usermanagement.adduser' | translate }} </h3>
        <form [formGroup]="formGroup">
            
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ 'usermanagement.email' | translate }}</mat-label>
                <input formControlName="usernameControl" matInput> 
            </mat-form-field>
            <span style="color: red; width: 100%;" *ngIf="formGroup.controls['usernameControl'].errors?.required && formGroup.touched">
                {{ 'usermanagement.requiredfield' | translate }}
            </span>
            <span style="color: red; width: 100%;" *ngIf="formGroup.controls['usernameControl'].errors?.email && formGroup.touched">
                {{ 'usermanagement.validemail' | translate }}
            </span>
            <br>
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ 'usermanagement.fullname' | translate }}</mat-label>
                <input formControlName="fullNameControl" matInput>             
            </mat-form-field>

        </form>

    </div>

    <div style="position:absolute; bottom: 0; right: 10px; z-index: 1000; width: 100px;">
        <button mat-stroked-button style="width: 100px;" (click)="saveUser()">Save</button>
    </div>

</div>

