import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as ThemeActions from '../../../actions/theme-actions/theme.actions';
import * as TranslateState from '../../../reducers/translate-reducer/translate.reducer';
import * as TranslateActions from '../../../actions/translate-actions/translate.actions';
import * as AppConfigActions from '../../../actions/appconfig-actions/appconfig.actions';
import * as BottomSheetActions from '../../../actions/bottomsheet-actions/bottomsheet.actions';
import * as ThemeState from '../../../reducers/theme-reducer/theme.reducer';
import * as AppconfigState from '../../../reducers/appconfig-reducer/appconfig.reducer';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../../../classes-types/appconfig';
import { ApplicationService } from '../../../services/app-state-services/application.service';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { Organisation } from '../../app-state-components/chooseorganisation/chooseorganisation.component';
import { ComponentmanagerService, Components } from '../../../services/app-state-services/componentmanager.service';
import { MatDialog } from '@angular/material/dialog';
import { PatchNotesComponent } from '../patch-notes/patch-notes.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-menu-settings',
  templateUrl: './menu-settings.component.html',
  styleUrls: ['./menu-settings.component.scss']
})
export class MenuSettingsComponent implements OnInit {

  @Input() mobile: boolean;

  constructor(private store: Store<AppState>, private translate: TranslateService,
              public appService: ApplicationService, public http: HttpService, public compManager: ComponentmanagerService,
              public dialog: MatDialog, private oidc: OidcSecurityService) { }

  availableLanguages: Array<string> = [];
  appConfigArray: Array<AppConfig> = [];
  currentActiveApp: AppConfig;
  currentActiveLanguage: string;
  theme: string;
  organisation: { id: string, organisation: string };
  organisationNames: string[];
  organisationId: string[];
  showOrganisations: boolean = false;
  orgArray: Array<Organisation>;
  showArray: Array<Organisation>;

  ngOnInit(): void {  // console.log(this.mobile);
    this.store.pipe(select(TranslateState.getTranslate)).subscribe((translate: TranslateState.TranslateState) => {
      this.handleTranslateChange(translate);
    });
    this.store.pipe(select(ThemeState.getTheme)).subscribe((theme: ThemeState.ThemeState) => {
      this.theme = theme.current_theme;
    });
    this.store.pipe(select(AppconfigState.getActiveApp)).subscribe(result => {
      this.currentActiveApp = result;
    });
    this.store.pipe(select(AppconfigState.getApp)).subscribe(result => {
      this.appConfigArray = result;
      // Switch org
      if (sessionStorage.getItem('ao') != null) {
        this.organisation = JSON.parse(sessionStorage.getItem('ao'));
        this.orgArray = this.fillArray();
        this.showArray = this.orgArray;
        this.showOrganisations = true;
      }
    });
  }

  public openLogGrid(): void {
    this.http.get( this.http.apiUrl + 'merkatorgrid/getidgridlogging' ).subscribe(result => {
      this.compManager.bottomSheetComponent = this.compManager.StringToComponent(Components.GRIDCOMPONENT);
      this.compManager.bottomSheetParams = {idGrid: result.id};
      this.store.dispatch(new BottomSheetActions.BottomSheetOpen({}));
    });
  }

  public whatsNew(): void {
    // console.log('im supposed to open a dialog');
    this.dialog.open(PatchNotesComponent, {
      height: '50%',
      width: '25%'
    });
  }

  public openSupport(): void {
    window.open('https://www.merkator.com/support/', '_blank', null);
  }

  public openMerkator(): void {
    window.open('https://www.merkator.com/', '_blank', null);
  }

  public openBaseline(): void {
    window.open('https://savebelow-merkator.gitbook.io/save-below/-Mba8uYJv3uapGkAjAZd/', '_blank', null);
  }

  public logoffAndRevokeTokens(): void {
    this.oidc.logoffAndRevokeTokens()
       .subscribe((result) => console.log(result));
  }

  public openUserManagement(): void {
    this.compManager.bottomSheetComponent = this.compManager.StringToComponent(Components.USERMANAGEMENTCOMPONENT);
    this.compManager.bottomSheetParams = {};
    this.store.dispatch(new BottomSheetActions.BottomSheetOpen({}));
  }

  public changeTheme(theme: string): void {
    this.store.dispatch(new ThemeActions.ChangeThemeAction(theme));
  }

  public changeApp(appConfig: AppConfig): void {
    this.appService.CurrentActiveIdApplicatie = appConfig.id;
    this.store.dispatch(new AppConfigActions.SetDefaultAppConfig(appConfig));
  }

  public changeLanguage(language: string): void {
    this.postDefaultLanguage(language);
    this.store.dispatch(new TranslateActions.ChangeTranslateAction(language));
    window.location.reload();
  }

  public handleTranslateChange(translate: TranslateState.TranslateState): void {
    if (this.availableLanguages.length === 0) {
      this.availableLanguages = translate.available_languages;
    }
    this.currentActiveLanguage = translate.current_active_language;
    this.translate.setDefaultLang(translate.current_active_language);
  }

  public postDefaultLanguage(translate: any): void {
     this.http.getText(this.http.apiUrl + 'MerkatorLanguage/SetLanguage?language=' + translate).subscribe(result => {
      this.currentActiveLanguage = translate;
    });
  }

  public changeOrg(orgId: number): void {
    this.store.dispatch(new AppConfigActions.ResetAppConfig());
    this.appService.organisatieTitle = JSON.parse(sessionStorage.getItem('ao'))[orgId];
    this.http.id_organisation = orgId.toString();
    sessionStorage.setItem('io', this.http.id_organisation);
    window.location.reload();
    // this.store.dispatch(new AppConfigActions.LoadAppConfig());
  }

  fillArray(): Array<Organisation> {
    const result = new Array<Organisation>();
    for (const key in this.organisation) {
      const value = this.organisation[key];
      const org = new Organisation();
      org.id = key;
      org.name = value;
      result.push(org);
    }
    return result;
  }

  filterMyArray(filter: string): void {
    if (filter === '') {
      this.showArray = this.orgArray;
    } else {
      this.showArray = this.orgArray.filter( org => org.name.includes(filter));
    }
  }

}
