import {Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state-interface/app.state";
import {ComponentmanagerService} from "../../../services/app-state-services/componentmanager.service";
import {ApplicationService} from "../../../services/app-state-services/application.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HttpService} from "../../../services/app-utililty-services/http.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ButtonmethodService} from "../../../services/app-buttons-services/buttonmethod.service";


@Component({
  selector: 'app-cronjob-menu',
  templateUrl: './cron-job-menu.component.html',
  styleUrls: ['./cron-job-menu.component.scss']
})
export class CronJobMenuComponent implements OnInit, OnDestroy {
  private readonly cronkey:string;
  private readonly savebelow_org: string;
  done:boolean;
  buttonprops = new Map<number, string>();
  constructor( store: Store<AppState>, private compManager: ComponentmanagerService, public appService: ApplicationService, public http: HttpService, public dialog: MatDialog,public buttonService: ButtonmethodService,
  @Inject(MAT_DIALOG_DATA) data: { myObjectHolder: any }) {

    let paramData = data.myObjectHolder;
    this.cronkey = paramData.cronkey;
    this.savebelow_org = paramData.savebelow_org;
    paramData.ids.forEach(d=>{
      this.buttonprops.set(d.id,d.name);
    });

  }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.done = false;
  }
  startCronJon(id:number){
    let httpHeaders= new HttpHeaders();
    httpHeaders=httpHeaders.set("cronkey",this.cronkey);
    httpHeaders=httpHeaders.set("savebelow_org",this.savebelow_org);
    this.http.get(this.http.apiUrl+'Document/startCronMailing?id='+id,httpHeaders).subscribe(result=>{
      if(result.isSuccessStatusCode){
        this.done = true;
        console.log(result.isSuccessStatusCode)
      }
    });
  }
}

