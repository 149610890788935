import * as menuActions from './../../actions/menu-actions/menu.actions';
import * as fromRoot from '../../state-interface/app.state';
import * as menuClasses from '../../classes-types/main.menu';
import { createSelector, createFeatureSelector } from '@ngrx/store';


export interface MenuState extends fromRoot.AppState {
    menuObject: Array<menuClasses.MainMenu>;
}

const initialState: MenuState = {
    menuObject: []
};

const getMenuState = createFeatureSelector<MenuState>('menu');

export const getMenu = createSelector(
    getMenuState,
    state => {
        return state.menuObject;
    }
);

export function menu_reducer(state: MenuState = initialState, action: menuActions.ActionType): fromRoot.AppState {
    switch (action.type) {
        case menuActions.MenuTypes.LOAD_MENU: {
            return {
                ...state
            };
        }
        case menuActions.MenuTypes.SUCCES_LOAD_MENU: {
            return {
                ...state,
                menuObject : action.payload
            };
        }
        default:
            return state;
    }
}
