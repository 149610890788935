<div *ngIf="buttons && buttons.length > 0" class="transition" style="position:relative; pointer-events: none;"
        [ngStyle]="{'position': 'absolute', 'top': positionObject.top, 'left': positionObject.left, 'right': positionObject.right, 'bottom': positionObject.bottom }">

    <!-- DrawFinishedButtons -->
    <ng-container *ngIf="buttonService.drawfinished">
        <ng-container *ngFor="let button of buttonService.onDrawFinishedButtons; let i = index;">
            <!-- meerdere mat icons om icons te mergen in 1-->
            <button matTooltip="{{ button.tooltip | translate }}" #tooltip="matTooltip" *ngIf="buttonService.onDrawFinishedButtons.length !== i+1" mat-mini-fab color="primary" style="margin-left: 3px; margin-right: 3px; pointer-events: auto;" (click)="buttonService.onDrawFinishMethods(button)">
                <mat-icon *ngIf="button.icon === 'delete'" style="font-size: 18px; position: absolute; bottom: 3px; right: -2px;">
                    edit
                </mat-icon>
                <mat-icon *ngIf="button.icon === 'delete'" style="position: absolute; left: 4px; bottom: 7px;">
                    {{button.icon}}
                </mat-icon>
                <mat-icon *ngIf="button.icon !== 'delete'">
                    {{button.icon}}
                </mat-icon>
            </button>
            <button matTooltip="{{ button.tooltip | translate }}" #tooltip="matTooltip" *ngIf="buttonService.onDrawFinishedButtons.length === i+1" mat-mini-fab color="primary" style="margin-right: 10px; margin-left: 3px; pointer-events: auto;" (click)="buttonService.onDrawFinishMethods(button)">
                <mat-icon *ngIf="button.icon === 'delete'" style="font-size: 18px; position: absolute; bottom: 3px; right: -2px;">
                    edit
                </mat-icon>
                <mat-icon *ngIf="button.icon === 'delete'" style="position: absolute; left: 4px; bottom: 7px;">
                    {{button.icon}}
                </mat-icon>
                <mat-icon *ngIf="button.icon !== 'delete'">
                    {{button.icon}}
                </mat-icon>
            </button>
            <div *ngIf="buttonService.onDrawFinishedButtons.length === i+1" style="height: 36px; width: 4px; background-color: white; display: inline-block; position: absolute; top: 5px; left: 232px; border-radius: 95%;">

            </div>
        </ng-container>
    </ng-container>

    <!-- KLIC SNIJD DRAW FINISHED-->
    <ng-container *ngIf="buttonService.klicDrawFinished">
        <ng-container *ngFor="let button of buttonService.OnDrawFinishedKlicFeature; let i = index;">
            <button matTooltip="{{ button.tooltip | translate }}" #tooltip="matTooltip" mat-mini-fab color="primary" style="margin-right: 10px; margin-left: 3px; pointer-events: auto;" (click)="buttonService.onDrawFinishMethods(button)">
                <mat-icon>
                    done
                </mat-icon>
            </button>
        </ng-container>
    </ng-container>

    <!--
    <ng-container *ngIf="buttonService.measurefinished">
        <ng-container *ngFor="let button of buttonService.onMeasureFinishedButtons">
            <button mat-mini-fab color="primary" style="margin: 3px;" (click)="buttonService.onDrawFinishMethods(button)">
                <mat-icon style="position: absolute; bottom: 6px; right: -2px;">
                    square_foot
                </mat-icon>
                <mat-icon style="position: absolute; left: 4px; bottom: 7px;">
                    {{button.icon}}
                </mat-icon>
            </button>
        </ng-container>
    </ng-container>
    -->

    <ng-container *ngFor="let button of buttons">
        <ng-container *ngIf="!button.children">
            <button [matTooltip]="button.tooltip" #tooltip="matTooltip" (click)="buttonService.buttonCreateMyComponent(button, false);" [ngClass]="button == activeButton ? 'activebutton' : ''" color="primary" mat-mini-fab style="margin-left: 3px; margin-right: 3px; pointer-events: auto;">
                    <ng-container>
                        <mat-icon>
                            {{button.icon}}
                        </mat-icon>
                    </ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="button.children" >
           <app-expandablebutton matTooltip="{{ 'tooltip.open' | translate }}" #tooltip="matTooltip" style="margin-left: 3px; margin-right: 3px; pointer-events: auto;" [expandTo]="'bottom'" [button]="button"></app-expandablebutton>
        </ng-container>
    </ng-container>
    <button matTooltip="{{ 'tooltip.cancel' | translate }}" (click)="buttonService.putMapInteractionOff();" mat-mini-fab color="primary" style="margin-left: 3px; margin-right: 3px; pointer-events: auto;">
        <mat-icon>
            close
        </mat-icon>
    </button>

</div>