<div style="padding: 10px">

  <merkator-mapprint-client *ngIf="service && loaded"
                            [boxCenter]="boxCenter"
                            [service]="service"
                            [mapinfo]="mapinfo"
                            [presets]="presets"
                            (boxEvent)="boxevent($event)"
                            (printEvent)="alert($event)"
                            (saveEvent)="alert($event)"
                            [canvas]="mapService.canvas"
                            (canvasEvent)="canvasEvent($event)">
  </merkator-mapprint-client>
  <!-- <merkator-mapprint *ngIf="service && loaded"
      [boxCenter]="boxCenter"
      [service]="service"
      [mapinfo]="mapinfo"
      [presets]="presets"
      (boxEvent)="boxevent($event)"
      (printEvent)="alert($event)"
      (saveEvent)="alert($event)"
      [canvas]="mapService.canvas"
      (canvasEvent)="canvasEvent($event)">
  </merkator-mapprint> -->
  <mat-card class="featuresCardContainer" *ngIf="featuresInsideBbox && objectKeys(featuresInsideBbox).length > 0">
    <mat-accordion #featuresAccordion class="bevindingenAccordion" displayMode="flat">
      <mat-expansion-panel *ngFor="let layer_name of objectKeys(featuresInsideBbox)">
        <mat-expansion-panel-header>
          <mat-panel-description>
            {{layer_name}} <mat-checkbox style="position:absolute; right: 49px;" checked="true" (change)="toggleAllFeatures(layer_name, $event)"></mat-checkbox>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-card (mouseenter)="highlightFeature(feature);" (mouseleave)="stopHighlightFeature(feature);"  class="featuresCard" *ngFor="let feature of featuresInsideBbox[layer_name]">
          <div class="row space-between-center">
            <p class="featureText">{{feature.getProperties().feature_identifier_name}}</p>
            <mat-checkbox style="margin: 5px 5px 0px 5px" checked="true" id="{{makeFeatureCheckBoxId(feature, layer_name)}}" (change)="toggleFeature(layer_name, feature, $event)"></mat-checkbox>
          </div>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
</div>

