import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
// @ts-ignore
import {
  MerkatorMapComponent, MerkatorMapInteractionTypes, MerkatorDrawDirective,
  MerkatorMeasureDirective, equalsFilter, style, StylesMenuComponent, StylesMenuConfig
} from '@merkator/merkator-map';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as merkatorMapState from '../../../reducers/merkator-map-reducer/merkator-map.reducer';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import * as snackBarActions from '../../../actions/snackbar-actions/snackbar.actions';
import { Subscription } from 'rxjs';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import { ApplicationService } from '../../../services/app-state-services/application.service';
import { ComponentmanagerService, Components } from '../../../services/app-state-services/componentmanager.service';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { ViewerButton } from '../../../classes-types/button';
import { DrawSelectOptions, MultiSelectOptions, SelectPartOptions } from '@merkator/merkator-map/lib/models/interactionOptions';
import { MerkatorMapHttpHeadersValue } from '../../../classes-types/merkator-map.classes';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import * as modalActions from '../../../actions/matmodal-actions/matmodal.actions';

@Component({
  selector: 'app-merkator-map-impl',
  templateUrl: './merkator-map-impl.component.html',
  styleUrls: ['./merkator-map-impl.component.scss']
})
export class MerkatorMapImplComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private store: Store<AppState>, public mapService: MerkatorMapService,
              public appService: ApplicationService, public buttonManager: ButtonmethodService,
              public compManager: ComponentmanagerService, private http: HttpService,
              public changeDetection: ChangeDetectorRef) { }

  @ViewChild('map1', {static: false }) merkatorMap: MerkatorMapComponent;
  @ViewChild(MerkatorDrawDirective, {static: false}) merkatorDraw: MerkatorDrawDirective;
  @ViewChild(MerkatorMeasureDirective, {static: false}) merkatorMeasure: MerkatorMeasureDirective;

  subscriptionArray: Array<Subscription> = new Array<Subscription>();
  config: any;
  layersArray;
  stylesArray;
  projectionCode: string;
  filterDict = null;
  mapconfigArray = [];
  multiSelectObject;
  merkatorDrawObject;
  httpHeaders;
  viewParams;
  featureStyles;
  textStyles;
  stylesLoaded: boolean = false;
  layersLoaded: boolean = false;
  featureStylesLoaded: boolean = false;
  textStylesLoaded: boolean = false;

  activeLineColor: string = 'rgba(0, 199, 175, 1)';
  activeMeasureColor: string = 'rgba(0, 199, 175, 1)';
  activeFillColor: string = 'rgba(0, 199, 175, 1)';
  addCoordStyle: style = {
    fillcolor: "rgba(128,189,189,0.8)",
    id: 989795,
    strokecolor: "black",
    strokewidth: 2,
    stylename: "addCoords",
    iconsource: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAEoElEQVRoge2Z3W8UZRTGf2eWtlBEgtJu+dIQGhED8iEsCbAbqiYSolxqIglcGk0oiNyZGLjwjiuIfwAYb42JIaBeEGMw8YOYFjEkRAhSoFAt0m7blLLv48XutjPb2e7OMqOJ6bnYd949857nOXOeOfPOLszarM1aYqbLHRn92rElSQxLKrB60u2k7DoABa209XfvJYHjJRG0GNneA1oRraXjRCyRCuhqZwvj+RtI6eIXDJBveta29Y3FjZVMBcZH9vnIA7Qxf2JvElCxJyBhyB1ClMgLJEBHpPgrHn8FLnXsQrwAFImXE5FW09v2WtxwCUhIh4uDStPShwDH+3GjxVpS9aTXgnon4/rJQykpb4NtutcTF2a8FZA+AKwkGaaTB1Q4GCdkbBVQT7oddAMxN0AcKuU0jmylbf7zThy48VVA7sA08lLYvdACvBsXbCwV0PfL59E6/geweLpkJj/8iQ3S3PSMrb878rjYkROQMH5rS1PQMpRaClqO2I6013/SFOFqcrLPwF0A+nDuNl7zLbYM3DULnB09AV1qX0UqtQyxAqclYMtRYRnYUmAFUgfQFEpQPuwIPk35JhD9wE2k20K3PNSHszuYu4lL9Vn2wbWqCejy0mPIfRQA8Q01ZNGQT6o4N7RzTflMOmrZ/LHyN3P8CSB3DRDCgqX3B62YhxGs06fo6wTedT/lQBeytf2nkO0HTQQ7SRXy4V2mpk9SdfLV1xVMvGPZodMBzoSYetOvAp8jLQgE8g3/lmRKMUZN3puWGzpTybVqF9IvHVvw3BlE238oGYBBE3ssN3whjOeMbVSX2ldR0DlEZwAkDKi+LhNpHeKGFdhlXcNXqnGs+RzQj20dNHEWaUMAxDfUL5mZ1k1L7LIVCrusa6xvJn41txKWGeinmSzYN4ErVI28zxcqmarrAuS/tfGm7bXIQ4Qnsa52tjD09ymktwIEKgn6iYf46pDhFzYn/7Zto67350hbCQnj4uLjyB0OEPAPjUsGGZ94O/LdZrh6OTW0mdNPi04gOxBTlymSh5OpXL47Kpc5tU8JMZGPUTJlG26ESmMJYFvL4I8jGb/P0NZGmER+oZHwkDYXjxvuMmG+jI5G5xP9jeyHp56X9GQDe5kpn0J9C+h6YnXyCchlJslVfXGvQr7sK88rfU6ZqHQiJ+A8ZWKRTHnuO3blixPBIt/E5iwTucvUcRODMEeyFdB55oLWhUtmmpwGDbrN0Q0MVq8IU9UyXixiJJQALQs2IZqnk/DJSTiJTy3FGsvlT9rO/EnzUp2CE4hHNeTUTGHexuQSIJWZscug84bbmMoN77Pt+cl/ZCz74H5q58hBw1sHnA2XWmnuot0HkRJwlR1oUhZ203D7vWz+ZcuN9FZbb13DV7yu0d0m9iB+nyLPZDJOllwCBpmKqzciZ8fs0dBzlh05PeNif5xXRr+0hWNrTO4QMOSXk1m0Vlr/dvq7hYtk+gtkCCc45anwoeVGH+s3Tp1rXeJSjz422X7AQ8gmxp+217lfz/r6E/gqPV+toxeBfjPviO148HOjpEPjf920WfKOg9L28OFL9gajccaftVn7v9o/fzB7l3fCLDkAAAAASUVORK5CYII=",
    iconscale: 0.67
  }
  merkatorMapTmpStyle: style = new style(989796, 'Tekenstyle', this.activeLineColor, 1, this.activeFillColor, 'idontknow');
  merkatorMapTmpStyleTransparant: style = new style(989798, 'tmpstyle', this.activeLineColor, 1, 'rgba(255,255,255,0)', 'idontknow');
  merkatorMapTmpStyleMeasure: style = new style(989797, 'Meetstyle', this.activeMeasureColor, 2, 'rgba(255,255,255,0)', 'POINT');
  initialCenter = null;
  initialZoom = null;
  mapToBlobFromBlobToFeature: boolean = false;
  canvasReport;
  mMapButtonPosition = {top: '50px', right: '10px', vertical: true};
  stylesMenuConfig: StylesMenuConfig = {
    enabled: true,
    style_ids: [989796,989797,989798]
  }

  /**
   * Part of selected geometry ( line )
   */
  selectPartGeometry: any;

  /**
   * The cut part of the ^ selected geometry
   */
  // cutFromSelectedPartGeometry: any;


  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.subscriptionArray.push(
      this.store.pipe(select(merkatorMapState.getMerkatorMapConfiguraties)).subscribe(mapconfiguratie => {
        if (mapconfiguratie.merkatorMapConfiguraties.length > 0) {
          if (this.checkIdApplicatie(mapconfiguratie)) {
            if (!this.arraysEqual(this.mapconfigArray, mapconfiguratie.merkatorMapConfiguraties)) {
              if ( (mapconfiguratie.merkatorMapConfiguraties[0] as any).initialcenter) {
                const center = (mapconfiguratie.merkatorMapConfiguraties[0] as any).initialcenter;
                this.initialCenter = [parseFloat(center.split(',')[0]), parseFloat(center.split(',')[1]) ];
              }
              if ( (mapconfiguratie.merkatorMapConfiguraties[0] as any).initialzoom) {
                this.initialZoom = (mapconfiguratie.merkatorMapConfiguraties[0] as any).initialzoom;
              }
              this.mapService.active_id_map = (mapconfiguratie.merkatorMapConfiguraties[0] as any).id;
              this.projectionCode = (mapconfiguratie.merkatorMapConfiguraties[0] as any).projectioncode;
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapLayers());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapStyles());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapFilters());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapViewParams());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapHttpHeaders());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapFeatureIdentifiers());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapFeatureStyle());
              this.store.dispatch(new merkatorMapActions.LoadMerkatorMapTextStyle());
            }
            this.mapconfigArray = mapconfiguratie.merkatorMapConfiguraties;
            this.config = mapconfiguratie.merkatorMapConfiguraties[0];
            this.mapService.config = this.config;
          }
        }
      }),
      this.store.pipe(select(merkatorMapState.getLayers)).subscribe(layersArray => {
        if (layersArray.loaded) {
          this.layersArray = layersArray.merkatorMapLayers;
          this.layersLoaded = true;
        }
      }),
      this.store.pipe(select(merkatorMapState.getMapFilters)).subscribe( filterDict => {
          if (filterDict.merkatorMapFilters != null) {
            // console.log(filterDict.merkatorMapFilters);
            this.filterDict = filterDict.merkatorMapFilters;
          }
        }
      ),
      this.store.pipe(select(merkatorMapState.getStyles)).subscribe(stylesArray => {
        if (stylesArray.loaded) {
          this.stylesArray = stylesArray.merkatorMapStyles;
          this.stylesLoaded = stylesArray !== undefined;
        }
      }),

      this.store.pipe(select(merkatorMapState.TalkToMapReference)).subscribe( payload => {
        this.handleTalkToMapReference(payload);
      }),
      this.store.pipe(select(merkatorMapState.getHttpHeaders)).subscribe( payload => {
        if (payload.loaded) {
          this.httpHeaders = this.processHttpHeaders(payload.merkatorMapHttpHeaders);
          setInterval(() => {
              this.httpHeaders = this.processHttpHeaders(payload.merkatorMapHttpHeaders);
          }, 1800000);
        }
      }),
      this.store.pipe(select(merkatorMapState.getViewParams)).subscribe( payload => {
        if (payload.loaded) {
          this.viewParams = payload.merkatorMapViewParams;
        }
      }),
      this.store.pipe(select(merkatorMapState.getMerkatorMapFeatureStyle)).subscribe( payload => {
        if (payload.loaded) {
          this.featureStyles = payload.merkatorMapFeatureStyles;
          this.featureStylesLoaded = true;
        }
      }),
      this.store.pipe(select(merkatorMapState.getMerkatorMapTextStyle)).subscribe( payload => {
        if (payload.loaded) {
          this.textStyles = payload.merkatorMapTextStyleState;
          this.textStylesLoaded = true;
        }
      }),
      this.store.pipe(select(merkatorMapState.getMapColorPicker)).subscribe( payload => {
        // console.log(payload);
        this.activeFillColor = payload.activefillcolor;
        this.activeLineColor = payload.activelinecolor;
        this.activeMeasureColor = payload.activemeasurecolor;
        // Create fake styleobject as if it came from the db, with random nonlikely id.
        // Then add it to the array to be able to use it later when the draw flow is default
        this.merkatorMapTmpStyle = new style(989796, 'tmpstyle', this.activeLineColor, 1, this.activeFillColor, 'idontknow');
        this.merkatorMapTmpStyleMeasure = new style(989797, 'Meetstyle', this.activeMeasureColor, 2, 'rgba(255,255,255,0)', 'POINT');
        if (this.stylesLoaded) {
          this.stylesArray.push(this.merkatorMapTmpStyle);
          this.stylesArray.push(this.merkatorMapTmpStyleMeasure);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach(sub => {
      sub.unsubscribe();
    });
  }

  mapToBlob(event: Blob): void {
    // console.log(event, 'MapToBlob', this.mapToBlobFromBlobToFeature);
    // console.log(event);
    if (this.mapToBlobFromBlobToFeature) {
      this.mapToBlobFromBlobToFeature = false;
      this.mapService.canvasReportEventEmitter.emit({canvas: event});
    } else {
      this.mapService.canvas = {
        timestamp: new Date().getTime(),
        canvas: event
      };
    }
  }

  processHttpHeaders(merkatorMapHeadersResponse: any): any {
    let newResponse = {};

    Object.keys(merkatorMapHeadersResponse).forEach( key => {
      newResponse[key] = [];

      for(let value of merkatorMapHeadersResponse[key]) {
        if (value.getfromstorage && value.key === 'Authorization') {
          value.value = 'Bearer ' + this.http.id_token;
        } else if ( value.getfromstorage ) {
          value = this.getValueFromStorage(value);
        }
        //console.log(value);
        newResponse[key].push(value);
      }
    });
    return newResponse;
  }

  getValueFromStorage(value: MerkatorMapHttpHeadersValue): MerkatorMapHttpHeadersValue {
    if (value.key === 'id_organisation') {
      value.value = sessionStorage.getItem('io');
    } else {
      value.value = sessionStorage.getItem(value.key);
    }
    return value;
  }

  CustomMapReady($event: any): void {
    this.changeDetection.detectChanges();
    this.mapService.mapRef = $event;

      this.mapService.drawRef = this.merkatorDraw;


    this.mapService.mapRef.addDrawingLayer('boxeventdrawlayer');
    this.stylesArray.push(this.merkatorMapTmpStyle);
    this.stylesArray.push(this.merkatorMapTmpStyleMeasure);
    this.stylesArray.push(this.merkatorMapTmpStyleTransparant);
    this.mapService.mapRef.addStyle(this.addCoordStyle);

    this.mapService.mapRef.clickedFeatures.subscribe(features => {

      if (features === null ) {
        features = [];
      }
      if ( this.merkatorDrawObject?.type === 'drawselect') {
        const fakeButton = new ViewerButton() as any;
        const params = {
          payload: { params: { imageInput: features[this.merkatorDrawObject.layername],
            config: this.merkatorDrawObject },
          },
            component: Components.IMAGECAROUSEL,
            sidenav: 'left',
            matsidenavConfig: {
              hasBackdrop: false
          }

        };
        fakeButton.payload = params;
        fakeButton.rights = {edit: false, create: false, view: true, delete: false};
        this.buttonManager.buttonCreateMyComponent(fakeButton, false, true);
        this.compManager.StringToMethod('off', null);
      } else {
        this.store.dispatch(new merkatorMapActions.SetClickedFeatures(features));
        const fakeButton = new ViewerButton() as any;
        this.mapService.multiSelectSettings.component = 'FeaturelistComponent';
        fakeButton.payload = this.mapService.multiSelectSettings;
        fakeButton.payload = JSON.stringify(fakeButton.payload);
        fakeButton.rights = '{"edit": false, "create": false, "view": true, "delete": false}';
        this.buttonManager.buttonCreateMyComponent(fakeButton, false);
        this.compManager.StringToMethod('off', null);
      }
    });
    // console.log(document.getElementById('merkator-map-coordinate-scale-frame-id'));
    document.getElementById('merkator-map-coordinate-scale-frame-id').classList.add('merkator-map-coordinate-scale-frame-class');
    document.getElementById('merkator-map-coordinate-scale-frame-id').style.left = '65px';
    // document.getElementById('merkator-map-coordinate-scale-frame-id')
  }

  errorEventThrownByMap(event: any): void {
    if ( event.type === 'fitview') {
      this.store.dispatch(new snackBarActions.SnackbarOpen({ message: 'merkatormap.nogeometryfound',
      type: null, panelClass: 'extra-class-snackbar-error' }));    }
  }

  measureFinished(event: any): void {
    // this.buttonManager.measurefinished = true;
    this.compManager.StringToMethod('off', null);
  }

  drawFinished(event: any): void {
    // console.log(event, this.merkatorDrawObject);
    if (this.merkatorDrawObject?.type !== 'drawlabel') {
      this.buttonManager.drawfinished = true;
      this.compManager.StringToMethod('off', null);
    }
    // this.compManager.StringToMethod('off', null);
    if ( this.merkatorDrawObject?.flow !== 'default' ) {
      this.merkatorDraw.verwijderVorige('Tekenlaag');
      this.store.dispatch(new merkatorMapActions.ResetDrawedFeature());
    }
    if ( this.merkatorDrawObject?.primaryValue) {
      event.primaryValue = this.merkatorDrawObject.primaryValue;
    }
    event.flow = this.merkatorDrawObject.flow;
    event.done = false;
    this.store.dispatch(new merkatorMapActions.SetDrawedFeature(event));
  }

  coordinatesCopied(event: any): void {
    this.compManager.StringToMethod('off', null);
    this.store.dispatch(new snackBarActions.SnackbarOpen({ message: 'clipboard.success',
                                                           type: null, panelClass: 'extra-class-snackbar' }));
  }

  modifiedFeature(event: any): void {
    // TODO modifiedFeature() toFeatureCollection
    const newa = this.merkatorMap.getGeoJsonFromFeature(event);
  }

  klicFeatureFinished(event: any): void {
    // console.log(event.id);
    this.mapService.idpipe = event.id;
    this.selectPartGeometry = event;
    this.store.dispatch(new modalActions.MatModalOpen({ title: 'nmp.title', component: Components.NMPEXPORTMAILINGCOMPONENT,
                                                        params:  { featureid: event.id, featurename: event.properties.name } }));
  }

  klicSelectedPart(event: any): void {
    // console.log('selectedpart function');
    // console.log(event);
    this.mapService.cutFromSelectedPartGeometry = JSON.stringify( { type : event.geometry.type, coordinates: event.geometry.coordinates });
    this.buttonManager.klicDrawFinished = true;
  }

  generateFakeButtonGkalForm(): ViewerButton {
    const fakeButton = new ViewerButton();
    fakeButton.payload = `{
        "payload": {
          "params": {}
        },
        "sidenav": "right",
        "component": "GkalformimplComponent"
      }
     `;
    return fakeButton;
  }

  arraysEqual(arr1: any, arr2: any): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = arr1.length; i--;) {
        if (arr1[i].id !== arr2[i].id) {
            return false;
        }
    }
    return true;
  }

  checkIdApplicatie(mapconfigArray: any): boolean {
    let result = false;
    mapconfigArray.merkatorMapConfiguraties.forEach((mapconfig: any) => {
      if (this.appService.CurrentActiveIdApplicatie === mapconfig.id_applicatie) {
        result = true;
      }
    });
    return result;
  }

  public zoomIn(): void {
    this.merkatorMap.zoomPlus();
  }

  public zoomOut(): void {
    this.merkatorMap.zoomMin();
  }

  test(): void {
    this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: 'measure', geometry: 'linestring'}));
  }

  interactionCanceled(event: string): void {
    if ( event === 'klicselect') {
      this.buttonManager.klicDrawFinished = false;
      this.compManager.StringToMethod('off', null);
    }
  }

  handleTalkToMapReference(payload: any): void {
    if ( payload.merkatorMapAction.type === MerkatorMapInteractionTypes.Select ) {
      this.merkatorMap.setInteractionOnLayer({type: MerkatorMapInteractionTypes.Select,
                                              geometry: payload.merkatorMapAction.geometry}, '10');
    } else if ( payload.merkatorMapAction.type === MerkatorMapInteractionTypes.MultiSelect ) {
      // console.log(payload.merkatorMapAction);
      this.merkatorDrawObject = payload.merkatorMapAction;
      this.merkatorMap.setInteraction(payload.merkatorMapAction);
    } else if ( payload.merkatorMapAction.type === MerkatorMapInteractionTypes.KlicSelect ) {
      // console.log('klicselect');
      this.merkatorMap.setInteraction({options: {layers: [payload.merkatorMapAction?.idlayer.toString()],
                                       quitOnLeftClick: true, accuracyPx: 0,
                                       selectColor: 'rgb(0, 255, 0)', confirmColor: 'yellow' } as MultiSelectOptions, type: MerkatorMapInteractionTypes.KlicSelect});
      } else if (payload.merkatorMapAction.type ===  MerkatorMapInteractionTypes.SelectPart) {
        // console.log('selectpart');
        this.store.dispatch(new modalActions.MatModalClose());
        this.merkatorMap.setInteraction({options: {geojson: this.selectPartGeometry} as SelectPartOptions, type: MerkatorMapInteractionTypes.SelectPart});
      } else if ( payload.merkatorMapAction.type === MerkatorMapInteractionTypes.Draw) {
          if (payload.merkatorMapAction.flow === 'klic') {
            this.mapService.klicTypeMelding = payload.merkatorMapAction.typemelding;
            this.mapService.accesToken = payload.merkatorMapAction.accesToken;
            this.merkatorDrawObject = payload.merkatorMapAction;
            // console.log(this.merkatorDrawObject);
            this.merkatorMap.setInteraction(
            { type: MerkatorMapInteractionTypes.Draw,
              geometry: this.merkatorDrawObject.geometry,
              options:  this.merkatorDrawObject.options });
          } else if (payload.merkatorMapAction.flow === 'default' ) {
            this.merkatorDrawObject = payload.merkatorMapAction;
            this.merkatorDrawObject.options.style_id = 989796;
            this.merkatorMap.setInteraction(
            { type: MerkatorMapInteractionTypes.Draw,
              geometry: this.merkatorDrawObject.geometry,
              options:  this.merkatorDrawObject.options });
            // console.log('no drawing flow implemented');
          } else if ( payload.merkatorMapAction.flow === 'merkatorTab') {
            this.merkatorDrawObject = payload.merkatorMapAction;
            this.merkatorDraw.verwijderVorige('Tekenlaag');
            this.merkatorMap.setInteraction(
              { type: MerkatorMapInteractionTypes.Draw,
                geometry: this.merkatorDrawObject.geometry });
          }
    } else if (payload.merkatorMapAction.type === 'highlight') {
      this.merkatorMap.highLightFeature(payload.merkatorMapAction.feature);
    } else if (payload.merkatorMapAction.type === 'mapsavetopng') {
      this.merkatorMap.mapSaveToPng('boxeventdrawlayer');
    }  else if ( payload.merkatorMapAction.type === 'drawlabel') {
      this.merkatorDrawObject = payload.merkatorMapAction;
      this.merkatorMap.enableTextLabelConfig();
    } else if (payload.merkatorMapAction.type === 'clearhighlight') {
      this.merkatorMap.clearHighLight();
    } else if (payload.merkatorMapAction.type === 'zoomtofeature') {
      this.merkatorMap.zoomToFeature(payload.merkatorMapAction.feature);
    } else if (payload.merkatorMapAction.type === 'zoomtofeaturebyproperty') {
      // console.log(payload.merkatorMapAction);
      const value = new equalsFilter(payload.merkatorMapAction.datafromgrid.content);
      const prop = payload.merkatorMapAction.featurePropertyZoom;
      const connectedIdLayer = (payload.merkatorMapAction.connectedIdLayer).toString();
      try {
        this.merkatorMap.zoomToFeaturesByPropertyValue(connectedIdLayer, { [prop] : [value]},
          { highlight: true, minScale: 500,
            padding: { top: 0, bottom: 450, left: 0, right: 0 } } );
      } catch (ex) {
      }
    } else if (payload.merkatorMapAction.type === 'getmapblobbyproperty' ) {
      this.mapToBlobFromBlobToFeature = true;
      const pl = (payload.merkatorMapAction as any);
      const name = pl.identifiercolumn;
      const filters = { [name]: [new equalsFilter(pl.identifiervalue)]};
      this.merkatorMap.featuresSaveToPng(pl.connectedIdLayer.toString(), filters, {backgroundLayers: true});
    } else if ( payload.merkatorMapAction.type === 'done') {
      // TODO HANDLE DONE DRAW EVENT
      this.buttonManager.drawfinished = false;
      this.compManager.StringToMethod('off', null);
      if ( this.merkatorDrawObject.flow === 'klic') {
        const fakeButton = this.generateFakeButtonGkalForm();
        this.buttonManager.buttonCreateMyComponent(fakeButton, false);
      }
    } else if ( payload.merkatorMapAction.type === MerkatorMapInteractionTypes.Modify) {
      this.merkatorMap.setInteraction(  { type: payload.merkatorMapAction.type } );
    } else if ( payload.merkatorMapAction.type === 'deletedrawing') {
      this.merkatorDraw.verwijderAlleGetekende('Tekenlaag');
      this.buttonManager.drawfinished = false;
    } else if (payload.merkatorMapAction.type === 'renewdrawing') {
      this.merkatorMap.setInteraction(
        { type: MerkatorMapInteractionTypes.Draw,
          geometry: this.merkatorDrawObject.geometry,
          options:  this.merkatorDrawObject.options });
    } else if ( payload.merkatorMapAction.type === 'deletedmeasured' ) {
      this.merkatorMeasure.verwijderAlleMetingen();
      this.buttonManager.measurefinished = false;
    } else if (payload.merkatorMapAction.type === 'coordinatestoclipboard') {
      // coordinates
      this.merkatorMap.copyCoordinatesToClipboard();
    } else if (payload.merkatorMapAction.type === 'measure') {
      this.merkatorMap.setInteraction(
      { type: MerkatorMapInteractionTypes.Measure,
        geometry: payload.merkatorMapAction.geometry,
        options:  { style_id: 989797} });
      // this.merkatorMeasure.start(payload.merkatorMapAction.geometry);

    } else if ( payload.merkatorMapAction.type === 'exportExcelNmp') {
      // type & exporttype
      if ( payload.merkatorMapAction.exporttype === 'geometry' ) {
        // download excel with cut geometry
        /*console.log(this.mapService.cutFromSelectedPartGeometry);
        console.log(this.mapSerbb stvice.currentBuffer);*/
        this.mapService.exportMailingToExcel('geometry');
      }
    } else if (payload.merkatorMapAction.type === 'drawselect') {
      const options: DrawSelectOptions = {type: 'Polygon', continuous: false};
      this.merkatorDrawObject = payload.merkatorMapAction;
      // options.layer = payload.merkatorMapAction.layername;
      this.merkatorMap.setInteraction({type: MerkatorMapInteractionTypes.DrawSelect, options});
    } else if ( payload.merkatorMapAction.type !== null && payload.merkatorMapAction.geometry !== null ) {
      // console.log(payload.merkatorMapAction);
      this.merkatorMap.setInteraction(payload.merkatorMapAction);
    }
  }

}

