import * as buttonActions from './../../actions/button-actions/button.actions';
import * as fromRoot from '../../state-interface/app.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';

// Interfaces
export interface ITask {
    payload: string;
}

export interface IButton {
    icon: string;
    task: ITask;
    selectable: boolean;
}

// State
export interface ButtonState extends fromRoot.AppState {
    topButtons: any;
    leftButtons: any;
    rightButtons: any;
    bottomButtons: any;
    leftLoaded: boolean;
    rightLoaded: boolean;
    bottomLoaded: boolean;
    topLoaded: boolean;
    activeButton: any;
}

// InitialState
const initialButtonState: ButtonState = {
    topButtons: [],
    leftButtons: [
        /*{ icon: 'home',
          task: { action: panelActions.ActionTypes.SET_LEFTCOMPONENT, payload: { name: 'demo', values: { name: 'L1' }}},
          selectable: true
        },
        { icon: 'grade',
          task: { action: panelActions.ActionTypes.SET_LEFTCOMPONENT, payload: { name: 'demo', values: { name: 'L2' }}},
          selectable: true
        },
        { icon: 'grid_on',
          task: { action: panelActions.ActionTypes.SET_LEFTCOMPONENT, payload: { name: 'grid', values: { }}},
          selectable: true
        }*/
    ],
    rightButtons: [
        /*{
          icon: 'home',
          task: { action: panelActions.ActionTypes.SET_RIGHTCOMPONENT, payload: { name: 'demo', values: { name: 'R1' }}},
          selectable: true
        },
        {
          icon: 'grade',
          task: { action: panelActions.ActionTypes.SET_RIGHTCOMPONENT, payload: { name: 'demo', values: { name: 'R2' }}},
          selectable: true
        }*/
    ],
    bottomButtons: [
        /*{
          icon: 'home',
          task: { action: panelActions.ActionTypes.SET_BOTTOMCOMPONENT, payload: { name: 'demo', values: { name: 'B' }}},
          selectable: true
        },
        {
          icon: 'grid_on',
          task: { action: panelActions.ActionTypes.SET_BOTTOMCOMPONENT, payload: { name: 'grid', values: {}}},
          selectable: true
        }*/
    ],
    leftLoaded: false,
    rightLoaded: false,
    topLoaded: false,
    bottomLoaded: true,
    activeButton: null
};

// Selectors
const getButtonState = createFeatureSelector<ButtonState>('buttons');

export const getButtons = createSelector(
    getButtonState,
    state => {
        return {
            leftButtons: state.leftButtons,
            rightButtons: state.rightButtons,
            bottomButtons: state.bottomButtons,
            topButtons: state.topButtons,
            leftLoaded: state.leftLoaded,
            rightLoaded: state.rightLoaded,
            bottomLoaded: state.bottomLoaded,
            topLoaded: state.topLoaded,
            activeButton: state.activeButton
        };
    }
);
export const getLeftButtons = createSelector(
    getButtonState,
    state => {
        return {
            leftButtons: state.leftButtons,
            leftLoaded: state.leftLoaded
        };
    }
);
export const getTopButtons = createSelector(
    getButtonState,
    state => {
        return {
            topButtons: state.topButtons,
            topLoaded: state.topLoaded
        };
    }
);
export const getRightButtons = createSelector(
    getButtonState,
    state => {
        return {
            rightButtons: state.rightButtons,
            rightLoaded: state.rightLoaded
        };
    }
);
export const getBottomButtons = createSelector(
    getButtonState,
    state => {
        return {
            bottomButtons: state.bottomButtons,
            bottomLoaded: state.bottomLoaded
        };
    }
);

export const getActiveButton = createSelector(
    getButtonState,
    state => {
        return {
            activeButton: state.activeButton
        };
    }
);

// Reducers
export function button_reducer(state: ButtonState = initialButtonState, action: buttonActions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case buttonActions.ActionTypes.GET_BUTTONS:
            return {
                    ...state,
                    leftButtons: state.leftButtons,
                    rightButtons: state.rightButtons,
                    bottomButtons: state.bottomButtons,
                    topButtons: state.topButtons
                };
        case buttonActions.ActionTypes.GET_LEFTBUTTONS:
            return {
                    ...state,
                    leftButtons: state.leftButtons
                };
        case buttonActions.ActionTypes.GET_RIGHTBUTTONS:
            return {
                    ...state,
                    rightButtons: state.rightButtons
                };
        case buttonActions.ActionTypes.GET_BOTTOMBUTTONS:
            return {
                    ...state,
                    bottomButtons: state.bottomButtons
                };
        case buttonActions.ActionTypes.LOAD_LEFT_BUTTONS:
                return {
                    ...state
                };
        case buttonActions.ActionTypes.LOAD_LEFT_BUTTONS_SUCCES:
                return {
                    ...state,
                    leftButtons: action.payload,
                    leftLoaded: true
                };
        case buttonActions.ActionTypes.LOAD_RIGHT_BUTTONS:
                return {
                    ...state
                };
        case buttonActions.ActionTypes.LOAD_RIGHT_BUTTONS_SUCCES:
                return {
                    ...state,
                    rightButtons: action.payload,
                    rightLoaded: true
                };
        case buttonActions.ActionTypes.LOAD_TOP_BUTTONS:
                return {
                    ...state
                };
        case buttonActions.ActionTypes.LOAD_TOP_BUTTONS_SUCCES:
                return {
                    ...state,
                    topButtons: action.payload,
                    topLoaded: true
                };
        case buttonActions.ActionTypes.SET_ACTIVE_BUTTON:
                return {
                    ...state,
                    activeButton: action.payload
                };
        case buttonActions.ActionTypes.CLEAR_ACTIVE_BUTTON:
                return {
                    ...state,
                    activeButton: null
                };
        default :
            return state;
    }
}
