import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DocumentItem, LabelType } from "@merkator/merkator-documents";
import { HttpService } from "../../../services/app-utililty-services/http.service";
import { forkJoin } from "rxjs";

declare var Tiff: any;


@Component({
    selector: 'app-merkator-documents',
    templateUrl: './merkator-documents.component.html',
    styleUrls: ['./merkator-documents.component.scss']
  })
  export class MerkatorDocumentsComponent implements OnInit {
    public documents: DocumentItem[] = [];
    public buttonLabel: LabelType[] = [LabelType.ICON, LabelType.NAME]

    constructor(
      private httpService: HttpService,
      @Inject(MAT_DIALOG_DATA) public data: any
    ){}
  
    ngOnInit(): void {
      console.log('DOCUMENT', this.data);
      const documents = []
      const calls = [];
      this.data.forEach((image) => {
        const body = {id: image.id};
        calls.push(this.httpService.postArrayBufferResponse( this.httpService.apiUrl + 'document/GetPDF', body));  
      });
      forkJoin(calls).subscribe({
        next: (results: BlobPart[]) => {
          results.forEach((result, idx) => {
            const documentType = this.getDocumentType(this.data[idx]);
            let file = new Blob([result], {type: documentType.mimeType});
            if (documentType.mimeType === 'image/tiff'){
              const t = new Tiff({buffer: result});
              const canvas = t.toCanvas();
              const fileURL = canvas.toDataURL('image/png');
              documents.push({
                url: fileURL,
                type: documentType.displayType,
                name: this.data[idx].filename
              });
            } else {
              const fileURL = URL.createObjectURL(file);
              documents.push({
                  url: fileURL,
                  type: documentType.displayType,
                  name: this.data[idx].filename
                });
              }
          })
          this.documents = [...documents];
        },
      error: (error) => console.log(error)
      });
    }

    private getDocumentType(data: any): {displayType: string; mimeType: string} {
      const filename = data.filename;
      const ext = filename.split('.').pop() ?? '';
      let result = {displayType: 'overig', mimeType: 'application/txt'};
      switch(ext) {
        case 'jpg':
        case 'jpeg':
        case 'img':
        case 'png':
        case 'gif':
          result = {displayType: 'image', mimeType: 'application/' + ext};
          break;
        case 'tiff':
        case 'tif':
          result = {displayType: 'image', mimeType: 'image/tiff'};
          break;
        case 'pdf':
          result = {displayType: 'pdf', mimeType: 'application/pdf'};
          break;
      }
      return result;
    }
  }