import * as themeActions from './../../actions/theme-actions/theme.actions';
import * as fromRoot from '../../state-interface/app.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export interface ThemeState extends fromRoot.AppState {
    old_theme: string;
    current_theme: string;
}

const initialThemeState: ThemeState = {
    old_theme : null,
    current_theme : 'light-theme'
};

const getThemeState = createFeatureSelector<ThemeState>('theme');

export const getTheme = createSelector(
    getThemeState,
    state => {
        return {
            current_theme : state.current_theme,
            old_theme : state.old_theme
        };
    }
);

export function reducer(state: ThemeState = initialThemeState, action: themeActions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case themeActions.ThemeActionTypes.GET_CURRENT_THEME : {
            return {
                    ...state
                };
        }
        case themeActions.ThemeActionTypes.CHANGE_THEME: {
            return {
                ...state,
                old_theme: state.current_theme,
                current_theme : action.payload
            };
        }
        default : {
            return state;
        }
    }
}
