import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MainMenu } from '../../../classes-types/main.menu';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import { ComponentmanagerService } from '../../../services/app-state-services/componentmanager.service';
import * as BottomSheetActions from '../../../actions/bottomsheet-actions/bottomsheet.actions';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() menuObject: Array<MainMenu> = [];
  @ViewChild('childMenu', {static: true}) public childMenu;

  JSON: JSON = JSON;

  constructor(private store: Store<AppState>, private compManager: ComponentmanagerService, public buttonMethod: ButtonmethodService) { }

  ngOnInit(): void {
    // console.log(this.menuObject);
  }

  onItemClick(menuItem: any, referenceMenu: any): void {
    this.buttonMethod.buttonCreateMyComponent(menuItem, false);
  }

}
