import { Component, OnInit } from '@angular/core';
import { AppState } from '../../../state-interface/app.state';
import { Store, select } from '@ngrx/store';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import * as merkatorMapState from '../../../reducers/merkator-map-reducer/merkator-map.reducer';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';

@Component({
  selector: 'app-featurelist',
  templateUrl: './featurelist.component.html',
  styleUrls: ['./featurelist.component.scss']
})
export class FeaturelistComponent implements OnInit {

  constructor(public store: Store<AppState>, public mapService: MerkatorMapService) { }

  layerArray = [];
  featureArray = [];
  oneLayer: boolean;

  ngOnInit(): void {
    this.store.pipe(select(merkatorMapState.getSelectedFeatures)).subscribe(features => {
      // this.featureArray = features.merkatorMapSelectedFeatures;
      this.layerArray = Object.keys(features.merkatorMapSelectedFeatures);
      this.featureArray = Object.values(features.merkatorMapSelectedFeatures);
      if ( this.layerArray.length === 1 ) {
        if (this.featureArray[0].length <= 1 ) {
          this.oneLayer = true;
        } else {
          this.oneLayer = false;
        }
      } else {
        this.oneLayer = false;
      }
    });
  }


}
