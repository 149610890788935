<ng-container *ngIf="showGrid">


    <!-- voor positionering grid *ngIf-->
    <div *ngIf="!showDetail" style="position: absolute; top: 4px; float:right; padding-right: 30px; flex:auto;">
        <!--
        <button mat-button
            (click)="gridService.updateButtons($event,'preheaderpanel', null)">
            <i class="fa fa-times"></i>
            Close Grouping
        </button>
        -->

        <button mat-button style="margin: 3px; color: black !important;">
            {{ 'grid.grouping' | translate }}
            <mat-slide-toggle [color]="'accent'"
                [checked]="false"
                (change)="myToggleChangeGroup($event);"
            >
            </mat-slide-toggle>
        </button>

        <!-- Showgrouping  -->
        <button mat-button  *ngIf="showGrouping" style="margin: 3px; color: black !important;"
            (click)="gridService.updateButtons($event,'cleargrouping', null)">
            <i class="fa fa-times"></i>
            {{ 'grid.cleargroups' | translate }}
        </button>
        <button mat-button *ngIf="showGrouping" style="margin: 3px;color: black !important;"
            (click)="gridService.updateButtons($event,'expandallgroups', null)">
            <i class="fa fa-expand"></i>
            {{ 'grid.expandgroups' | translate }}
        </button>
        <button mat-button *ngIf="showGrouping" style="margin: 3px;color: black !important;"
            (click)="gridService.updateButtons($event,'collapseallgroups', null)">
            <i class="fa fa-compress"></i>
            {{ 'grid.colapsgroups' | translate }}
        </button>
        <!---->


        <button mat-button style="margin: 3px;color: black !important;"
            (click)="gridService.updateButtons($event,'clearsorting', null)">
            <i class="fa fa-unsorted"></i>
            {{ 'grid.clearsorting' | translate }}
        </button>
        <button mat-button style="margin: 3px;color: black !important;"
            (click)="gridService.updateButtons($event,'clearfilters', null)">
            <i class="fa fa-filter"></i>
            {{ 'grid.clearfilters' | translate }}
        </button>
        <!--
        <button mat-button style="margin: 3px;color: black !important;"
            (click)="gridService.updateButtons($event,'filterrow', null)">
            <i class="fa fa-random"></i>
            Filter Row
        </button>
        -->
        <!--
        <button mat-button style="margin: 3px;"
            (click)="gridService.updateButtons($event,'toppanel', null)">
            <i class="fa fa-bars"></i>
            Toppanel
        </button>
        -->
        <button mat-button style="margin: 3px;color: black !important;"
            (click)="gridService.updateButtons($event,'sidepanel', null)">
            <i class="fa fa-bars"></i>
            {{ 'grid.sidepanel' | translate }}
        </button>
        <button mat-button style="margin: 3px;color: black !important;"
            (click)="openStateSettings($event);">
            <i class="fa fa-cogs"></i>
            {{ 'grid.saveload' | translate }}
        </button>
        <button mat-button style="margin: 3px;color: black !important;"
            (click)="reloadGrid();">
            <i class="fa fa-refresh"></i>
            {{ 'grid.reloadgrid' | translate }}
        </button>
        <!--<button mat-button style="margin: 3px;color: black !important;"
            (click)="exportToShp();">
            <i class="fa fa-refresh"></i>
            TMP EXPORT 2 SHP
        </button>-->
    </div>

    <button [disableRipple]="true" *ngIf="!showDetail && activeRows != null && activeRows != undefined" mat-button style="cursor: default; margin: 4px; font-weight: bold; position: absolute; right: 220px; top: 0px; float: right; font-weight: 500;
    font-size: 13px;">
          {{this.gridInput.gridConfiguration.name}}
    </button>
    <button [disableRipple]="true" *ngIf="!showDetail && activeRows != null && activeRows != undefined" mat-button style="cursor: default; margin: 4px; position: absolute; right: 80px; top: 0px; float: right; font-weight: 500;
    font-size: 13px;">
       {{ 'grid.activerows' | translate }}: {{activeRows}}
    </button>

    <div [hidden]="showDetail" *ngIf="showGrid">

        <merkator-grid
            [id]="'grid1'"
            [gridInput]="gridInput"
            [gridSettings]="gridSettings"
            (mapEvent)="geometryColumnhandler($event)"
            (detailInfoEvent)="openDetailPane($event)"
            [item]="item"
            [buttons]="gridService.button"
            (gridInfoEvent)="updateMetrics($event)"
            (buttonEvent)="buttonEvent($event)"
            (customEvent)="parseCustomEvent($event)">
        </merkator-grid>

    </div>

    <ng-container *ngIf="showDetail">

        <!-- NEW GRID TAB IMPLEMENTATIE  -->
        <merkator-tab-library *ngIf="detailGridConfig"
            [initGridConfig]="detailGridConfig"
            [detailObject]="detailObject"
            [customHttpHeaders]="httpHeaders"
            [matGroupHeight]="gridService.height + 'px'"
            (backToGrid)="merkatorTabEmitter($event)"
            [newDetailObject]="newObjectInDetail"
            [currentSrid]="'3163'"
            [geometryChangedEvent]="geometryChangedEvent"
            [canvasReportEventEmitter]="mapService.canvasReportEventEmitter"
            [buttonRights]="rights">
        </merkator-tab-library>



    </ng-container>

</ng-container>
