import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { EMPTY, Observable, EmptyError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import * as buttonActions from '../actions/button-actions/button.actions';
import * as mapActions from '../actions/map-actions/merkator-map.actions';
import * as snackbarActions from '../actions/snackbar-actions/snackbar.actions';
import { ApplicationService } from '../services/app-state-services/application.service';
import { Action } from '@ngrx/store';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class ButtonEffects {
    constructor(private action$: Actions, private applicatieService: ApplicationService) { }

    loadLeftButtonsEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(buttonActions.ActionTypes.LOAD_LEFT_BUTTONS),
        mergeMap(() => this.applicatieService.getMainMenu('leftpanel')
                            .pipe(
                                map(buttons => (new buttonActions.LoadLeftButtonsSucces(buttons))),
                                catchError(() => {
                                    console.log('Error');
                                    return EMPTY;
                                })
                            )
        ))
    );

    loadRightButtonsEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(buttonActions.ActionTypes.LOAD_RIGHT_BUTTONS),
        mergeMap(() => this.applicatieService.getMainMenu('rightpanel')
                            .pipe(
                                map(buttons => (new buttonActions.LoadRightButtonsSucces(buttons))),
                                catchError(() => {
                                    console.log('Error');
                                    return EMPTY;
                                })
                            )
        ))
    );

    loadTopButtonsEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(buttonActions.ActionTypes.LOAD_TOP_BUTTONS),
        mergeMap(() => this.applicatieService.getMainMenu('top')
                            .pipe(
                                map(buttons => (new buttonActions.LoadTopButtonsSucces(buttons))),
                                catchError(() => {
                                    console.log('Error');
                                    return EMPTY;
                                })
                            )
        ))
    );

    /*
    loadLeftButtonSuccesEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
            ofType(buttonActions.ActionTypes.GET_LEFTBUTTONS),
            map(value =>  new snackbarActions.SnackbarOpen({
                message: 'snackbar.succes'
                // config: { } as MatSnackBarConfig
              })
            ))
    );
    */
}
