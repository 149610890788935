import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef,
         ComponentRef, OnChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CustomDynamicComponent } from '../classes-types/dynamic-comp.types';
import { ComponentmanagerService } from '../services/app-state-services/componentmanager.service';

@Directive({
    selector: '[dynamicComponent]'
})

export class DynamicComponentDirective implements OnInit, OnChanges, OnDestroy {

    @Input() desiredComponent;
    @Input() params: any;

    ComponentReference: ComponentRef<CustomDynamicComponent>;

    constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef,
                public compManager: ComponentmanagerService, public refresh: ChangeDetectorRef) {}

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        if (this.ComponentReference === undefined) {
            this.createComponent();
        } else {
            this.ComponentReference.destroy();
            this.createComponent();
        }
    }

    ngOnDestroy(): void {
        this.ComponentReference.destroy();
    }

    createComponent(): void {
        const factory = this.resolver.resolveComponentFactory<CustomDynamicComponent>(this.desiredComponent);
        this.ComponentReference = this.container.createComponent(factory) as ComponentRef<CustomDynamicComponent>;
        Object.assign(this.ComponentReference.instance, this.params);
    }
}
