import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import * as menuActions from '../actions/menu-actions/menu.actions';
import * as snackbarActions from '../actions/snackbar-actions/snackbar.actions';
import { ApplicationService } from '../services/app-state-services/application.service';
import { Action } from '@ngrx/store';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { MainMenu } from '../classes-types/main.menu';

@Injectable()
export class MenuEffects {
    constructor(private action$: Actions, private applicatieService: ApplicationService) { }

    loadToolbarMenuConfiguraties$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(menuActions.MenuTypes.LOAD_MENU),
        mergeMap(() => this.applicatieService.getMainMenu('navbar')
                            .pipe(
                                map(menuObjects => (new menuActions.SuccesLoadMenu(menuObjects))),
                                catchError(() => {
                                    console.log('Error');
                                    return EMPTY;
                                })
                            )
        ))
    );

    /*
    loadMenuEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
            ofType(menuActions.MenuTypes.SUCCES_LOAD_MENU),
            map(value =>  new snackbarActions.SnackbarOpen({
                message: 'snackbar.succes'
                // config: { } as MatSnackBarConfig
              })
            ))
    );
    */
}
