<mat-menu #childMenu="matMenu">

    <span *ngFor="let child of menuObject">
      <ng-container *ngIf="child?.rights?.view">
      <!-- Handle branch node menu items -->
      <span *ngIf="child.children && child.children.length > 0">
        <button (click)="onItemClick(child, childMenu)" mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
          <!--<mat-icon>{{child.icon}}</mat-icon>-->
          <span>{{child.name}}</span>
        </button>
        <app-menu-item #menu [menuObject]="child.children"></app-menu-item>
      </span>
      <!-- Handle leaf node menu items -->
      <span *ngIf="!child.children || child.children.length === 0">
        <button mat-menu-item (click)="onItemClick(child, childMenu)">
          <!--<mat-icon>{{child.icon}}</mat-icon>-->
          <span>{{child.name}}</span>
        </button>
      </span>
     </ng-container>
    </span>
  </mat-menu>
