import { Action } from '@ngrx/store';

// Enum -- Constants voor Action types
export enum ThemeActionTypes {
    GET_CURRENT_THEME = '[Theme] get current theme',
    CHANGE_THEME = '[Theme] changed'
}

// ChangeThemeAction om thema te krijgen
export class GetThemeAction implements Action {
    readonly type = ThemeActionTypes.GET_CURRENT_THEME;
}

// ChangeThemeAction om thema te wijzigen
export class ChangeThemeAction implements Action {
    readonly type = ThemeActionTypes.CHANGE_THEME;
    constructor(public payload: string) { }
}


// Export alle actions onder 1 type
export type ActionType = ChangeThemeAction | GetThemeAction;

