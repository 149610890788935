import * as actions from '../../actions/demo-actions/demo.actions';
import * as fromRoot from '../../state-interface/app.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { state } from '@angular/animations';

// State
export interface DemoStates extends fromRoot.AppState {
    [demoStateId: string]: DemoState;
}

export interface DemoState {
    name: string;
}

// InitialState
const initialDemoState: DemoStates = {

};

// Selectors
const getDemoState = createFeatureSelector<DemoStates>('demo');

export const getDemo = createSelector(
    getDemoState,
    state => {
        return state;
    }
);

export const getDemoById = (id) => createSelector(
    getDemoState,
    state => {
        return state[id];
    }
);

// Reducers
export function demo_reducer(state: DemoStates = initialDemoState, action: actions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case actions.ActionTypes.CREATE_COMPONENT : {
            const id = action.meta.id;
            return {
                ...state,
                [id] : {
                    ...state[id],
                    name: state.name
                }
            };
        }
        case actions.ActionTypes.DESTROY_COMPONENT: {
            const id = action.id;
            delete state[id];
            return {
                ...state
            };
        }
        case actions.ActionTypes.GET_NAME : {
            return {
                ...state
            };
        }
        case actions.ActionTypes.SET_NAME : {
            const id = action.meta.id;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    name: action.payload
                }
            };
        }
        default : {
            return state;
        }
    }
}
