import { Action } from '@ngrx/store';
import { Type } from '@angular/core';
import { CustomDynamicComponent } from '../../classes-types/dynamic-comp.types';
import { Components } from '../../services/app-state-services/componentmanager.service';

export enum MatModalTypes {
    MATMODAL_OPEN = '[MatModal] matmodal open',
    MATMODAL_CLOSE = '[MatModal] matmodal close'
}

export class MatModalPayload {
  title: string;
  component: Components;
  params: any;
}

export class MatModalOpen implements Action {
  readonly type = MatModalTypes.MATMODAL_OPEN;
  constructor(public payload: MatModalPayload) { }
}

export class MatModalClose implements Action {
  readonly type = MatModalTypes.MATMODAL_CLOSE;
}

export type MatModalAction = MatModalOpen | MatModalClose;
