<!-- Material theme menu voor mobiel-->
<button matTooltip="{{ 'tooltip.settings' | translate }}" #tooltip="matTooltip" *ngIf="mobile" mat-menu-item [matMenuTriggerFor]="setting_menu">
        <mat-icon>
                settings
        </mat-icon>
    <!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

<button *ngIf="mobile" mat-menu-item [matMenuTriggerFor]="helpmenu">
        <mat-icon>
                help
        </mat-icon>
    <!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

        <!-- Material theme menu -->
<button *ngIf="mobile && appConfigArray.length > 1" mat-menu-item [matMenuTriggerFor]="change_app">
    <mat-icon>compare_arrows</mat-icon>
</button>

<button *ngIf="mobile && showOrganisations" mat-menu-item [matMenuTriggerFor]="change_org">
    <mat-icon>
            sd_card
    </mat-icon>
<!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

<mat-menu #change_app="matMenu">
    <ng-container *ngFor="let appconf of appConfigArray">
            <button (click)="changeApp(appconf)" mat-menu-item >
                     <span>{{appconf.name}}</span>
            </button>
    </ng-container>
</mat-menu>

<button [disableRipple]="true" mat-button *ngIf="!mobile" style="border: none; outline: none; background-color: transparent; margin-right: 20px;padding-left: 0px;">

<app-merkator-googlelocator *ngIf="!mobile" >

</app-merkator-googlelocator>

</button>





<!-- Material theme menu voor niet mobiel -->
<button matTooltip="{{ 'tooltip.settings' | translate }}" #tooltip="matTooltip" *ngIf="!mobile" mat-button [matMenuTriggerFor]="setting_menu">
        <mat-icon>
                settings
        </mat-icon>
    <!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

<!-- Material theme menu -->
<button matTooltip="{{ 'tooltip.questions' | translate }}" #tooltip="matTooltip" *ngIf="!mobile" mat-button [matMenuTriggerFor]="helpmenu">
        <mat-icon>
                help
        </mat-icon>
    <!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

<!-- Material theme menu -->
<button matTooltip="{{ 'tooltip.switchwithinorg' | translate }}" #tooltip="matTooltip" *ngIf="!mobile && appConfigArray.length > 1" mat-button [matMenuTriggerFor]="change_app">
        <mat-icon>
                compare_arrows
        </mat-icon>
    <!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

<!-- Material theme menu -->
<button matTooltip="{{ 'tooltip.switchorg' | translate }}" #tooltip="matTooltip" *ngIf="!mobile && showOrganisations" mat-button [matMenuTriggerFor]="change_org">
        <mat-icon>
                sd_card
        </mat-icon>
    <!--{{ 'mainapp.buttons.settings' | translate}}-->
</button>

<!-- release notes, help, about merkator, pdf met baseline-->
<mat-menu #helpmenu="matMenu">

    <button (click)="whatsNew();" mat-menu-item>
        <mat-icon>new_releases</mat-icon>
        {{ 'toolbar.settings.whatsnew' | translate }}
    </button>
    <button (click)="openSupport();" mat-menu-item>
        <mat-icon>contact_support</mat-icon>
        {{ 'toolbar.settings.support' | translate }}
    </button>
    <button (click)="openBaseline();" mat-menu-item>
        <mat-icon>policy</mat-icon>
        {{ 'toolbar.settings.baseline' | translate }}    
    </button>
    <button (click)="openMerkator();" mat-menu-item>
        
        <!-- add merkator image -->
        <img style="margin-top: 7px; margin-right: 10px;" src="../../../../assets/merkatorlogo.png" height="24" width="24">
        {{ 'toolbar.settings.about' | translate }}
    </button>

</mat-menu>

<!--
"usermanagement": "Usermanagement",
"whatsnew": "What's new?",
"theme": "Colortheme",
"language": "Language",
"about": "About Merkator",
"support": "Contact support",
"baseline": "Download baseline"
-->

<mat-menu #change_org="matMenu">
    <mat-form-field (click)="$event.stopPropagation()" style="max-width: 80%;padding-left: 10px;">
        <input #input matInput placeholder="Filter" value="" (keyup)="filterMyArray(input.value);">
    </mat-form-field>
    <ng-container *ngFor="let organisation of showArray;">
            <button (click)="changeOrg(organisation.id)" mat-menu-item >
                     <span>{{organisation.name}}</span>
            </button>
    </ng-container>
</mat-menu>


<mat-menu #setting_menu="matMenu">
        
        <!-- Logging menu -->
        <button *ngIf="appService.hasAdminPower" (click)="openLogGrid();" mat-menu-item>
            <mat-icon>assignment</mat-icon>
            {{ 'toolbar.settings.logging' | translate }}
        </button>

        <!-- Language menu -->
        <button mat-menu-item [matMenuTriggerFor]="menu_language">
            <mat-icon>language</mat-icon>
            {{ 'toolbar.settings.language' | translate }}
        </button>
        <mat-menu #menu_language="matMenu">
            <ng-container *ngFor="let language of availableLanguages">
                <button (click)="changeLanguage(language)" mat-menu-item
                    [disabled]="currentActiveLanguage == language ? true : false">
                    <flag-icon country="{{language}}"></flag-icon>
                    {{language | uppercase}}
                </button>
            </ng-container>
        </mat-menu>
            
        <!-- Material theme menu -->
        <button mat-menu-item [matMenuTriggerFor]="menu_palette">
            <mat-icon>palette</mat-icon>
            {{ 'toolbar.settings.theme' | translate }}
        </button>
        <mat-menu #menu_palette="matMenu">
            <button (click)="changeTheme('light-theme')" mat-menu-item [disabled]="theme == 'light-theme' ? true : false">
                <span>{{ 'toolbar.settings.lighttheme' | translate }}
                </span>
            </button>
            <button (click)="changeTheme('dark-theme')" mat-menu-item [disabled]="theme == 'dark-theme' ? true : false">
                <span>{{ 'toolbar.settings.darktheme' | translate }}</span>
            </button>
        </mat-menu>

        <!-- Account theme menu -->
        <button *ngIf="appService.hasAdminPower" mat-menu-item [matMenuTriggerFor]="account_">
            <mat-icon >account_box</mat-icon>
            {{ 'toolbar.settings.usermanagement' | translate }}
        </button>
        <mat-menu #account_="matMenu">
            <button (click)="openUserManagement()" mat-menu-item >
                <span>{{ 'toolbar.settings.usermanagement' | translate }}</span>
            </button>
        </mat-menu>
        <!-- Account theme menu -->
        <button (click)="logoffAndRevokeTokens()" mat-menu-item>
            <mat-icon >logout</mat-icon>
            {{ 'toolbar.settings.logout' | translate }}
        </button>




</mat-menu>