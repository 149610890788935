import { Component, OnInit } from '@angular/core';
import { LocatieServiceProxyConfig, LocationServiceTypes } from '@merkator/merkator-map';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import { HttpService } from '../../../services/app-utililty-services/http.service';

@Component({
  selector: 'app-merkator-googlelocator',
  templateUrl: './merkator-googlelocator.component.html',
  styleUrls: ['./merkator-googlelocator.component.scss']
})
export class MerkatorGooglelocatorComponent implements OnInit {

  constructor(public mapService: MerkatorMapService, public _apihandler: HttpService) { }

  suggesties: Array<{ id: string, weergavenaam: string, service: string }>;
  selected: { id: string, weergavenaam: string, service: string };
  proxyConfig: LocatieServiceProxyConfig[] = [{ replacing: LocationServiceTypes.CRAB, 
                                                proxyLookupUrl: this._apihandler.apiUrl + 'proxy/crab/lookup?q=',
                                                proxySuggestionUrl: this._apihandler.apiUrl + 'proxy/crab/suggestion?q='
                                              },
                                              { replacing: LocationServiceTypes.WALLONIE, 
                                                proxyLookupUrl: '',
                                                proxySuggestionUrl: this._apihandler.apiUrl + 'proxy/wallonie/suggestion/'
                                              }];

  ngOnInit(): void {
  }


}
