import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of} from 'rxjs';
import { map, tap, switchMap, exhaustMap } from 'rxjs/operators';
import * as matModalActions from '../actions/matmodal-actions/matmodal.actions';
import { Action } from '@ngrx/store';
import { MatDialog, MatDialogActions } from '@angular/material/dialog';
import { MatmodalComponent } from '../components/app-utility-components/matmodal/matmodal.component';

@Injectable()
export class MatModalEffects {

 constructor(private actions: Actions, public dialog: MatDialog) {
 }

  closeSnackBar$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(matModalActions.MatModalTypes.MATMODAL_CLOSE),
            tap(() => this.dialog.closeAll()),
        ),
        { dispatch: false }
  );

  showDialog$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(matModalActions.MatModalTypes.MATMODAL_OPEN),
            exhaustMap((action: any) => {
                const dialogRef = this.dialog.open(MatmodalComponent, {
                    data: action.payload,
                    disableClose: true
                });
                return dialogRef.afterClosed();
            }),
            map((result: any) => {
                if (result === undefined) {
                return new matModalActions.MatModalClose();
                }
                return new matModalActions.MatModalClose();
            }),
        )
  );

}
