<app-menu-toolbar></app-menu-toolbar>


  <mat-sidenav-container [hasBackdrop]="false" [autosize]="true">

  <!-- inhoud van de sidenav links -->
  <mat-sidenav mwlResizable [enableGhostResize]="true" (click)="$event.stopPropagation();" [disableClose]="true"
               [ngClass]="{'example-cropped': compManager.leftSideComponent, 'example-full': !compManager.leftSideComponent}"
    [resizeEdges]="{bottom: false, right: true, top: false, left: false}" style="max-width: 80vw; pointer-events: none;" (resizeEnd)="onResizeEnd($event)" position="start" #leftpanel [autoFocus]="false">



    <div style="z-index: 20; position: absolute; height: 100%; width: 9px; right: 0px; cursor: col-resize; pointer-events: auto;" mwlResizeHandle
    [resizeEdges]="{ right: true }">
    </div>

    <div id="leftsidenavwidth" style="height: 0px; padding-bottom: 5px;"></div>

    <div style="height: 30px; text-align: right; width: 100%; position: absolute; top: 20px; right: 15px; z-index: 1000; pointer-events: auto;">
      <button mat-icon-button (click)="customLeftPanelClose(leftpanel)">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div style="pointer-events: auto; max-height: 100vh; overflow: auto;">
      <ng-container *ngIf="compManager.leftSideComponent" [desiredComponent]="compManager.leftSideComponent" [params]="compManager.leftSideParams" dynamicComponent></ng-container>
    </div>
  </mat-sidenav>

  <!-- inhoud van de sidenav rechts-->
  <mat-sidenav mwlResizable [disableClose]="true" tabindex="-1"  [ngClass]="{'example-cropped': compManager.rightSideComponent, 'example-full': !compManager.rightSideComponent}"
    [enableGhostResize]="true"
    [resizeEdges]="{bottom: false, right: false, top: false, left: true}" style="max-width: 80vw; z-index: 99999999999; position: absolute;"
    (resizeEnd)="onResizeEnd($event)" position="end" #rightpanel
    [autoFocus]="false">


    <div style="z-index: 20; position: absolute; height: 100%; width: 9px; left: 0px; cursor: col-resize;" mwlResizeHandle
    [resizeEdges]="{ left: true }">
    </div>

  <div id="rightsidenavwidth" style="height: 0px; padding-bottom: 5px; pointer-events: auto;">

      <div style="height: 30px; position: absolute; right: 15px; top: 20px; z-index: 1000; pointer-events: auto;">
      <button mat-icon-button (click)="closeRightSidePanel(rightpanel);">
        <mat-icon>close</mat-icon>
      </button>
      </div>
  </div>

  <div style="pointer-events: auto; max-height: 100vh; overflow: auto;">
    <ng-container *ngIf="compManager.rightSideComponent" [desiredComponent]="compManager.rightSideComponent" [params]="compManager.rightSideParams" dynamicComponent>
  </ng-container>
  </div>

  </mat-sidenav>

  <!-- material sidenav content ( wat er onder sidenav zit )-->
  <mat-sidenav-content>

    <!-- de main content -->
    <div style="height: 100%;" id="main">

      <!-- LEFTBUTTONS -->
      <app-buttoncontainer  [width]="'48px'" [eventSender]="buttonService.leftSideNavEvent" [sideNav]="sidenavleft" [buttons]="leftButtons" [initTop]="'5px'" [initLeft]="'5px'"></app-buttoncontainer>

      <!-- TOPBUTTONS -->
      <app-topbuttoncontainer [style.display]="viewportService.innerWidth > 959 ? null : 'none'" [rightEvent]="buttonService.rightSideNavEvent" [leftEvent]="buttonService.leftSideNavEvent" [leftSideNav]="sidenavleft" [rightSideNav]="sidenavright" [initTop]="'0px'" [initRight]="'5px'"></app-topbuttoncontainer>

      <!-- MOBILEBUTTONS -->
      <app-buttoncontainer [style.display]="viewportService.innerWidth > 960 ? 'none' : null" [mobile]="true" [sideNav]="sidenavright"  [buttons]="combinedButtons" [initTop]="'200px'" [initRight]="'5px'"></app-buttoncontainer>

      <!-- DE MAIN MAP-->
      <app-merkator-map-impl style="height: 100%; width: 100%; position: absolute; left: 0; top: 0"></app-merkator-map-impl>

    </div>

  </mat-sidenav-content>

  </mat-sidenav-container>
