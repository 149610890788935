import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthgaurdService } from './services/app-state-services/authgaurd.service';
import { AppComponent } from './app.component';
import { AppmainComponent } from './components/app-state-components/appmain/appmain.component';
import { AutoLoginComponent } from './components/app-state-components/auto-login/auto-login.component';
import { UnauthorizedComponent } from './components/app-state-components/unauthorized/unauthorized.component';


const routes: Routes = [
  { path: '', component: AppComponent},
  { path: 'app', component: AppmainComponent},
  { path: 'autologin', component: AutoLoginComponent},
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: '**', component: UnauthorizedComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: 'disabled' } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
