import { Injectable, ApplicationRef } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of} from 'rxjs';
import { map, tap, switchMap, exhaustMap } from 'rxjs/operators';
import * as bottomSheetActions from '../actions/bottomsheet-actions/bottomsheet.actions';
import { Action } from '@ngrx/store';
import { MatbottomsheetComponent } from '../components/app-utility-components/matbottomsheet/matbottomsheet.component';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { ComponentmanagerService } from '../services/app-state-services/componentmanager.service';

@Injectable()
export class MatBottomSheetEffects {

 constructor( private actions: Actions, private matBottom: MatBottomSheet, public refresh: ApplicationRef
            , public compmanager: ComponentmanagerService ) {
 }


  closeSnackBar$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(bottomSheetActions.BottomSheetTypes.BOTTOMSHEET_CLOSE),
            tap(() => this.matBottom.dismiss()),
        ),
        { dispatch: false }
  );


  showMatBottomSheet$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(bottomSheetActions.BottomSheetTypes.BOTTOMSHEET_OPEN),
            exhaustMap((action: any) => {
                // this.compmanager.bottomSheetParams = action.payload;
                const dialogRef = this.matBottom.open(MatbottomsheetComponent, {
                    data: action.payload,
                    hasBackdrop: false,
                    closeOnNavigation: false,
                    panelClass: 'bottom-sheet-container',
                    autoFocus: false
                });
                this.refresh.tick();
                return dialogRef.afterDismissed();
            }),
            map((result: any) => {
                if (result === undefined) {
                return new bottomSheetActions.BottomSheetClose();
                }
                return new bottomSheetActions.BottomSheetClose();
            }),
        )
  );

}
