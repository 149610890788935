<div style="min-height: 60%; width: 400px; overflow-y: auto;">
    <mat-form-field>
        <input #input matInput placeholder="Filter" value="" (keyup)="filterMyArray(input.value);">
    </mat-form-field>

    <div style="max-height: 400px; overflow-y: auto;">
    <ng-container *ngFor="let org of showArray">

        <div style="padding: 5px;">
        <button mat-stroked-button (click)="onChooseOrganisation(org.id, org.name);">
            {{org.name}}
        </button>
        </div>

    </ng-container>
    </div>    
</div>