import * as actions from '../../actions/grid-actions/merkator-grid.actions';
import * as fromRoot from '../../state-interface/app.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Column, GridOption, Editors, Formatters } from 'angular-slickgrid';

// State
export interface GridActiveState extends fromRoot.AppState {
    currentActiveGrid: number;
}
// State
export interface GridState extends fromRoot.AppState {
    gridObject: any;
    loaded: boolean;
}

export interface GridHeightState extends fromRoot.AppState {
    gridHeight: actions.GridHeight;
}

// InitialState
const initialGridActiveState: GridActiveState = {
    currentActiveGrid: null
};

const initialGridState: GridState = {
    gridObject: null,
    loaded: false
};

const initialGridHeight: GridHeightState = {
    gridHeight: { height: 400, sideEffect: 'refresh', oldHeight: null }
};

// Selectors
const getGridActiveState = createFeatureSelector<GridActiveState>('gridactive');
const getGridState = createFeatureSelector<GridState>('grid');
const getGridHeightState = createFeatureSelector<GridHeightState>('gridheight');

export const getGridActive = createSelector(
    getGridActiveState,
    state => {
        return {
            gridactive: state
        };
    }
);

export const getGrid = createSelector(
    getGridState,
    state => {
        return {
            grid: state
        };
    }
);

export const getGridHeight = createSelector(
    getGridHeightState,
    state => {
        return {
            gridHeight: state
        };
    }
);

// Reducers
export function grid_reducer(state: GridActiveState = initialGridActiveState, action: actions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case actions.ActionTypes.RESET_GRID : {
            return {
                ...state,
                currentActiveGrid: null
            };
        }
        case actions.ActionTypes.SET_GRID_HEIGHT : {
            return {
                ...state,
                height: action.payload
            };
        }
        case actions.ActionTypes.LOAD_GRID_FULL : {
            return {
                ...state,
                currentActiveGrid: action.payload
            };
        }
        default : {
            return state;
        }
    }
}

// Reducers
export function grid_height_reducer(state: GridHeightState = initialGridHeight, action: actions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case actions.ActionTypes.SET_GRID_HEIGHT : {
            return {
                ...state,
                gridHeight: action.payload
            };
        }
        default : {
            return state;
        }
    }
}

export function grid_reducer_object(state: GridState = initialGridState, action: actions.ActionType ): fromRoot.AppState {
    switch (action.type) {
        case actions.ActionTypes.RESET_GRID : {
            return {
                ...state,
                gridObject: null,
                loaded: false
            };
        }
        case actions.ActionTypes.LOAD_GRID_FULL_SUCCESS : {
            return {
                ...state,
                gridObject: action.payload,
                loaded: true
            };
        }
        default : {
            return state;
        }
    }
}
