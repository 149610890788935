<div *ngIf="!mobile && buttons.length > 0" [style.display]="viewportService.innerWidth > 959 ? null : 'none'" class="transition"
    [ngStyle]="{'position': 'absolute', 'top': positionObject.top, 'left': positionObject.left, 'right': positionObject.right, 'bottom': positionObject.bottom }">
    
    <div style="float:right; width: 64px;">

    <ng-container *ngIf="sideNav.opened">
        <button (click)="closeNavbar()" mat-mini-fab color="primary" style="margin: 3px;">
            <mat-icon>
                keyboard_arrow_left
            </mat-icon>
        </button>
    </ng-container>
    <ng-container *ngIf="!sideNav.opened" >
        <button (click)="openNavbar()" mat-mini-fab color="primary" style="margin: 3px;">
            <mat-icon>
                keyboard_arrow_right
            </mat-icon>
        </button>
    </ng-container>

    <!--buttons-->
            <ng-container *ngFor="let button of buttons">
                <ng-container *ngIf="!button.children">
                    <button (click)="buttonService.buttonCreateMyComponent(button, false);" mat-mini-fab color="primary" style="margin: 3px;">
                        <ng-container>
                            <mat-icon>
                                {{button.icon}}
                            </mat-icon>
                        </ng-container>
                    </button>
                </ng-container>
                <div *ngIf="button.children" style="flex-direction: row; width: 100%;">
                    <app-expandablebutton [expandTo]="'left'" [button]="button"></app-expandablebutton>
                </div>
            </ng-container>
    </div>
</div>

<!-- MOBILE -->
<!-- DrawFinishedButtons -->
<div *ngIf="buttonService.drawfinished && mobile" style="position: absolute; top: 10px; right: 5px; z-index: 1000">
    <ng-container *ngFor="let button of buttonService.onDrawFinishedButtons">
        <button mat-mini-fab color="primary" style="margin: 3px;" (click)="buttonService.onDrawFinishMethods(button)">
            <mat-icon>
                {{button.icon}}
            </mat-icon>
        </button>
    </ng-container>
</div>

<!-- MOBILE -->
<div *ngIf="mobile && buttons.length > 0" class="transition_mob" style="position: absolute; top: 10px; left: 16px; float: left;">
    <div style="width: 40px; height: 52px;">
        <button (click)="ToggleShowMyChildren();" mat-mini-fab color="primary">
            <mat-icon>
                {{mobileMainButton.icon}}
            </mat-icon>
        </button>
        <mat-icon style="position: relative; left: 7px; bottom: 5px; ">
            arrow_drop_down
        </mat-icon>
    </div>

        <div @list *ngIf="showMyChildren">
            <button @items (click)="buttonService.putMapInteractionOff();" mat-mini-fab color="primary" style="margin-top: 5px;">
                <mat-icon>
                    close
                </mat-icon>
            </button>
            <div @items *ngFor="let button of buttons" style="margin-top: 5px;">

                <div *ngIf="!button.children" style="flex-direction: column;">
                    <button (click)="buttonService.buttonCreateMyComponent(button, true);" [ngClass]="button == activeButton ? 'activebutton' : ''" mat-mini-fab color="primary">
                        <ng-container>
                            <mat-icon>
                                {{button.icon}}
                            </mat-icon>
                        </ng-container>
                    </button>
                </div>
                <div *ngIf="button.children" style="flex-direction: row; width: 100%;">
                    <app-expandablebutton [button]="button" [mobile]="true"></app-expandablebutton>
                </div>
            </div>

        </div>
</div>

