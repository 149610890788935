import { Action } from '@ngrx/store';

// Enums
export enum ActionTypes {
    CREATE_COMPONENT = '[DEMO] Component created',
    DESTROY_COMPONENT = '[DEMO] Component destroyed',
    GET_NAME = '[DEMO] Get Name',
    SET_NAME = '[DEMO] Set Name',
}

export interface DemoMetadata {
    id: string;
}

// Actions
export class CreateComponent implements Action {
    readonly type = ActionTypes.CREATE_COMPONENT;
    constructor(public meta: DemoMetadata) { }
}

export class DestroyComponent implements Action {
    readonly type = ActionTypes.DESTROY_COMPONENT;
    constructor(public id: string) { }
}
export class GetName implements Action {
    readonly type = ActionTypes.GET_NAME;
}
export class SetName implements Action {
    readonly type = ActionTypes.SET_NAME;
    constructor(public payload: string, public meta: DemoMetadata) { }
}

// Export As 1 ActionType
export type ActionType = GetName | SetName | CreateComponent | DestroyComponent;
