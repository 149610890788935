import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';
import { style, MerkatorMapInteractionTypes, DragAndDropOptions, filter, equalsFilter, SetInteraction } from '@merkator/merkator-map';
import Feature from 'ol/Feature';
import { FeatureLike } from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { Subscription } from 'rxjs';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import RenderEvent from 'ol/render/Event';
import GeoJSON from 'ol/format/GeoJSON';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { Template } from '@merkator/merkator-mapprint-client/public-api';
import { MerkatorPrintService } from '../../../services/app-print-services/merkator-print.service';

@Component({
  selector: 'app-merkator-mapprint',
  templateUrl: './merkator-mapprint.component.html',
  styleUrls: ['./merkator-mapprint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MerkatorMapprintComponent implements OnInit, OnDestroy {

  constructor(
    public mapService: MerkatorMapService,
    private store: Store<AppState>,
    private printApi: MerkatorPrintService
  ) { }

  service: {
    templates: Template[]; // Templates are loaded from database and consits a name, a json file with the template and a json-file with values
    selectedTemplate: number // selected when opening the popup
  } = { templates: [ { name: 'empty', template: '', values: '' }], selectedTemplate: 0 };

  mapinfo: {
    layers: any[], // layers-object uit Merkator-Map
    view: any, // view-object uit Merkator-Map
  } = { layers: [], view: null };

  presets: any[] = []; // opgeslagen instellingen via saveEvent. Moet array zijn. Array mag leeg zijn.
  loaded: boolean = false;

  boxCenter: number[];
  featuresInsideBbox: { [key: string]: FeatureLike[] };
  objectKeys = Object.keys;
  subModifiedFeature: Subscription;
  subDragAndDropStarted: Subscription;
  allFilter: {[layer_key: string]: {[property: string]: filter[]}} = {};
  merkatorMapFallBackInteraction: SetInteraction;
  dragAndDropInteraction: SetInteraction = {type: MerkatorMapInteractionTypes.DragDrop, options: {layers: ['boxeventdrawlayer']} as DragAndDropOptions};

  ngOnDestroy(): void {
    this.mapService.mapRef.fallbackInteraction = this.merkatorMapFallBackInteraction;
    this.mapService.mapRef.setInteraction({type: MerkatorMapInteractionTypes.Off});
    this.subModifiedFeature.unsubscribe();
    this.deleteAllFilters();
    if (this.subDragAndDropStarted && !this.subDragAndDropStarted.closed) {
      this.subDragAndDropStarted.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.mapinfo = this.mapService.mapRef.getPrintLayers();
    this.merkatorMapFallBackInteraction = this.mapService.mapRef.fallbackInteraction;
    this.mapService.mapRef.fallbackInteraction = this.dragAndDropInteraction;
    this.mapService.mapRef.setInteraction(this.dragAndDropInteraction);
    this.subscribeOnBoxMoveStart();
    this.subscribeOnBoxMoveEnd();
    this.printApi.getAllTemplates().subscribe((templates) => {
      this.service.templates = [];
      templates.forEach((template) => {
        this.service.templates.push({
          name: template.name,
          template: template.template,
          values: template.values
        })
      });
      this.service.selectedTemplate = 0;
      this.loaded = true;
    })
  }

  subscribeOnBoxMoveEnd(): void {
    this.subModifiedFeature = this.mapService.drawRef.modifiedFeature.subscribe((feature: Feature) => {
      this.boxCenter = (feature.getGeometry() as Polygon).getInteriorPoint().getCoordinates();
      // fix for vinkje
      if (this.mapService.config.mapprintfilter) {
        this.setSelectedFeatures(feature);
      }
    });
  }

  setSelectedFeatures(feature: Feature): void {
    this.mapService.mapRef.map.renderSync();
    this.mapService.mapRef.map.once('rendercomplete', (e: RenderEvent) => {
      this.featuresInsideBbox = this.mapService.mapRef.getFeaturesInSidePolygon(feature);
      if ('boxeventdrawlayer' in this.featuresInsideBbox) {
        delete this.featuresInsideBbox.boxeventdrawlayer;
      }
      this.setAllInitialFilters();
    });
  }

  subscribeOnBoxMoveStart(): void {
    this.subDragAndDropStarted = this.mapService.drawRef.dragAndDropStarted.subscribe(e => {
      this.deleteAllFilters();
    });
  }

  deleteAllFilters(): void {
    let allFilters = [];
    for (const layer_key in this.allFilter) {
      for (const property in this.allFilter[layer_key]) {
        for (const filters of this.allFilter[layer_key][property]) {
          allFilters = allFilters.concat(filters);
        }
      }
    }
    this.mapService.mapRef.deleteFilters(allFilters);
    this.allFilter = {};
  }

  deleteFiltersByLayerKey(layer_key: any): void {
    let allFilters = [];
    for (const property in this.allFilter[layer_key]) {
      for (const filters of this.allFilter[layer_key][property]) {
        allFilters = allFilters.concat(filters);
      }
    }
    this.mapService.mapRef.deleteFilters(allFilters);
    this.allFilter = {};
  }

  setAllInitialFilters(): void {
    for (const layername in this.featuresInsideBbox) {
      const layerkey = this.mapService.mapRef.db_layers.filter(layer => layer.name === layername)[0].key;
      for (const feature of this.featuresInsideBbox[layername]) {
        const featureId = feature.getProperties().id;
        this.addFilter(featureId, layerkey);
      }
      this.addFilter(-1, layerkey);
    }
    for (const layerkey in this.allFilter) {
      this.mapService.mapRef.filterOnProperty(layerkey, 'id', this.allFilter[layerkey].id, true);
    }
  }

  /**
   *
   */

  toggleAllFeatures(layer_name: string, e: any): void {
    const layerkey = this.mapService.mapRef.db_layers.filter(layer => layer.name === layer_name)[0].key;
    if (e.checked) {
      this.featuresInsideBbox[layer_name].map(feature => {
        const htmlElement = document.getElementById(feature.getProperties().id + '' + layer_name + '-input') as any;
        if (!htmlElement.checked) {
          htmlElement.click();
        }
      });
      this.mapService.mapRef.filterOnProperty(layerkey, 'id', this.allFilter[layerkey].id, true);
    } else {
      this.featuresInsideBbox[layer_name].map(feature => {
        const htmlElement = document.getElementById(feature.getProperties().id + '' + layer_name + '-input') as any;
        if (htmlElement.checked) {
          htmlElement.click();
        }
      });
    }
  }

  makeFeatureCheckBoxId(feature: any, layer_name: any): string {
    return feature.getProperties().id + '' + layer_name;
  }

  toggleFeature(layer_name: any, feature: Feature, e: any): void {
    const layerkey = this.mapService.mapRef.db_layers.filter(layer => layer.name === layer_name)[0].key;
    const featureId = feature.getProperties().id;
    if (e.checked) {
      this.addFilter(featureId, layerkey);
      this.mapService.mapRef.filterOnProperty(layerkey, 'id', this.allFilter[layerkey].id, true);
    } else {
      this.deleteFilter(featureId, layerkey);
    }
  }

  addFilter(featureId: any, layerkey: any): void {
    const filter = new equalsFilter(featureId);
    if (this.allFilter[layerkey] && this.allFilter[layerkey].id) {
      this.allFilter[layerkey].id.push(filter);
    } else if (this.allFilter[layerkey]) {
      this.allFilter[layerkey].id = [filter];
    } else {
      this.allFilter[layerkey] = {id: [filter]};
    }
  }

  deleteFilter(featureId: any, layerkey: any): void {
    for (let i = 0; i < this.allFilter[layerkey].id.length; i++) {
      const filter: any = this.allFilter[layerkey].id[i];
      if (featureId === filter.value) {
        this.mapService.mapRef.deleteFilters([filter]);
        this.allFilter[layerkey].id = this.allFilter[layerkey].id.filter((filt: any) => filt.value !== featureId);
        break;
      }
    }
  }

  highlightFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'highlight', feature}));
  }

  stopHighlightFeature(feature: any): void {
    this.store.dispatch( new merkatorMapActions.TalkToMapReference({type : 'clearhighlight', feature}));
  }

  canvasEvent(event: any): void {
    this.mapService.mapRef.mapSaveToPng('boxeventdrawlayer', true);
  }

  boxevent(event: any): void {
    // console.log("boxevent => ", event);
    this.deleteAllFilters();
    const mystyle = new style(99999, 'mapprint', '#000000', 1.5, 'rgb(0, 122, 122, 0.15)', '?');
    this.mapService.mapRef.removeAllFeaturesFromLayer('boxeventdrawlayer');
    this.mapService.mapRef.addFeatureCollectionStringToVectorLayer('boxeventdrawlayer', event.geojson_projection, mystyle);
    const feature: Feature = new GeoJSON().readFeatures(event.geojson_projection)[0];
    // fix for vinkje
    if (this.mapService.config.mapprintfilter) {
      this.setSelectedFeatures(feature);
    }
  }

  alert(event: any): void {

  }

}
