import { Component, OnInit, Input } from '@angular/core';
import { ViewerButton } from '../../../classes-types/button';
import { trigger, transition, query, stagger, animateChild, style, animate } from '@angular/animations';
import { AppState } from '../../../state-interface/app.state';
import { Store, select } from '@ngrx/store';
import * as ButtonState from '../../../reducers/button-reducer/button.reducer';
import * as ButtonActions from '../../../actions/button-actions/button.actions';
import * as MapActions from '../../../actions/map-actions/merkator-map.actions';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { ComponentmanagerService } from '../../../services/app-state-services/componentmanager.service';

@Component({
  selector: 'app-expandablebutton',
  templateUrl: './expandablebutton.component.html',
  styleUrls: ['./expandablebutton.component.scss'],
  animations:
  [
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(125, animateChild()))
      ]),
    ]),
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
         style({
           transform: 'scale(0.5)', opacity: 0,
           height: '0px', margin: '0px'
         }))
      ])
    ])
  ]
})
export class ExpandablebuttonComponent implements OnInit {

  @Input() button: ViewerButton;
  @Input() mobile?: boolean = false;
  @Input() reversed?: boolean = false;
  @Input() expandTo: string;

  showMyChildren: boolean = false;
  initMainButton: ViewerButton;
  mainButton: ViewerButton;
  workAroundShowMain: boolean = true;
  activeButton: ViewerButton;
  timeoutHandler: any;

  constructor(public buttonService: ButtonmethodService, private store: Store<AppState>, private compManager: ComponentmanagerService) { }

  ngOnInit(): void {
    this.initMainButton = this.button;
    this.mainButton = this.button;
    this.store.pipe(select(ButtonState.getActiveButton)).subscribe(value => {
      if (value.activeButton !== null) {
        if (JSON.parse(value.activeButton.payload).payload.params.type !== 'deletedmeasured'
        && JSON.parse(value.activeButton.payload).payload.params.type !== 'deletedrawing' ) {
          //  this.mainButton = childButton;
          this.activeButton = value.activeButton;
        }
      } else if ( value.activeButton === null) {
        this.activeButton = null;
      }
    });
  }

  ToggleShowMyChildren(): void {
    this.showMyChildren = !this.showMyChildren;
  }

  changeMainButton(childButton: ViewerButton, mobile: boolean): void {
    if (JSON.parse(childButton.payload).payload.params.type !== 'deletedmeasured'
    && JSON.parse(childButton.payload).payload.params.type !== 'deletedrawing' ) {
      //  this.mainButton = childButton;
    }
    if (childButton === this.initMainButton) {

    } else {
      this.buttonService.buttonCreateMyComponent(childButton, mobile);
      // this.ToggleShowMyChildren();
    }
  }

  public mouseup(mainButton: ViewerButton, e?: any): void {
    this.buttonService.buttonCreateMyComponent(mainButton, false);
  }

  public mousedown(): void {
      this.ToggleShowMyChildren();
  }

}
