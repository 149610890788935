import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-merkator-feature',
  templateUrl: './merkator-feature.component.html',
  styleUrls: ['./merkator-feature.component.scss']
})
export class MerkatorFeatureComponent implements OnInit {

  @Input() feature: any;

  firstFeature;
  propArray = [];

  constructor() { }

  ngOnInit(): void {

    this.firstFeature = this.feature;

    // console.log(this.firstFeature);
    // console.log(this.feature);

    const keys = Object.keys(this.firstFeature.values_);
    const values = Object.values(this.firstFeature.values_);
    for (let i = 0; i < keys.length; i++ ) {
      if (values[i] !== null || values[i] !== undefined) {
        // console.log(keys[i], values[i]);
        if (keys[i] !== 'geometry' && keys[i] !== 'geom') {
          this.propArray.push({key: keys[i], value: values[i]} );
        }
      }
    }
    // console.log(Object.keys(this.firstFeature.properties));
    // console.log(Object.values(this.firstFeature.properties));
  }

}
