import { Injectable, EventEmitter } from '@angular/core';
import { HttpService } from '../app-utililty-services/http.service';
import { Observable } from 'rxjs';
// import { ExternalButtons } from '@merkator/merkator-grid';

@Injectable()

export class MerkatorGridService {

  constructor(public http: HttpService) { }

  idGrid: number;
  whereclause: string;
  button: any = { event: null, action: '' };
  previousSize: string;
  height: number;
  gridstate: string;
  resetgridstate: EventEmitter<any> = new EventEmitter<any>();

  getGridConfig(idGrid: number): Observable<any> {
    let body = {};
    if (this.whereclause === undefined) {
      body = { id: idGrid, whereclause: ''};
    } else {
      this.whereclause = this.whereclause.replace(/\\/g, '');
      body = { id: idGrid, whereclause: this.whereclause};
    }
    return this.http.post(this.http.apiUrl + 'merkatorgrid', body);
  }

  updateButtons(e: any, newAction: string, payload: any): void {
    this.button = {
      event: e,
      action: newAction,
      payload
    };
  }

}
