import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import * as AppConfigActions from '../actions/appconfig-actions/appconfig.actions';
import * as snackbarActions from '../actions/snackbar-actions/snackbar.actions';
import { ApplicationService } from '../services/app-state-services/application.service';
import { Action } from '@ngrx/store';

@Injectable()
export class AppConfigEffects {
    constructor(private action$: Actions, private applicatieService: ApplicationService) { }

    loadToolbarMenuConfiguraties$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(AppConfigActions.AppConfigTypes.LOAD_APP_CONFIG),
        mergeMap(() => this.applicatieService.getAppConfig()
                            .pipe(
                                map(AppConfig => (new AppConfigActions.SuccesLoadAppConfig(AppConfig))),
                                catchError(() => {
                                    console.log('Error');
                                    return EMPTY;
                                })
                            )
        ))
    );

/*
    loadMenuEffect$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
            ofType(menuActions.MenuTypes.SUCCES_LOAD_MENU),
            map(value =>  new snackbarActions.SnackbarOpen({
                message: 'snackbar.succes'
                // config: { } as MatSnackBarConfig
              })
            ))
    );
*/
}
