import * as matModalActions from '../../actions/matmodal-actions/matmodal.actions';
import * as fromRoot from '../../state-interface/app.state';

export interface MatModalState extends fromRoot.AppState {
  show: boolean;
}

const matModalState: MatModalState = {
  show: false
};

export function matmodal_reducer(state: MatModalState = matModalState, action: matModalActions.MatModalAction): fromRoot.AppState {
  switch (action.type) {
    case matModalActions.MatModalTypes.MATMODAL_OPEN:
      return { ...state, show: false };
    case matModalActions.MatModalTypes.MATMODAL_CLOSE:
      return { ...state, show: true };
    default:
      return state;
  }
}
