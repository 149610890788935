import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as snackbarActions from '../../../actions/snackbar-actions/snackbar.actions';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})

export class SnackBarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private store: Store<AppState> ) { }

  ngOnInit(): void {
  }

  closeMessage(): void {
    this.store.dispatch(new snackbarActions.SnackbarClose());
  }

}
