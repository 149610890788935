import * as bottomSheetActions from '../../actions/bottomsheet-actions/bottomsheet.actions';
import * as fromRoot from '../../state-interface/app.state';

export interface BottomSheetState extends fromRoot.AppState {
  show: boolean;
}

const matModalState: BottomSheetState = {
  show: false
};

export function bottomsheet_reducer(state: BottomSheetState = matModalState,
                                    action: bottomSheetActions.BottomSheetAction): fromRoot.AppState {
  switch (action.type) {
    case bottomSheetActions.BottomSheetTypes.BOTTOMSHEET_CLOSE:
      return { ...state, show: false };
    case bottomSheetActions.BottomSheetTypes.BOTTOMSHEET_OPEN:
      return { ...state, show: true };
    default:
      return state;
  }
}
