import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as merkatorMapActions from '../actions/map-actions/merkator-map.actions';
import { MerkatorMapService } from '../services/app-map-services/merkator-map.service';
import { Action } from '@ngrx/store';

// import all requried services or any dependencies

@Injectable()
export class MerkatorMapEffects {
    constructor(private action$: Actions, private mapService: MerkatorMapService) { }

    loadMerkatorMapConfiguraties$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_CONFIGURATIES),
        mergeMap(() => this.mapService.getAllMapConfiguratie()
        .pipe(
            map(configuraties => (new merkatorMapActions.LoadSuccesMerkatorMapConfiguraties(configuraties))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapLayers$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_LAYERS),
        mergeMap(() => this.mapService.getAllMapLayers()
        .pipe(
            map(layers => (new merkatorMapActions.LoadSuccesMerkatorMapLayers(layers))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapViewParams$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_VIEWPARAMS),
        mergeMap(() => this.mapService.getAllMapViewParams()
        .pipe(
            map(viewparams => (new merkatorMapActions.LoadSuccesMerkatorMapViewParams(viewparams))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapHttpHeaders$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_HTTPHEADERS),
        mergeMap(() => this.mapService.getAllMapHttpHeaders()
        .pipe(
            map(httpheaders => (new merkatorMapActions.LoadSuccesMerkatorMapHttpHeaders(httpheaders))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapStyles$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_STYLES),
        mergeMap(() => this.mapService.getAllMapStyles()
        .pipe(
            map(styles => (new merkatorMapActions.LoadSuccesMerkatorMapStyles(styles))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapCateogires$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_CATEGORIES),
        mergeMap(() => this.mapService.getAllMapCategories()
        .pipe(
            map(categories => (new merkatorMapActions.LoadSuccesMerkatorMapCategories(categories))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapLegendItems$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_LEGEND_ITEMS),
        mergeMap(() => this.mapService.getAllMapLegendItems()
        .pipe(
            map(legendItems => (new merkatorMapActions.LoadSuccesMerkatorMapLegendItems(legendItems))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapFilters$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_FILTERS),
        mergeMap(() => this.mapService.getAllFilters()
        .pipe(
            map(filterItems => (new merkatorMapActions.LoadSuccesMerkatorMapFilters(filterItems))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapFeatureIdentifier$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_FEATUREIDENTIFIER),
        mergeMap(() => this.mapService.getAllFeatureIdentifiers()
        .pipe(
            map(featureIdentifier => (new merkatorMapActions.LoadSuccesMerkatorMapFeatureIdentifiers(featureIdentifier))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapFeatureStyles$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_FEATURESTYLE),
        mergeMap(() => this.mapService.getAllFeatureStyles()
        .pipe(
            map(featureStyles => (new merkatorMapActions.LoadSuccesMerkatorMapFeatureStyle(featureStyles))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

    loadMerkatorMapTextStyles$: Observable<Action> = createEffect(() =>
        this.action$.pipe(
        ofType(merkatorMapActions.MerkatorMapTypes.LOAD_MERKATOR_MAP_TEXTSTYLES),
        mergeMap(() => this.mapService.getAllTextStyles()
        .pipe(
            map(textStyles => (new merkatorMapActions.LoadSuccesMerkatorMapTextStyle(textStyles))
            ),
            catchError(() => {
                console.log('Error');
                return EMPTY;
            })
        )
        ))
    );

}
