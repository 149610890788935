import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from '../../../state-interface/app.state';
import { Store } from '@ngrx/store';
import * as matModalActions from '../../../actions/matmodal-actions/matmodal.actions';
import * as merkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import { ApplicationService } from '../../../services/app-state-services/application.service';

@Component({
  selector: 'app-htmlinputcomponent',
  templateUrl: './htmlinputcomponent.component.html',
  styleUrls: ['./htmlinputcomponent.component.scss']
})
export class HtmlinputcomponentComponent implements OnInit {

  constructor(public dom: DomSanitizer, public store: Store<AppState>, public appService: ApplicationService) { }

  @Input() htmlinput: string;
  @Input() flow: string;
  input;

  ngOnInit(): void {
    this.input = this.dom.bypassSecurityTrustHtml(this.htmlinput);
  }

  gkalgraafyes(): void {
    this.store.dispatch(new merkatorMapActions.TalkToMapReference({ type: 'deletedrawing', geometry: null}));
    this.store.dispatch(new matModalActions.MatModalClose());
    this.appService.rightSideNavRef.close();
  }

  gkalgraafno(): void {
    this.store.dispatch(new matModalActions.MatModalClose());
  }

}
