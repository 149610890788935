<div style="height: 500px; width: 500px;">

    <div style="padding-bottom: 45px; position: relative;"> 
    <form [formGroup]="savedState" *ngIf="savedState" style="font-size: 14px;">
        <b>{{ 'grid.savemenu.save' | translate :{ Default: 'Save'} }}</b> <br><br>
        <mat-form-field appearance="outline" style="min-width: 200px !important; max-width: 200px !important; max-height: 50px !important; padding-right: 25px;">
            <mat-label>{{ 'grid.savemenu.name' | translate :{ Default: 'Name'} }}</mat-label>
            <input formControlName="title" matInput> 
        </mat-form-field>
        <mat-checkbox formControlName="default_active">{{ 'grid.savemenu.default' | translate :{ Default: 'Default'} }}</mat-checkbox>
    </form>
    
    <div style="font-size:12px; height: 20px; width: 20px; position: absolute; right: 20px; bottom: 55px;">
        <button (click)="createSaveObject()" mat-mini-fab aria-label="Save grid">
            <mat-icon>save</mat-icon>
        </button>
    </div>

    </div>
    <!--<hr>-->

    <br>
    <div style="font-size: 14px; max-height: 300px;">   
    
    <b>{{ 'grid.savemenu.settings' | translate :{ Default: 'Your settings'} }}</b>
    <mat-grid-list cols="5" rowHeight="80px">
        <mat-grid-tile>
            {{ 'grid.savemenu.title' | translate :{ Default: 'Title'} }}
        </mat-grid-tile>
        <mat-grid-tile>
            {{ 'grid.savemenu.datecreated' | translate :{ Default: 'Date created'} }}
        </mat-grid-tile>
        <mat-grid-tile>
            {{ 'grid.savemenu.default' | translate :{ Default: 'Default'} }}
        </mat-grid-tile>
        <mat-grid-tile>
            {{ 'grid.savemenu.loadsettings' | translate :{ Default: 'Load settings'} }}           
        </mat-grid-tile>
        <mat-grid-tile>
            {{ 'grid.savemenu.deletesettings' | translate :{ Default: 'Delete settings'} }}         
        </mat-grid-tile>
        <hr>
        <ng-container *ngFor="let previous of previousStateArray">
            <mat-grid-tile>
                {{previous.title}} 
            </mat-grid-tile>
            <mat-grid-tile>
                {{previous.created_date}}
            </mat-grid-tile>
            <mat-grid-tile>
                   <mat-checkbox (click)="makeDefaultActive(previous);" [(ngModel)]="previous.default_active"></mat-checkbox>
            </mat-grid-tile>
            <mat-grid-tile>
                <button (click)="loadGridSettings(previous)" mat-mini-fab>
                    <mat-icon>trending_flat</mat-icon>
                </button>
            </mat-grid-tile>
            <mat-grid-tile>
                <button (click)="deleteGridSettings(previous)" mat-mini-fab>
                    <mat-icon>delete</mat-icon>
                </button>            
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list>
    
    </div>



    
</div>