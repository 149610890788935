import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state-interface/app.state';
import * as MenuState from '../../../reducers/menu-reducer/menu.reducer';
import * as MenuClasses from '../../../classes-types/main.menu';
import * as BottomSheetActions from '../../../actions/bottomsheet-actions/bottomsheet.actions';
import {Subscription} from 'rxjs';
import {ComponentmanagerService} from '../../../services/app-state-services/componentmanager.service';
import { FeatureCollection } from 'geojson';
import * as AppConfigState from '../../../reducers/appconfig-reducer/appconfig.reducer';

import {ApplicationService} from '../../../services/app-state-services/application.service';


import {ViewportService} from '../../../services/app-utililty-services/viewport.service';
import {Extent} from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import {FeatureExporterComponent} from "../../app-map-components/feature-exporter/feature-exporter.component";


@Component({
  selector: 'app-menu-toolbar',
  templateUrl: './menu-toolbar.component.html',
  styleUrls: ['./menu-toolbar.component.scss']
})
export class MenuToolbarComponent implements OnInit, OnDestroy {

  title: string = 'title';
  SubscriptionArray: Subscription[] = [];
  availableLanguages: Array<string> = [];
  currentActiveLanguage: string;
  theme: string;
  menuObject: Array<MenuClasses.MainMenu>;
  loadSpinning: boolean = false;
  JSON: JSON = JSON;
  exportOpen=  false;

  constructor(private store: Store<AppState>, private compManager: ComponentmanagerService, public appService: ApplicationService, public viewportService: ViewportService) {
  }

  ngOnInit(): void {
    this.SubscriptionArray.push(
      this.store.pipe(select(MenuState.getMenu)).subscribe((menu: Array<MenuClasses.MainMenu>) => {
        if (menu.length > 0) {
          this.menuObject = menu;
        }
      }),
      this.store.pipe(select(AppConfigState.getLoadingStatus)).subscribe((loading: boolean) => {
        if (loading != null) {
          setTimeout(() => {
            this.loadSpinning = loading;
          }, 1);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.SubscriptionArray.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onItemClick(menuItem: any): void {
    const object = JSON.parse(menuItem.payload);
    if (object)
      object.rights = menuItem.rights;
    if (object.component) {
      switch (object.sidenav) {
        case 'left':
          this.compManager.leftSideComponent = this.compManager.StringToComponent(object.component);
          break;
        case 'right':
          this.compManager.rightSideComponent = this.compManager.StringToComponent(object.component);
          break;
        case 'bottom':
          this.compManager.bottomSheetComponent = this.compManager.StringToComponent(object.component);
          this.store.dispatch(new BottomSheetActions.BottomSheetOpen({}));
          break;
        default:
          break;
      }
    }
  }

  OpenExport() {
    this.exportOpen = !this.exportOpen;
    //this.compManager.leftSideComponent = FeatureExporterComponent;
    //console.log(this.compManager.leftSideComponent);
  }
}
