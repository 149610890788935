import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Action } from '@ngrx/store';

export enum SnackbarTypes {
    SNACKBAR_OPEN = '[Snackbar] snackbar open',
    SNACKBAR_CLOSE = '[Snackbar] snackbar close'
}
export class SnackBarPayLoad {
  /** Message moet een translate json property zijn en moet toegevoegd zijn in meerdere translate.json files voor meertaligheid ! */
  message: string;
  action?: string;
  config?: MatSnackBarConfig;
  type: string;
  panelClass: string;
  params?: string = null;
  delay?: number = 2000;
}

export class SnackbarOpen implements Action {
  readonly type = SnackbarTypes.SNACKBAR_OPEN;
  constructor(public payload: SnackBarPayLoad) { }
}

export class SnackbarClose implements Action {
  readonly type = SnackbarTypes.SNACKBAR_CLOSE;
}

export type SnackbarAction = SnackbarOpen | SnackbarClose;
