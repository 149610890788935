import { Action } from '@ngrx/store';
import { Type } from '@angular/core';
import { CustomDynamicComponent } from '../../classes-types/dynamic-comp.types';
import { Components } from '../../services/app-state-services/componentmanager.service';

export enum BottomSheetTypes {
    BOTTOMSHEET_OPEN = '[BottomSheet] BottomSheet open',
    BOTTOMSHEET_CLOSE = '[BottomSheet] BottomSheet close'
}

export class BottomSheetPayload {
  title?: string;
  component?: Components;
  params?: any;
}

export class BottomSheetOpen implements Action {
  readonly type = BottomSheetTypes.BOTTOMSHEET_OPEN;
  constructor(public payload: BottomSheetPayload) { }
}

export class BottomSheetClose implements Action {
  readonly type = BottomSheetTypes.BOTTOMSHEET_CLOSE;
}

export type BottomSheetAction = BottomSheetClose | BottomSheetOpen;
