import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {

  constructor() { }

  viewportHeight;
  innerWidth;
}
