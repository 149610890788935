import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpService } from '../../../services/app-utililty-services/http.service';
import { MerkatorGridService } from '../../../services/app-grid-services/merkator-grid.service';

@Component({
  selector: 'app-gridprevioussaveddialog',
  templateUrl: './gridprevioussaveddialog.component.html',
  styleUrls: ['./gridprevioussaveddialog.component.scss']
})
export class GridprevioussaveddialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GridprevioussaveddialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public fb: UntypedFormBuilder, public http: HttpService,
    public gridService: MerkatorGridService) { }

  savedState: UntypedFormGroup;
  previousStateArray: [];


  ngOnInit(): void {
    this.createFormForSavedGridSetting();
    this.loadPreviouslySavedForUser();
  }

  createFormForSavedGridSetting(): void {
    const form = this.fb.group({});
    form.addControl('title', new UntypedFormControl('', Validators.required));
    form.addControl('default_active', new UntypedFormControl(false));
    this.savedState  = form;
  }

  loadPreviouslySavedForUser(): void {
    const object = {
      id_grid_config : this.data.params.idGrid,
      default_active : false
    };
    this.http.post(this.http.apiUrl + 'MerkatorGrid/GetAllGridState', object).subscribe(result => {
      this.previousStateArray = result;
    });
  }

  loadGridSettings(previous: any): void {
    this.gridService.resetgridstate.emit(previous.stringparsedstate); //  = previous.stringparsedstate;
    this.dialogRef.close();
    // const object = this.createUpdateSetObject(previous, false);
  }

  deleteGridSettings(previous: any): void {
    // console.log(previous);
    const object = this.createUpdateSetObject(previous, false);
    this.http.post(this.http.apiUrl + 'merkatorgrid/detail' + '/save', object).subscribe( result => {
      this.loadPreviouslySavedForUser();
    });
  }

  createUpdateSetObject(previous: any ,   defaultactive: boolean): any {
    let custom_column;
    let deleted;
    if (defaultactive) {
      custom_column = 'default_active';
      if (previous.default_active) {
        deleted = false;
      } else {
        deleted = true;
      }
    } else {
      custom_column = 'deleted';
      deleted = true;
    }

    return {
      custom_table: '"viewer"."merkator_grid_savedstates"',
      column: custom_column,
      value: deleted + '',
      identifiercolumn: 'id',
      identifiervalue: previous.id + ''
    };
  }

  makeDefaultActive(previous: any): void {
    const object = {
      id_grid_config: this.data.params.idGrid,
      id: previous.id,
      previousstate: previous.default_active
    };
    this.http.post(this.http.apiUrl + 'merkatorgrid/makedefaultactive', object).subscribe( result => {
      this.loadPreviouslySavedForUser();
    });
  }

  createSaveObject(): void {
    if (this.savedState.valid) {
      const object = {
        id_grid_config: this.data.params.idGrid,
        stringparsedstate: this.data.params.state,
        title: this.savedState.controls.title.value,
        default_active: this.savedState.controls.default_active.value === true ? true : false
      };
      this.http.post(this.http.apiUrl + 'MerkatorGrid/insertGridState', object).subscribe(result => {
        this.savedState.reset();
        this.loadPreviouslySavedForUser();
      });
    }
  }



}
