import { Action } from '@ngrx/store';

// Enums
export enum ActionTypes {
    LOAD_LEFT_BUTTONS = '[Buttons] load left Buttons',
    LOAD_RIGHT_BUTTONS = '[Buttons] load right Buttons',
    LOAD_TOP_BUTTONS = '[Buttons] load top Buttons',
    LOAD_LEFT_BUTTONS_SUCCES = '[Buttons] load left Buttons succes',
    LOAD_RIGHT_BUTTONS_SUCCES = '[Buttons] load right Buttons succes',
    LOAD_TOP_BUTTONS_SUCCES = '[Buttons] load top Buttons succes',
    GET_BUTTONS = '[Buttons] get Buttons',
    GET_LEFTBUTTONS = '[Buttons] get Left Buttons',
    GET_RIGHTBUTTONS = '[Buttons] get Right Buttons',
    GET_BOTTOMBUTTONS = '[Buttons] get Bottom Buttons',
    SET_ACTIVE_BUTTON = '[Buttons] set active button',
    CLEAR_ACTIVE_BUTTON = '[Buttons] clear active button'
}

/** ############# */
export class LoadLeftButtons implements Action {
    readonly type = ActionTypes.LOAD_LEFT_BUTTONS;
}
export class LoadRightButtons implements Action {
    readonly type = ActionTypes.LOAD_RIGHT_BUTTONS;
}
export class LoadTopButtons implements Action {
    readonly type = ActionTypes.LOAD_TOP_BUTTONS;
}
export class LoadLeftButtonsSucces implements Action {
    readonly type = ActionTypes.LOAD_LEFT_BUTTONS_SUCCES;
    constructor(public payload: any) {}
}
export class LoadRightButtonsSucces implements Action {
    readonly type = ActionTypes.LOAD_RIGHT_BUTTONS_SUCCES;
    constructor(public payload: any) {}
}
export class LoadTopButtonsSucces implements Action {
    readonly type = ActionTypes.LOAD_TOP_BUTTONS_SUCCES;
    constructor(public payload: any) {}
}

export class SetActiveButton implements Action {
    readonly type = ActionTypes.SET_ACTIVE_BUTTON;
    constructor(public payload: any) {}
}

export class ClearActiveButton implements Action {
    readonly type = ActionTypes.CLEAR_ACTIVE_BUTTON;
}
/** ############## */

export class GetButtons implements Action {
    readonly type = ActionTypes.GET_BUTTONS;
}
export class GetLeftButtons implements Action {
    readonly type = ActionTypes.GET_LEFTBUTTONS;
}
export class GetRightButtons implements Action {
    readonly type = ActionTypes.GET_RIGHTBUTTONS;
}
export class GetBottomButtons implements Action {
    readonly type = ActionTypes.GET_BOTTOMBUTTONS;
}

// Export as 1 ActionType
export type ActionType = GetButtons | GetLeftButtons | GetRightButtons | GetBottomButtons |
                     LoadLeftButtons | LoadLeftButtonsSucces | LoadRightButtons | LoadRightButtonsSucces
                    | LoadTopButtons | LoadTopButtonsSucces | SetActiveButton | ClearActiveButton;
