import { Component, OnInit, Input, OnChanges, EventEmitter } from '@angular/core';
import { ViewerButton } from '../../../classes-types/button';
import { MatSidenav } from '@angular/material/sidenav';
import { trigger, transition, query, style, stagger, animate, state, animateChild } from '@angular/animations';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../state-interface/app.state';
import * as ButtonState from '../../../reducers/button-reducer/button.reducer';
import * as ButtonActions from '../../../actions/button-actions/button.actions';
import { ComponentmanagerService } from '../../../services/app-state-services/componentmanager.service';
import { ButtonmethodService } from '../../../services/app-buttons-services/buttonmethod.service';
import { ViewportService } from '../../../services/app-utililty-services/viewport.service';

@Component({
  selector: 'app-buttoncontainer',
  templateUrl: './buttoncontainer.component.html',
  styleUrls: ['./buttoncontainer.component.scss'],
  animations:
  [
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(125, animateChild()))
      ]),
    ]),
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 }))  // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
         style({
           transform: 'scale(0.5)', opacity: 0,
           height: '0px', margin: '0px'
         }))
      ])
    ])
  ]

})
export class ButtoncontainerComponent implements OnInit {

  constructor(private compManager: ComponentmanagerService, public store: Store<AppState>, public buttonService: ButtonmethodService, public viewportService: ViewportService) { }

  @Input() sideNav: MatSidenav;
  @Input() buttons?: Array<ViewerButton>;
  @Input() eventSender?: EventEmitter<any> = new EventEmitter();
  @Input() initTop?: string;
  @Input() initLeft?: string;
  @Input() initRight?: string;
  @Input() initBottom?: string;
  @Input() width?: string;
  @Input() mobile?: boolean = false;

  positionObject: { left: string, right: string, top: string, bottom: string };
  activeButton = null;
  mobileMainButton: ViewerButton = {
    id: 999,
    name: '',
    translate_property: '',
    id_menu: null,
    tooltop: null,
    component_name: null,
    deleted: false,
    id_applicatie: null,
    children: [],
    icon: 'touch_app',
    rights: '',
    payload: null,
    id_container_name: 'main',
    tooltip: ''
  };
  showMyChildren: boolean = false;

  ngOnInit(): void {
    this.positionObject = { left: this.initLeft, right: this.initRight, top: this.initTop, bottom: this.initBottom };
    this.sideNav.openedStart.subscribe(() => {
        this.openedStart();
    });
    this.sideNav.closedStart.subscribe(() => {
        this.closedStart();
    });
    this.eventSender.subscribe( value => {
      if ( value === 'refresh') {
        setTimeout(() => {
          this.openedStart();
        }, 20);
      } else if ( value === 'open') {
        this.sideNav.open();
      }
    });
    this.store.pipe(select(ButtonState.getActiveButton)).subscribe(value => {
      if (value.activeButton !== null) {
        this.activeButton = value.activeButton;
      } else if ( value.activeButton === null) {
        this.activeButton = null;
      }
    });
  }

  openedStart(): void {
    if (this.positionObject.left === undefined) {
        this.positionObject.right = this.sideNav._getWidth() + 10 + 'px';
    } else if (this.positionObject.right === undefined) {
        this.positionObject.left = this.sideNav._getWidth() + 10 + 'px';
        // document.getElementById('merkator-map-coordinate-scale-frame-id').classList.remove('merkator-map-coordinate-scale-frame-class'); //.left = this.positionObject.left + 10 + 'px';
        setTimeout(() => {
          document.getElementById('merkator-map-coordinate-scale-frame-id').style.left = this.sideNav._getWidth() + 10 + 'px';
        }, 100);
        
    }
  }

  closedStart(): void {
    if (this.positionObject.left === undefined) {
      this.positionObject.right = this.initRight;
    } else if (this.positionObject.right === undefined) {
      this.positionObject.left = this.initLeft;
      setTimeout(() => {
        if(this.mobile){
          document.getElementById('merkator-map-coordinate-scale-frame-id').style.left = this.sideNav._getWidth() + 65 + 'px';
        } else {
          document.getElementById('merkator-map-coordinate-scale-frame-id').style.left = this.sideNav._getWidth() + 65 + 'px';
        }
      }, 100);
      // document.getElementById('merkator-map-coordinate-scale-frame-id').classList.add('merkator-map-coordinate-scale-frame-class');
    }
  }

  closeNavbar(): void {
    this.sideNav.close();
  }

  openNavbar(): void {
    this.sideNav.open();
  }

  ToggleShowMyChildren(): void {
    this.showMyChildren = !this.showMyChildren;
  }

}
