import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../app-utililty-services/http.service';

@Injectable()
export class MerkatorPrintService {

  constructor(public http: HttpService) { }

  getAllTemplates(): Observable<any> {
    return this.http.get(this.http.apiUrl + 'MerkatorPrint');
  }

}
