<mat-card-content>
    <mat-expansion-panel style="min-width: 35vw;" class="mat-elevation-z0" [expanded]="hasOneLayer">
            <mat-expansion-panel-header>
                <mat-panel-description>
                {{layerName}}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- DETAILS  -->
            <ng-container *ngFor="let feature of featureArray; let i = index;">
                <mat-expansion-panel style="max-width: 100%;" class="mat-elevation-z0"  [expanded]="hasOneLayer">
                    <mat-expansion-panel-header (mouseenter)="highlightFeature(feature);" (mouseleave)="stopHighlightFeature(feature);">
                        <mat-panel-description style="padding-right: 20px;">
                        {{modifyFeatureValueDisplayName(feature.values_.id_feature_identifier_config, feature.values_.feature_identifier_name) }}  
                        </mat-panel-description>
                        <mat-panel-description>
                            <button mat-icon-button style="position: absolute; right: 85px; height: 22px; width: 24px;" (click)="$event.stopPropagation(); zoomToFeature(feature);">
                                <mat-icon style="font-size: 24px; margin-bottom: 2px;">location_on</mat-icon>
                            </button>                          
                            <button *ngIf="feature.id_grid" mat-icon-button style="position: absolute; right: 115px; height: 22px; width: 24px;" (click)="$event.stopPropagation(); openDetailTabFromFeature(feature);">
                                <mat-icon style="font-size: 24px; margin-bottom: 2px;">grid_on</mat-icon>
                            </button>                          
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <app-merkator-feature [feature]="feature"></app-merkator-feature>
                </mat-expansion-panel>
                <hr *ngIf="i+1 !== featureArray.length" class="merkator-featurecontainer-hr">
            </ng-container> 

    </mat-expansion-panel>
</mat-card-content>
