import { Component, OnInit, Input } from '@angular/core';
import { AppState } from '../../../state-interface/app.state';
import { Store, select } from '@ngrx/store';
import * as MerkatorMapActions from '../../../actions/map-actions/merkator-map.actions';
import * as MerkatorMapState from '../../../reducers/merkator-map-reducer/merkator-map.reducer';
import { MerkatorMapService } from '../../../services/app-map-services/merkator-map.service';

@Component({
  selector: 'app-gkal-form-impl',
  templateUrl: './gkal-form-impl.component.html',
  styleUrls: ['./gkal-form-impl.component.scss']
})
export class GkalFormImplComponent implements OnInit {

  constructor(public store: Store<AppState>, public mapService: MerkatorMapService) { }

  geoJson: string;
  email: string;
  fullName: string;
  accesToken: string;

  ngOnInit(): void {
    this.email = sessionStorage.getItem('ue');
    this.fullName = sessionStorage.getItem('fn');
    this.accesToken = this.mapService.accesToken;
    this.store.pipe(select(MerkatorMapState.getDrawedFeature)).subscribe(result => {
      this.geoJson = JSON.stringify(result.merkatorMapDrawedFeature);
    });

  }

}
