<div style="margin: 15px">
  <span style="float: right" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
 </span>
  <h1 *ngIf="done" class="theme-text-color">
    {{ 'tooltip.RequestSend' | translate :{Default: 'RequestSend'} }}
      <br/>
    {{ 'tooltip.CloseWindow' | translate :{Default: 'CloseWindow'} }}

  </h1>
  <div *ngIf="!done">
    <h2 class="theme-text-color">
      {{ 'tooltip.RequestExport' | translate :{Default: 'RequestExport'} }}
    </h2>
    <p>
      {{ 'tooltip.SendViaEmail' | translate :{Default: 'SendViaEmail'} }}
    </p>

    <span *ngFor="let buttonprop of buttonprops | keyvalue">
        <button class="cronButton mat-focus-indicator mat-stroked-button mat-button-base mat-accent"
                (click)="startCronJon(buttonprop.key)">
        {{buttonprop.value}}
        </button>
    </span>


  </div>
</div>
